<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination, Navigation } from 'swiper/modules'
import step01 from '@/assets/images/onboarding/onboarding-step01.png'
import step02 from '@/assets/images/onboarding/onboarding-step02.png'
import step03 from '@/assets/images/onboarding/onboarding-step03.png'
import step04 from '@/assets/images/onboarding/onboarding-step04.png'
import step05 from '@/assets/images/onboarding/onboarding-step05.png'
import { ROUTES } from '@configs'

const { showSignInDialog } = useMembershipProcess()
const userStore = useUserStore()
const { localStorageItems, set: setLocalStorage } = useLocalStorage()
const { locale, messages } = useI18n()
const { hide: modalHide } = useModal()

const show = ref(false)

const imgMapping: Record<string, string> = {
  step01,
  step02,
  step03,
  step04,
  step05,
}

const swiperOptions = {
  pagination: {
    clickable: true,
  },
  watchOverflow: true,
  modules: [Navigation, Pagination],
}
const handleOnClose = async (showAgain: boolean) => {
  await setLocalStorage<typeof localStorageItems.ON_BOARDING.key, boolean>(
    localStorageItems.ON_BOARDING.key,
    showAgain
  )
  await modalHide(modalsName.MODAL_ON_BOARDING_CAROUSEL)
  if (!userStore.isSignIn) {
    await showSignInDialog(undefined, { cf2: 'onBoarding' })
  } else {
    // 홈으로 이동
    await useNavigations({ url: ROUTES.HOME.path })
  }
}

const onboardingContent = computed(() =>
  Object.keys((messages.value[locale.value].onboarding as any).content).map(
    (key) => ({
      pageIndex: key,
      imgSource: imgMapping[key as keyof typeof imgMapping],
    })
  )
)
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_ON_BOARDING_CAROUSEL"
    :content-class="'modal-content'"
    classes="modal-container"
    @click-outside="handleOnClose"
  >
    <NovaBoxBase v-if="show" class="modal-box">
      <div class="modal-body">
        <Swiper v-bind="swiperOptions">
          <SwiperSlide v-for="data in onboardingContent" :key="data.pageIndex">
            <NovaOnboardingContent
              :page-index="data.pageIndex"
              :img-source="data.imgSource"
              @close-modal="handleOnClose"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </NovaBoxBase>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(30px + 24px + 12px) 32px 32px;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    padding: 0;
    max-width: 670px;
    max-height: 100%;
    pointer-events: auto;

    .modal-body {
      border-radius: 20px;
      height: 100%;
      .swiper {
        height: 100%;
      }
    }
  }
}

@include mobile {
  :deep(.modal-content) {
    width: 100%;
    height: 100%;
    padding: 0;

    .modal-box {
      width: 100%;
      max-width: 100%;
      height: 100%;
      border-radius: 0 !important;

      .modal-body {
        border-radius: 0;
      }
    }
  }
}
:deep(.swiper-pagination) {
  width: 100%;
  height: 24px;
  top: 40px;
  font-size: 14px;
  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: $color-bg-custom-11;
    &.swiper-pagination-bullet-active {
      background-color: $color-primary-blue-dark;
    }
  }
}
</style>
