<script setup lang="ts">
import type {
  NovaBoxSocialLinksEmits,
  NovaBoxSocialLinksProps,
} from './NovaBoxSocialLinks.types'
import type { RegCreatorSocial } from '@store'
import { NovaBoxSocialLinksItemEditMode } from '#components'

const emit = defineEmits<NovaBoxSocialLinksEmits>()
const props = withDefaults(defineProps<NovaBoxSocialLinksProps>(), {
  editMode: false,
  theme: 'default',
})
const { gtEvent } = useGoogleTag()
const { socials } = toRefs(props)
const { t } = useI18n()
const creatorStore = useCreatorStore()
const loading = ref(false)
const initSocials = ref<RegCreatorSocial[]>([])
const socialsRef = ref<(typeof NovaBoxSocialLinksItemEditMode)[]>([])
const initSocialLinkModel: RegCreatorSocial = {
  optionNcm: '',
  expsrOrdr: -1,
  optionCharstVal: '',
}

// edit mode 변경 감지 후 컨트롤할 모델 초기화
watch(
  () => props.editMode,
  (cur) => {
    if (cur) {
      initSocials.value = socials.value.map((social, index) => ({
        optionNcm: social.optionNcm,
        optionCharstVal: social.optionCharstVal,
        expsrOrdr: index + 1,
      }))
    }
  }
)

// 소셜 링크 모델 수정
const handleOnModifySocial = (social: RegCreatorSocial, index: number) => {
  initSocials.value[index] = social
}

// 소셜 링크 삭제
const handleOnRemoveSocial = (index: number) => {
  const data = initSocials.value.find((item) => item.expsrOrdr === index)
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '링크 수정 > 삭제',
    eventLabel: 'remove',
    eventSlot: '',
    eventI18nAddr: '',
    eventComponent: 'Button',
    expsrOrdr: data?.expsrOrdr || '',
    optionCharstVal: data?.optionCharstVal,
    optionNcm: data?.optionNcm,
  })
  if (initSocials.value.length === 1) {
    initSocials.value = [{ ...initSocialLinkModel }]
  } else {
    initSocials.value.splice(index, 1)
  }
}

// 소셜링크 업데이트(수정된 항목만 추려 배열로 업데이트)
const handleOnUpdateSocials = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '링크 수정 > 저장 버튼 클릭',
    eventLabel: t('save'),
    eventSlot: '링크 수정 > 저장 버튼',
    eventI18nAddr: useKoreanTranslation('save'),
    eventComponent: 'Button',
  })
  try {
    loading.value = true

    const errors = []
    initSocials.value.forEach((_social, index) => {
      const error = socialsRef.value[index].handleOnCheckError()
      if (error) {
        errors.push(index)
      }
    })
    if (errors.length) {
      useToast(t('creatorMyPage.home.linkValidFail'))
      return
    }

    await creatorStore.updateCreatorSocials(initSocials.value)
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '크리에이터 홈 > 링크 수정',
      eventLabel: t('save'),
      eventSlot: '크리에이터 홈 > 링크 수정',
      eventI18nAddr: useKoreanTranslation('save'),
      eventComponent: 'Button',
    })
    useToast(t('creatorMyPage.home.linkSaveSuccess'))
    emit('turnOffEditMode', true)
  } catch {
    useToast(t('creatorMyPage.home.linkSaveFail'))
  } finally {
    loading.value = false
  }
}

// 소셜 링크 더미 추가
const handleOnAddDummy = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '크리에이터 홈 > 링크 수정 > 링크 추가버튼 클릭',
    eventLabel: t('creatorMyPage.home.linkUrlPlaceholder'),
    eventSlot: initSocials.value.length >= 10 ? '링크 갯수 최대' : '링크 추가',
    eventI18nAddr: useKoreanTranslation(
      'creatorMyPage.home.linkUrlPlaceholder'
    ),
    eventComponent: 'Button',
  })
  if (initSocials.value.length >= 10) return
  initSocials.value = [
    ...initSocials.value,
    { ...initSocialLinkModel, expsrOrdr: initSocials.value.length },
  ]
}

// 에디트 모드 끄기
const handleOnTurnOffEditMode = () => {
  emit('turnOffEditMode', false)
}

// 동적 refs 업데이트
const handleOnUpdateSocialsRefs = (
  el: typeof NovaBoxSocialLinksItemEditMode | null,
  index: number
) => {
  if (el) {
    socialsRef.value[index] = el
  } else {
    socialsRef.value.splice(index, 1)
  }
}
</script>

<template>
  <div :class="['social-links', `theme-${theme}`]">
    <!-- editMode: false -->
    <div v-if="!editMode" class="inner">
      <NovaBoxSocialLinksItem
        v-for="(item, index) in socials"
        :key="index"
        :source="item"
        :theme="theme"
      />
    </div>

    <!-- editMode: true -->
    <div v-else class="inner edit-mode">
      <div class="controls">
        <NovaButtonText
          :label="$t('creatorMyPage.home.linkUrlPlaceholder')"
          :theme="'primary-blue'"
          :size="32"
          :loading="loading"
          @click="handleOnAddDummy"
        />
      </div>

      <NovaBoxSocialLinksItemEditMode
        v-for="(item, index) in initSocials"
        :ref="(el) => handleOnUpdateSocialsRefs(el, index)"
        :key="item.expsrOrdr"
        :source="item"
        :index="index"
        :loading="loading"
        @update:modify="handleOnModifySocial"
        @update:remove="handleOnRemoveSocial"
      />

      <div class="actions">
        <NovaButtonText
          :label="$t('cancel')"
          :theme="'secondary-gray'"
          :size="32"
          :loading="loading"
          @click="handleOnTurnOffEditMode"
        />

        <NovaButtonText
          :label="$t('save')"
          :theme="'primary-blue'"
          :size="32"
          :loading="loading"
          @click="handleOnUpdateSocials"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.social-links {
  > .inner {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &.edit-mode {
      gap: 8px !important;
    }

    .controls {
      display: flex;
      justify-content: flex-end;
    }

    .actions {
      display: flex;
      gap: 8px;
      justify-content: flex-end;
    }
  }

  &.theme-bar {
    > .inner:not(.theme-default) {
      gap: 12px;
    }
  }
}
</style>
