<script setup lang="ts">
import { Tippy } from 'vue-tippy'

const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const userStore = useUserStore()
const { user } = storeToRefs(userStore)
const editMode = ref(false)
const loading = ref(false)

const handleOnToggleEditMode = () => {
  gtEvent('userAction', {
    eventCategory: '사용자',
    eventAction: `크리에이터 카테고리 수정 ${
      !editMode.value ? '열기' : '닫기'
    }`,
    eventLabel: t('accountPanel.settings.creatorCategory.title'),
    eventSlot: '프로필 수정 > 크리에이터 카테고리',
    eventI18nAddr: useKoreanTranslation(
      'accountPanel.settings.creatorCategory.title'
    ),
    eventComponent: 'Button',
  })
  editMode.value = !editMode.value
}
</script>

<template>
  <NovaBoxAccountSection
    :title="t('accountPanel.settings.creatorLinkTree.title')"
  >
    <template #contents>
      <div class="account-section-wrap">
        <div class="inner">
          <NovaBoxSocialLinks
            :socials="user?.links || []"
            :edit-mode="editMode"
            @turn-off-edit-mode="handleOnToggleEditMode"
          />
        </div>
      </div>
    </template>

    <template #tools>
      <Tippy
        :append-to="'parent'"
        :content="t('modify')"
        :interactive="true"
        :theme="'simple'"
        :placement="'bottom'"
      >
        <NovaButtonIcon
          :icon="{ type: 'outline', name: 'write' }"
          :size="16"
          :loading="loading"
          class="btn-clipboard"
          @click="handleOnToggleEditMode"
        />
      </Tippy>
    </template>
  </NovaBoxAccountSection>
</template>

<style lang="scss" scoped>
.account-section-wrap {
  > .inner {
    display: flex;
    flex-direction: column;
    gap: 12px;

    > .actions {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      letter-spacing: -0.025em;
      text-align: left;
      color: $color-text-12;
    }
  }
}
</style>
