<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import {
  NotificationSettingViewType,
  type NotificationSettingViewStack,
} from './NovaBoxNotificationSetting.types'
import {
  LayerType,
  NotificationPushSoundCode,
  type UpdateNotificationPushPayload,
  type NotificationServiceSetting,
  type BooleanYn,
} from '@store'
import { NovaModalDialog } from '#components'

const { t } = useI18n()
const layoutStore = useLayoutStore()
const etcStore = useEtcStore()
const { show: modalShow, hide: modalHide } = useModal()
const {
  handleOnUpdateNotificationMarketing,
  handleOnUpdateNotificationService,
} = useUpdateNotification()

const isOpen = computed(() =>
  layoutStore.layers.includes(LayerType.NOTIFICATION_SETTING_PANEL)
)

const isReady = ref(false)

const swiper = ref<typeof Swiper>()
const stacks = computed<NotificationSettingViewStack[]>(() => [
  {
    id: NotificationSettingViewType.SETTING,
    depth: 0,
    name: t('notificationSetting.notificationAndSetting'),
  },
  {
    id: NotificationSettingViewType.PUSH_SOUND,
    depth: 1,
    name: t('notificationSetting.selectNotificationSound'),
  },
  {
    id: NotificationSettingViewType.SERVICE_DETAIL,
    depth: 1,
    name: etcStore.notificationServiceDetail?.ntcnNm || '',
  },
])
const curStack = ref(0)
const prevStack = ref(0)
const showBackBtn = computed(
  () => stacks.value[curStack.value].id !== NotificationSettingViewType.SETTING
)

// 알림 설정 팝업 열릴때 필요한 정보 조회
watch(
  () => isOpen.value,
  async (cur) => {
    if (cur) {
      try {
        isReady.value = false
        await useSleeper()
        await fetchNotificationPushSounds()
        await fetchNotificationSettings()
      } finally {
        isReady.value = true
      }
    }
  }
)

// 알림 설정 팝업 닫기
const handleOnClose = () => {
  layoutStore['layer:close'](LayerType.NOTIFICATION_SETTING_PANEL)
}

// 스와이퍼 초기화
const handleOnSetSwiper = (evt: typeof Swiper) => {
  swiper.value = evt
}

// 스와이퍼 슬라이드 뒤로가기
const handleOnBackStack = () => {
  curStack.value = prevStack.value
  stacks.value[curStack.value].id === NotificationSettingViewType.SETTING
    ? swiper.value?.slideTo(0)
    : swiper.value?.slideTo(swiper.value?.activeIndex - 1)
}

// 스와이퍼 슬라이드 이동
const handleOnGoStack = (stackId: NotificationSettingViewType) => {
  const stackIdx = stacks.value.findIndex((item) => item.id === stackId)
  // const stack = stacks.value.find((item) => item.id === stackId)
  const interval = setInterval(() => {
    goViewStack()
  }, 100)
  const goViewStack = () => {
    if (swiper.value?.el) {
      swiper.value.slideTo(stacks.value[stackIdx]?.depth)
      prevStack.value = curStack.value
      curStack.value = stackIdx

      clearInterval(interval)
    }
  }
}

// 푸시 알림음 목록 조회
const fetchNotificationPushSounds = async () => {
  if (!etcStore.notificationPushSounds.length) {
    try {
      await etcStore.fetchNotificationPushSounds()
    } catch {}
  }
}

// 알림 설정 목록 조회
const fetchNotificationSettings = async () => {
  try {
    await etcStore.fetchNotificationSettings()
  } catch {}
}

// 푸시 알림 설정 업데이트: 베이스
const updateNotificationPush = async (
  payload: UpdateNotificationPushPayload,
  loader?: (_loading: boolean) => void
) => {
  try {
    loader?.(true)
    await useSleeper()
    await etcStore.updateNotificationPush(payload)
  } catch {
    useToast(t('commonError.network.message'))
  } finally {
    loader?.(false)
  }
}

// 푸시 알림 설정 업데이트: 상태
const handleOnUpdateNotificationPushStatus = async (
  value: boolean,
  loader?: (_loading: boolean) => void
) => {
  const payload = {
    svcNtcnRecptnAt: (value ? 'Y' : 'N') as BooleanYn,
    ntcnChnnelAlarmTyCode:
      etcStore.notificationSetting!.push.pushSound.ntcnChnnelAlarmTyCode,
  }

  if (!value) {
    await modalShow({
      component: NovaModalDialog,
      bind: {
        name: modalsName.MODAL_DIALOG,
        btns: [
          {
            label: t('notificationSetting.pushDialog.confirm'),
            theme: 'primary-blue-light',
            size: 32,
            onClick: async () => {
              await modalHide(modalsName.MODAL_DIALOG)
              await updateNotificationPush(payload, loader)
            },
          },
        ],
      },
      slots: {
        title: t('notificationSetting.pushDialog.title'),
        content: t('notificationSetting.pushDialog.contents'),
      },
    })
  } else {
    await updateNotificationPush(payload, loader)
  }
}

// 푸시 알림 설정 업데이트: 사운드
const handleOnUpdateNotificationPushSound = async (
  value: NotificationPushSoundCode,
  loader?: (_loading: boolean) => void
) => {
  const payload = {
    svcNtcnRecptnAt: etcStore.notificationSetting!.push.svcNtcnRecptnAt,
    ntcnChnnelAlarmTyCode: value,
  }
  await updateNotificationPush(payload, loader)
}

// 서비스 알림 설정 상세 조회
const handleOnFetchNotificationServiceDetail = async (
  serviceId: NotificationServiceSetting['ntcnSetupId']
) => {
  await etcStore.fetchNotificationServiceDetail({ ntcnSetupId: serviceId })
}
</script>

<template>
  <NovaSettingPopup
    :title="stacks[curStack].name"
    :is-ready="isReady"
    :is-open="isOpen"
    :is-body-no-padding="true"
    :show-btn-back="showBackBtn"
    :on-back="handleOnBackStack"
    :on-close="handleOnClose"
    class="nova-box-notification-setting"
  >
    <template #contents>
      <Swiper
        v-if="isReady && etcStore.notificationSetting"
        class="swiper"
        :allow-touch-move="false"
        :auto-height="true"
        @swiper="handleOnSetSwiper"
      >
        <SwiperSlide>
          <!-- Depth 1. setting -->
          <NovaBoxNotificationSettingStackSetting
            :source="etcStore.notificationSetting"
            :go-stack="handleOnGoStack"
            :on-update-notification-push="handleOnUpdateNotificationPushStatus"
            :on-fetch-notification-service-detail="
              handleOnFetchNotificationServiceDetail
            "
            :on-update-notification-service="handleOnUpdateNotificationService"
            :on-update-notification-marketing="
              handleOnUpdateNotificationMarketing
            "
          />
        </SwiperSlide>

        <SwiperSlide>
          <!-- Depth 2. push sound -->
          <NovaBoxNotificationSettingStackNotificationSound
            v-if="
              stacks[curStack].id === NotificationSettingViewType.PUSH_SOUND
            "
            :push-sounds="etcStore.notificationPushSounds"
            :current-push-sound="
              etcStore.notificationSetting.push.pushSound.ntcnChnnelAlarmTyCode
            "
            :on-update-notification-push="handleOnUpdateNotificationPushSound"
          />

          <!-- Depth 2. service index -->
          <NovaBoxNotificationSettingStackServiceDetail
            v-if="
              stacks[curStack].id ===
                NotificationSettingViewType.SERVICE_DETAIL &&
              etcStore.notificationServiceDetail
            "
            :service-detail="etcStore.notificationServiceDetail"
            :on-update-notification-service="handleOnUpdateNotificationService"
          />
        </SwiperSlide>
      </Swiper>
    </template>
  </NovaSettingPopup>
</template>

<style lang="scss" scoped>
.swiper {
  width: 100%;

  :deep(.swiper-slide) {
    padding: 28px 18px 24px 18px;
  }
}

.notification-setting {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
</style>
