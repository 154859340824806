import { v4 } from 'uuid'
import * as CreatorType from './type'
import CreatorService from './service'
import { initCreatorState } from './init'
import type {
  RewardCountPayload,
  RewardDetailPayload,
  UpdateLiveStatusPayload,
  UpdateChatStatusPayload,
} from '@store/mypage/type'
import MyPageService from '@store/mypage/service'

export * from './init'
export * from './type'
export const useCreatorStore = defineStore('creator', () => {
  const { gtEvent } = useGoogleTag()
  const mypageStore = useMyPageStore()
  const userStore = useUserStore()
  const profile = ref(initCreatorState.profile)
  const myProfile = ref(initCreatorState.myProfile)
  const postsByHot = ref(initCreatorState.postsByHot)
  const contentsList = ref(initCreatorState.contentsList)
  const contents = ref(initCreatorState.contents)
  const commentContents = ref(initCreatorState.commentContents)
  const rewardContents = ref(initCreatorState.rewardContents)
  const rewardCount = ref(initCreatorState.rewardCount)
  const weeklyRewardCount = ref(initCreatorState.weeklyRewardCount)
  const weeklyRewardContents = ref(initCreatorState.weeklyRewardContents)
  const rewardChartData = ref(initCreatorState.rewardChartData)
  const rewardActivityChartData = ref(initCreatorState.rewardActivityChartData)
  const creatorGuestBooks = ref(initCreatorState.creatorGuestBooks)
  const creatorGuestBook = ref(initCreatorState.creatorGuestBook)
  const creatorNoticeList = ref(initCreatorState.creatorNoticeList)
  const tempCreatorNoticeList = ref(initCreatorState.tempCreatorNoticeList)
  const creatorNotice = ref(initCreatorState.creatorNotice)
  const creatorHome = ref(initCreatorState.creatorHome)
  const creatorSnsLinks = ref(initCreatorState.creatorSnsLinks)
  const creatorStatItem = ref(initCreatorState.creatorStatItem)
  const creatorShareStats = ref(initCreatorState.creatorShareStats)
  const creatorShareVideoStats = ref(initCreatorState.creatorShareVideoStats)
  const commentsAndReplies = ref(initCreatorState.commentsAndReplies)
  const commentPosts = ref(initCreatorState.commentPosts)
  const creatorCategories = ref(initCreatorState.creatorCategories)
  const creatorsByHomePersonal = ref(initCreatorState.creatorsByHomePersonal)
  const creatorsByHomeProject = ref(initCreatorState.creatorsByHomeProject)
  const creatorsByAll = ref(initCreatorState.creatorsByAll)
  const creatorsByRecommended = ref(initCreatorState.creatorsByRecommended)
  const creatorPremiumSupporters = ref(
    initCreatorState.creatorPremiumSupporters
  )
  const myCreators = ref(initCreatorState.myCreators)
  const creators = ref(initCreatorState.creators)
  const recommendCreators = ref(initCreatorState.recommendCreators)

  /**
   * 프로필 정보 조회
   */
  const fetchProfile = async (payload: CreatorType.CreatorProfilePayload) => {
    const { data } = await CreatorService.fetchProfile(payload)
    profile.value = data
    return data
  }

  /**
   * 내 프로필 정보 조회
   */
  const fetchMyProfile = async (
    payload: CreatorType.CreatorProfileByCreatorLinkPayload
  ) => {
    const { data } = await CreatorService.fetchProfileLink(payload)
    myProfile.value = data
  }

  /**
   * 크리에이터 링크를 통한 프로필 정보 조회
   */
  const fetchProfileLink = async (
    payload: CreatorType.CreatorProfileByCreatorLinkPayload
  ) => {
    const { data } = await CreatorService.fetchProfileLink(payload)
    profile.value = data
    profile.value.creatorLink = payload.creatorLink
    return profile.value
  }

  /**
   * 크리에이터 프로필 닉네임/소개 변경
   */
  const editProfile = async (
    payload: CreatorType.CreatorProfileEditPayload
  ) => {
    const editPayload = {
      proflDc: payload.proflDc,
      userNcnm: payload.userNcnm,
    }
    const creatorPayload = { creatorLink: payload.creatorLink }

    await MyPageService.editProfile(editPayload)
    await fetchProfileLink(creatorPayload)

    // 크리에이터 목록 닉네임 오버라이드: 모든 크리에이터 목록
    const overrideTargetIdxByAll = creatorsByAll.value.items.findIndex(
      (item) => item.userSn === profile.value!.userSn
    )
    if (overrideTargetIdxByAll !== -1)
      creatorsByAll.value.items[overrideTargetIdxByAll].creatorNcnm =
        payload.userNcnm

    // 크리에이터 목록 닉네임 오버라이드: 홈 > 개인 크리에이터 목록
    const overrideTargetIdxByHomePersonal =
      creatorsByHomePersonal.value.findIndex(
        (item) => item.userSn === profile.value!.userSn
      )
    if (overrideTargetIdxByHomePersonal !== -1)
      creatorsByHomePersonal.value[
        overrideTargetIdxByHomePersonal
      ].creatorNcnm = payload.userNcnm

    // 크리에이터 목록 닉네임 오버라이드: 홈 > 프로젝트 크리에이터 목록
    const overrideTargetIdxByHomeProject =
      creatorsByHomeProject.value.findIndex(
        (item) => item.userSn === profile.value!.userSn
      )
    if (overrideTargetIdxByHomeProject !== -1)
      creatorsByHomeProject.value[overrideTargetIdxByHomeProject].creatorNcnm =
        payload.userNcnm
  }

  /**
   *  프로필 사진 삭제
   */
  const deleteProfilePhoto = async (
    payload: CreatorType.CreatorProfileByCreatorLinkPayload
  ) => {
    const fileNameBeforeDelete = mypageStore.profile!.userProflUrl
    await MyPageService.deleteProfilePhoto()
    gtEvent('fileAction', {
      eventCategory: '파일',
      eventAction: '프로필 파일 삭제',
      eventLabel: '',
      eventSlot: '프로필 삭제 모달',
      eventI18nAddr: '',
      eventComponent: 'Button',
      fileName: fileNameBeforeDelete,
    })
    await fetchProfileLink(payload)
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '프로필 이미지 삭제',
      eventLabel: '',
      eventSlot: '프로필 삭제 모달',
      eventI18nAddr: '',
      eventComponent: 'Button',
    })
  }

  /**
   *  프로필 사진 등록 및 수정
   */
  const createProfilePhoto = async (
    payload: FormData,
    creatorProfileLinkPayload: CreatorType.CreatorProfileByCreatorLinkPayload
  ) => {
    await MyPageService.createProfilePhoto(payload)
    creatorProfileLinkPayload.creatorLink &&
      (await fetchProfileLink(creatorProfileLinkPayload))
  }

  /**
   * 크리에이터 포스트 목록 조회
   */
  const fetchPosts = async (
    payload: CreatorType.CreatorContentsItemListPayload,
    refresh = false
  ) => {
    if (contentsList.value.loading) {
      return
    }

    if (payload.orderBy === 'hot')
      payload.lastSumSvcActScore =
        contentsList.value.items[contentsList.value.items.length - 1]
          ?.sumSvcActScore || 0

    if (refresh) {
      delete payload.lastCmtyNttSn
      delete payload.lastSumSvcActScore
    }

    contentsList.value.loading = true
    const { data, headers } = await CreatorService.fetchPosts({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })

    contentsList.value = {
      items: refresh ? data : contentsList.value.items.concat(data),
      payload: {
        ...payload,
        pageNum: refresh ? 1 : payload.pageNum + 1,
        lastCmtyNttSn: data.length ? data[data.length - 1].cmtyNttSn : 0,
      },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
    contentsList.value.loading = false
  }

  /**
   * 콘텐츠 리스트 초기화
   */
  const initContentList = () => {
    contentsList.value = { ...initCreatorState.contentsList }
  }

  /**
   * 댓글 & 답글 목록 초기화(스토어에서)
   */
  const initCommentsAndReplies = () => {
    commentsAndReplies.value = { ...initCreatorState.commentsAndReplies }
  }

  /**
   * 내 보상 게시물 조회
   */
  const fetchRewardPosts = async (
    payload: CreatorType.RewardItemPayload,
    refresh = false
  ) => {
    const { data, headers } = await CreatorService.fetchRewardPosts({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })

    // 유니크 아이디 생성
    data.forEach((item) => {
      item.id = v4()
    })

    rewardContents.value = {
      items: refresh ? data : rewardContents.value.items.concat(data),
      payload: { ...payload, pageNum: refresh ? 1 : payload.pageNum + 1 },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * 내 보상 상세내역 조회
   */
  const fetchRewardDetail = async (payload: RewardDetailPayload) => {
    const { data } = await CreatorService.fetchRewardDetail(payload)

    // 보상 리스트 아이템에 상세 정보 주입
    rewardContents.value.items.some((item) => {
      if (item.id === payload.id) {
        item.detail = data
        return true
      }
      return false
    })
  }

  /**
   * 내 보상 게시물 개수 조회
   */
  const fetchRewardCount = async (payload: RewardCountPayload) => {
    const { data } = await CreatorService.fetchRewardCount(payload)
    rewardCount.value = data
  }

  /**
   * 총 보상량, 주간 예상 보상량 조회
   */
  const fetchWeeklyRewardCount = async () => {
    const { data } = await CreatorService.fetchWeeklyRewardCount()
    weeklyRewardCount.value = data
  }

  /**
   * 총 보상량, 주간 예상 보상량 조회
   */
  const fetchWeeklyRewardContents = async (
    payload: CreatorType.WeeklyRewardItemPayload,
    refresh = false
  ) => {
    const { data, headers } = await CreatorService.fetchWeeklyRewardContents({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })

    // 유니크 아이디 생성
    // data.forEach((item) => {
    //   item.id = v4()
    // })
    weeklyRewardContents.value = {
      followCount: data.followCount,
      recommendCount: data.recommendCount,
      items: refresh
        ? data.rewardDataList
        : weeklyRewardContents.value.items.concat(data.rewardDataList),
      payload: { ...payload, pageNum: refresh ? 1 : payload.pageNum + 1 },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * 총 보상량, 주간 예상 보상량 초기화
   */
  const deleteWeeklyRewardContents = () => {
    weeklyRewardContents.value = {
      items: [],
      followCount: 0,
      recommendCount: 0,
      payload: {
        pageNum: 0,
        pageSize: 20,
        nextPageSize: 20,
        svcActGroupClCode: 'RC',
      },
      refresh: false,
      last: false,
    }
  }

  /**
   * 크리에이터 활동 차트 데이터 조회
   */
  const fetchActivityRewardChart = async (
    payload: CreatorType.RewardChartPayload
  ) => {
    const { data } = await CreatorService.fetchActivityRewardChart(payload)
    rewardActivityChartData.value = data
  }

  /**
   * 총 리워드 차트 데이터 조회
   */
  const fetchRewardChart = async (payload: CreatorType.RewardChartPayload) => {
    const { data } = await CreatorService.fetchRewardChart(payload)
    rewardChartData.value = data
  }

  /**
   * 크리에이터 링크 중복 체크
   * @param payload
   */
  const fetchCheckDuplicateCreatorLink = (
    payload: CreatorType.CreatorLinkCheckDuplicatePayload
  ) => CreatorService.fetchCheckDuplicateCreatorLink(payload)

  /**
   * 크리에이터 링크 수정
   * @param payload
   */
  const reqUpdateCreatorLink = (
    payload: CreatorType.CreatorLinkUpdatePayload
  ) => CreatorService.reqUpdateCreatorLink(payload)

  /**
   * 크리에이터 목록 모델 업데이트
   * @param payload
   */
  const updateCreatorGuestBooksModel = (payload: {
    gustSn: CreatorType.CreatorGuestBook['gustSn']
    type: 'add' | 'delete' | 'replace'
    replace?: CreatorType.CreatorGuestBook
  }) => {
    if (
      (payload.type === 'replace' || payload.type === 'add') &&
      typeof payload.replace === 'undefined'
    )
      throw new Error('update creator guest books model replace not able')

    const guestbookIdx = creatorGuestBooks.value.items.findIndex(
      (item) => item.gustSn === payload.gustSn
    )

    if (
      guestbookIdx === -1 &&
      (payload.type === 'replace' || payload.type === 'delete')
    )
      return

    switch (payload.type) {
      case 'add':
        creatorGuestBooks.value.items.unshift(payload.replace!)
        break
      case 'delete':
        creatorGuestBooks.value.items.splice(guestbookIdx, 1)
        break
      case 'replace':
        creatorGuestBooks.value.items[guestbookIdx] = payload.replace!
        break
      default:
        throw new Error('update creator guest books model type is not match')
    }
  }

  /**
   * 크리에이터 방명록 리스트 조회
   * @param payload
   * @param refresh
   */
  const fetchCreatorGuestBooks = async (
    payload: CreatorType.CreatorGuestBooksPayload,
    refresh = false
  ) => {
    if (refresh) {
      delete payload.lastGustSn
    }

    const { data, headers } = await CreatorService.fetchCreatorGuestBooks({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })

    const items = refresh ? data : creatorGuestBooks.value.items.concat(data)

    creatorGuestBooks.value = {
      items,
      payload: {
        ...payload,
        pageNum: refresh ? 1 : payload.pageNum + 1,
        lastGustSn: items.length ? items[items.length - 1].gustSn : 0,
      },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * 크리에이터 방명록 상세 조회
   * @param payload
   */
  const fetchCreatorGuestBook = async (
    payload: CreatorType.CreatorGuestBookPayload
  ) => {
    const { data } = await CreatorService.fetchCreatorGuestBook(payload)
    creatorGuestBook.value = data
    updateCreatorGuestBooksModel({
      gustSn: payload.gustSn,
      type: 'replace',
      replace: data,
    })
  }

  /**
   * 크리에이터 방명록 등록
   * @param payload
   */
  const regCreatorGuestBook = async (
    payload: CreatorType.RegCreatorGuestBookPayload
  ) => {
    const { data } = await CreatorService.regCreatorGuestBook(payload)
    updateCreatorGuestBooksModel({
      gustSn: data.gustSn,
      type: 'add',
      replace: data,
    })
  }

  /**
   * 크리에이터 방명록 수정
   * @param payload
   */
  const updateCreatorGuestBook = async (
    payload: CreatorType.UpdateCreatorGuestBookPayload
  ) => {
    const { data } = await CreatorService.updateCreatorGuestBook(payload)
    creatorGuestBook.value = data
  }

  /**
   * 크리에이터 방명록 삭제
   * @param payload
   */
  const deleteCreatorGuestBook = async (
    payload: CreatorType.DeleteCreatorGuestBookPayload
  ) => {
    await CreatorService.deleteCreatorGuestBook(payload)
    updateCreatorGuestBooksModel({
      gustSn: payload.gustSn,
      type: 'delete',
    })
  }

  /**
   * 크리에이터 홈탭 정보조회
   * @param payload
   */
  const fetchCreatorHome = async (payload: CreatorType.CreatorHomePayload) => {
    const { data } = await CreatorService.fetchCreatorHome(payload)

    data.links?.forEach((item) => {
      switch (item.optionTyCode) {
        case 'FCBK':
          item.class = 'icon-facebook'
          break
        case 'YUTB':
          item.class = 'icon-youtube'
          break
        case 'INTA':
          item.class = 'icon-instagram'
          break
        case 'TWIR':
          item.class = 'icon-twitter'
          break
        default:
          item.class = 'icon-homepage'
          break
      }
    })

    creatorHome.value = data
    return data
  }

  /**
   * 크리에이터홈 인기포스터 조회
   * @param payload
   * @param refresh
   */
  const fetchCreatorHomeHotPost = async (
    payload: CreatorType.CreatorHomePayload,
    refresh = false
  ) => {
    const _payload = {
      ...postsByHot.value.payload,
      ...payload,
      pageNum: refresh ? 1 : postsByHot.value.payload.pageNum + 1,
    }
    const { data, headers } = await CreatorService.fetchCreatorHomeHotPost(
      payload
    )
    updateCreatorHomeHotPosts({
      payload: _payload,
      items: refresh ? data : postsByHot.value.items.concat(data),
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    })
  }

  /**
   * 크리에이터홈 인기포스트 업데이트
   * @param payload
   */
  const updateCreatorHomeHotPosts = (payload: typeof postsByHot.value) => {
    postsByHot.value = payload

    console.log('updateCreatorHomeHotPosts update model:', postsByHot.value)
  }

  /**
   * 크리에이터 공지사항 리스트 조회
   * @param payload
   * @param refresh
   */
  const fetchCreatorNoticeList = async (
    payload: Partial<CreatorType.CreatorNoticeListPayload>,
    refresh = false
  ) => {
    const _payload = {
      ...creatorNoticeList.value.payload,
      ...payload,
    }

    if (refresh) {
      delete _payload.lastCrtrNttSn
    }

    const { data, headers } = await CreatorService.fetchCreatorNoticeList(
      _payload
    )
    const items = refresh ? data : creatorNoticeList.value.items.concat(data)

    creatorNoticeList.value = {
      items,
      payload: {
        ..._payload,
        lastCrtrNttSn: items.length ? items[items.length - 1].crtrNttSn : 0,
      },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * 크리에이터 임시저장 공지사항 리스트 조회
   * @param payload
   */
  const fetchTempCreatorNoticeList = async (
    payload: CreatorType.TempCreatorNoticeListPayload
  ) => {
    try {
      const { data } = await CreatorService.fetchTempCreatorNoticeList(payload)
      tempCreatorNoticeList.value = data
    } catch (err: any) {
      console.log('err', err)
    }
  }

  /**
   * 크리에이터 공지사항 상세 조회
   * @param payload
   */
  const fetchCreatorNotice = async (
    payload: CreatorType.CreatorNoticePayload
  ) => {
    const { data } = await CreatorService.fetchCreatorNotice(payload)
    creatorNotice.value = data
    return data
  }

  /**
   * 크리에이터 공지사항 초기화
   * @description api 호출 없는 모델 업데이트
   */
  const initCreatorNotice = () => {
    creatorNotice.value = initCreatorState.creatorNotice
  }

  /**
   * 크리에이터 홈 공지사항 등록
   * @param payload
   */
  const regCreatorNotice = async (
    payload: CreatorType.RegCreatorNoticePayload
  ) => {
    try {
      await CreatorService.regCreatorNotice(payload)
    } catch (err) {
      console.log('err', err)
    }
  }

  /**
   * 크리에이터 홈 공지사항 수정
   * @param payload
   */
  const updateCreatorNotice = (
    payload: CreatorType.UpdateCreatorNoticePayload
  ) => CreatorService.updateCreatorNotice(payload)

  /**
   * 크리에이터 홈 공지사항 삭제
   * @param payload
   */
  const deleteCreatorNotice = (
    payload: CreatorType.DeleteCreatorNoticePayload
  ) => CreatorService.deleteCreatorNotice(payload)

  /**
   * 크리에이터설정정보 SNS링크 조회
   * @param payload
   */
  const fetchCreatorSocials = async (
    payload: CreatorType.CreatorSnslinkPayload
  ) => {
    const { data } = await CreatorService.fetchCreatorSocials(payload)
    creatorSnsLinks.value = data
  }

  /**
   * 크리에이터 소셜 목록 수정
   * @param payload
   */
  const updateCreatorSocials = async (
    payload: CreatorType.RegCreatorSocial[]
  ) => {
    const { data } = await CreatorService.updateCreatorSocials(payload)

    if (mypageStore.profile) {
      if (
        profile.value &&
        mypageStore.profile.userSn === profile.value.userSn
      ) {
        profile.value.snslinks = data
      }

      mypageStore.profile.links = data
    }
  }

  /**
   * 크리에이터 통계정보 조회
   * @param payload
   */
  const fetchCreatorStatsInfo = async (
    payload: CreatorType.CreatorStatPayload
  ) => {
    const { data } = await CreatorService.fetchCreatorStatsInfo(payload)
    creatorStatItem.value = data
  }

  /**
   * 크리에이터 통계정보 공유한 콘텐츠 정보 목록
   * @param payload
   * @param refresh
   */
  const fetchCreatorShareStatsList = async (
    payload: CreatorType.CreatorShareStatPayload,
    refresh = false
  ) => {
    const { data, headers } = await CreatorService.fetchCreatorShareStatsList({
      ...payload,
      pageNo: refresh ? 1 : payload.pageNo + 1,
    })

    const items = refresh ? data : creatorShareStats.value.items.concat(data)

    creatorShareStats.value = {
      items,
      payload: {
        ...payload,
        pageNo: refresh ? 1 : payload.pageNo + 1,
        lastCmtyNttSn: items.length ? items[items.length - 1].cmtyNttSn : 0,
      },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  const fetchCreatorShareStatsVideoList = async (
    payload: CreatorType.CreatorShareStatPayload,
    refresh = false
  ) => {
    const { data, headers } = await CreatorService.fetchCreatorShareStatsList({
      ...payload,
      pageNo: refresh ? 1 : payload.pageNo + 1,
    })

    const items = refresh
      ? data
      : creatorShareVideoStats.value.items.concat(data)

    creatorShareVideoStats.value = {
      items,
      payload: {
        ...payload,
        pageNo: refresh ? 1 : payload.pageNo + 1,
        lastCmtyNttSn: items.length ? items[items.length - 1].cmtyNttSn : 0,
      },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * 크리에이터 소개정보 등록/수정
   * @param payload
   */
  const regCreatorIntroduction = async (
    payload: CreatorType.RegCreatorIntroductionPayload
  ) => {
    await CreatorService.regCreatorIntoduction(payload)

    // 크리에이터 홈 > 크리에이터 소개 업데이트(동일 사용자일 경우)
    if (profile.value && userStore.user?.userSn === profile.value?.userSn) {
      profile.value.introduction = payload.optionCharstVal
    }

    // 자신의 모델 업데이트
    if (mypageStore.profile) {
      mypageStore.profile.introduce = payload.optionCharstVal
    }
  }

  /**
   * 크리에이터 신청 요건 충족 정보 조회
   */
  const fetchApplyCreatorConditions = async () => {
    const { data } = await CreatorService.fetchApplyCreatorConditions()

    // 크리에이터 전환 상태 업데이트
    if (mypageStore.profile) {
      const recentCondition = data.find(
        (condition) => !!condition.crtfcProgrsSttusCode
      )

      if (!recentCondition) {
        mypageStore.profile = {
          ...mypageStore.profile,
          creatorApplicationAvailableAt: 'Y',
          crtfcProgrsSttusCode: null,
          crtrRegistSttusCode: null,
        }
      } else {
        mypageStore.profile = {
          ...mypageStore.profile,
          creatorApplicationAvailableAt: recentCondition.applicationAvailableAt,
          crtfcProgrsSttusCode: recentCondition.crtfcProgrsSttusCode,
          crtrRegistSttusCode: recentCondition.crtfcProgrsSttusCode,
          resultGradCndTyCode: recentCondition.resultGradCndTyCode,
        }
      }
    }

    return data
  }

  /**
   * 크리에이터 신청: 심사 요건중 하나 이상 충족시
   * @param payload
   */
  const reqApplyCreatorSatisfyYes = (
    payload: CreatorType.ApplyCreatorSatisfyYesPayload
  ) => CreatorService.reqApplyCreatorSatisfyYes(payload)

  /**
   * 크리에이터 심사 요청
   */
  const reqApplyCreator = (payload: CreatorType.ApplyCreatorPayloadFormData) =>
    CreatorService.reqApplyCreator(payload)

  /**
   * 크리에이터 콘텐츠 팔로우, 언팔로우 업데이트
   */
  const updateCreatorContentsFollowUnfollow = (payload: {
    userSn: number
    follow: boolean
  }) => {
    // 1. 크리에이터 프로필 팔로우, 언팔로우 상태 업데이트
    // if (this.profile && this.profile.userSn === payload.userSn) {
    if (profile.value?.userSn === payload.userSn) {
      profile.value.followerYn = payload.follow ? 'Y' : 'N'
    }

    // 2. 크리에이터 포스트(핫) 리스트 상태 업데이트
    postsByHot.value.items.forEach((item) => {
      if (item.userSn === payload.userSn) {
        item.followFlag = payload.follow ? '1' : '0'
      }
    })

    // 3. 크리에이터 콘텐츠(포스트) 리스트 상태 업데이트
    contentsList.value.items.forEach((item) => {
      if (item.userSn === payload.userSn) {
        item.followFlag = payload.follow ? '1' : '0'
      }
    })
  }

  /**
   * 크리에이터 카테고리 목록 조회
   * @param payload
   */
  const fetchCreatorCategories = async (
    payload: CreatorType.CreatorCategoriesPayload
  ) => {
    const { data } = await CreatorService.fetchCreatorCategories(payload)

    creatorCategories.value = data
    return data
  }

  /**
   * 크리에이터 목록 조회: 홈
   * @param payload
   */
  const fetchCreatorsByHome = async (
    payload: CreatorType.CreatorsByHomePayload
  ) => {
    if (payload.crtrCtgrySN === -1) {
      delete payload.crtrCtgrySN
    }

    const { data: creators } = await CreatorService.fetchCreatorsByHome(payload)

    switch (payload.crtrOrgnztTyCode) {
      case 'BIZ':
        creatorsByHomeProject.value = creators
        break
      case 'IND':
      default:
        creatorsByHomePersonal.value = creators
        break
    }
  }

  /**
   * 크리에이터 목록 조회: 전체
   * @param payload
   * @param refresh
   */
  const fetchCreatorsByAll = async (
    payload: CreatorType.CreatorsByAllPayload,
    refresh = false
  ) => {
    if (payload.crtrCtgrySN === -1) {
      delete payload.crtrCtgrySN
    }

    if (creatorsByAll.value.loading) {
      return
    }

    creatorsByAll.value.loading = true
    const { data, headers } = await CreatorService.fetchCreatorsByAll({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })

    creatorsByAll.value = {
      items: refresh ? data : creatorsByAll.value.items.concat(data),
      payload: { ...payload, pageNum: refresh ? 1 : payload.pageNum + 1 },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
      loading: false,
    }
  }

  /**
   * 크리에이터 목록 조회: 노바+ (추천)
   * @param payload
   * @param refresh
   */
  const fetchCreatorsByRecommended = async (
    payload: CreatorType.CreatorsByRecommendedPayload,
    refresh = false
  ) => {
    if (payload.crtrCtgrySN === -1) {
      delete payload.crtrCtgrySN
    }

    if (creatorsByRecommended.value.loading) {
      return
    }

    creatorsByRecommended.value.loading = true

    const { data, headers } = await CreatorService.fetchCreatorsByRecommended({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })

    creatorsByRecommended.value = {
      items: refresh ? data : creatorsByRecommended.value.items.concat(data),
      payload: { ...payload, pageNum: refresh ? 1 : payload.pageNum + 1 },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
      loading: false,
    }
  }

  /**
   *  크리에이터 대표 프로필 사진등록/변경
   *  @param formData
   */
  const regCreatorImageUpdate = async (formData: FormData) => {
    const { data } = await CreatorService.regCreatorImageUpdate(formData)
    console.log(data)
  }

  /**
   * 크리에이터 카테고리 변경
   * @param payload
   */
  const updateCreatorCategory = async (
    payload: CreatorType.UpdateCreatorCategory
  ) => {
    await CreatorService.updateCreatorCategory(payload)

    if (mypageStore.profile) {
      mypageStore.profile.crtrCtgrySn = payload.crtrCtgrySn
      const ctgryName = creatorCategories.value.find(
        (item) => item.crtrCtgrySn === payload.crtrCtgrySn
      )?.ctgryNm
      mypageStore.profile.ctgryNm = ctgryName || ''
    }
  }

  /**
   * 크리에이터 프리미엄 약관 목록 조회
   * @param payload
   */
  const fetchPremiumTerms = async (
    payload: CreatorType.PremiumTermsPayload
  ) => {
    const { data } = await CreatorService.fetchPremiumTerms(payload)
    return data
  }

  /**
   * 크리에이터 프리미엄 약관 상세 조회
   * @param payload
   */
  const fetchPremiumTerm = async (payload: CreatorType.PremiumTermPayload) => {
    const { data } = await CreatorService.fetchPremiumTerm(payload)
    return data
  }

  /**
   * 크리에이터 프리미엄 상품 정보 조회
   * @param payload
   */
  const fetchPremium = async (payload: CreatorType.PremiumPayload) => {
    const { data } = await CreatorService.fetchPremium(payload)
    return data
  }

  /**
   * 크리에이터 프리미엄 개설(신청) 정보(혜택/유의사항) 조회
   */
  const fetchPremiumApplication = async () => {
    const { data } = await CreatorService.fetchPremiumApplication()
    return data
  }

  /**
   * 크리에이터 프리미엄 개설(신청)
   */
  const reqPremiumApplication = async (
    payload: CreatorType.PremiumApplicationOpenedPayload
  ) => {
    await CreatorService.reqPremiumApplication(payload)
    const mypageStore = useMyPageStore()

    // 1. 내 프로필 정보 업데이트
    if (mypageStore.profile) {
      mypageStore.profile.prmbrshAt = 'Y'
    }

    // 2. GNB 사용자 패널 > 개설 여부 업데이트
    if (myProfile.value) {
      myProfile.value.prmbrshAt = 'Y'
    }
  }

  /**
   * 프리미엄 혜택 목록 수정
   */
  const updatePremiumBenefits = (
    payload: CreatorType.UpdatePremiumBenefitsPayload
  ) => CreatorService.updatePremiumBenefits(payload)

  /**
   * 크리에이터 프리미엄 혜택 목록 조회
   */
  const fetchPremiumBenefits = async (
    payload: CreatorType.PremiumBenefitsPayload
  ) => {
    const { data } = await CreatorService.fetchPremiumBenefits(payload)
    return data
  }

  /**
   * 크리에이터 프리미엄 혜택 목록 전체 조회
   */
  const fetchPremiumBenefitsAll = async (
    payload: CreatorType.PremiumBenefitsPayload
  ) => {
    const { data } = await CreatorService.fetchPremiumBenefitsAll(payload)
    return data
  }

  /**
   * 크리에이터 프리미엄 후원(구독) 신청
   */
  const reqPremiumDonate = async (
    payload: CreatorType.PremiumDonatePayload
  ) => {
    const { data } = await CreatorService.reqPremiumDonate(payload)
    return data
  }

  /**
   * 크리에이터 프리미엄 후원(구독) 결제 완료 여부, 주문 정보 조회
   */
  const fetchPremiumPaymentTradeInfo = async (
    payload: CreatorType.PremiumPaymentTradeInfoPayload
  ) => {
    const { data } = await CreatorService.fetchPremiumPaymentTradeInfo(payload)
    return data
  }

  /**
   * 크리에이터 정산 은행 정보 등록
   */
  const createCreatorBank = async (payload: CreatorType.CreatorBankPayload) => {
    await CreatorService.createCreatorBank(payload)

    if (myProfile.value) {
      myProfile.value = { ...myProfile.value, ...payload }
    }
  }

  /**
   * 프리미엄 후원자 목록 조회
   */
  const fetchPremiumSupporters = async (
    payload: CreatorType.PremiumSupportersPayload
  ) => {
    if (!payload.searchKeyword) delete payload.searchKeyword
    const { data } = await CreatorService.fetchPremiumSupporters(payload)
    // 후원자 상태 업데이트
    creatorPremiumSupporters.value = {
      items: data.list,
      payload,
      pagination: {
        currentPage: payload.pageNum,
        itemPerPage: payload.pageSize,
        pagePerScope: creatorPremiumSupporters.value.pagination.pagePerScope,
        totals: data.totalCount,
        pageStartIdx: (payload.pageNum - 1) * payload.pageSize,
      },
    }
  }

  /**
   * 크리에이터 라이브 상태 업데이트
   */
  const updateLiveStatus = (payload: UpdateLiveStatusPayload) => {
    if (profile.value) {
      profile.value = {
        ...profile.value,
        ...payload,
      }
    }
  }

  /**
   * 크리에이터 채팅 상태 업데이트
   */
  const updateChatStatus = (payload: UpdateChatStatusPayload) => {
    if (profile.value) {
      profile.value = {
        ...profile.value,
        ...payload,
      }
    }
  }

  /**
   * 크리에이터 업데이트
   */
  const updateCreatorProfile = (payload: CreatorType.CreatorProfile) => {
    profile.value = payload
  }

  /**
   * 나의 크리에이터 목록 조회
   */
  const fetchMyCreators = async ({
    payload,
    refresh = false,
  }: {
    payload?: Partial<CreatorType.MyCreatorsPayload>
    refresh?: boolean
  }) => {
    myCreators.value.loading = true

    const _payload: CreatorType.MyCreatorsPayload = {
      pageNum: payload?.pageNum || myCreators.value.payload.pageNum + 1,
      pageSize: payload?.pageSize || myCreators.value.payload.pageSize,
      crtrCtgrySn: payload?.crtrCtgrySn || myCreators.value.payload.crtrCtgrySn,
      randSeed: payload?.randSeed || myCreators.value.items.randSeed,
    }

    if (_payload.crtrCtgrySn === -1) {
      delete _payload.crtrCtgrySn
    }

    if (refresh) {
      _payload.pageNum = 1
      delete _payload.randSeed
    }

    Object.keys(_payload).forEach(
      (key: keyof CreatorType.MyCreatorsPayload) => {
        if (typeof _payload[key] === 'undefined') {
          delete _payload[key]
        }
      }
    )

    const { data } = await CreatorService.fetchMyCreators({
      ..._payload,
    })

    myCreators.value = {
      items: {
        ...data,
        creatorList: refresh
          ? data.creatorList
          : myCreators.value.items.creatorList.concat(data.creatorList),
      },
      payload: _payload,
      refresh,
      last: data.moreAt === 'N',
      loading: false,
    }
  }

  /**
   * 크리에이터 목록 조회
   */
  const fetchCreators = async ({
    payload,
    refresh = false,
  }: {
    payload?: Partial<CreatorType.AllCreatorsPayload>
    refresh?: boolean
  }) => {
    creators.value.loading = true

    const _payload: CreatorType.AllCreatorsPayload = {
      pageNum: payload?.pageNum || creators.value.payload.pageNum + 1,
      pageSize: payload?.pageSize || creators.value.payload.pageSize,
      crtrCtgrySn: payload?.crtrCtgrySn || creators.value.payload.crtrCtgrySn,
      randSeed: payload?.randSeed || creators.value.items.randSeed,
    }

    if (_payload.crtrCtgrySn === -1) {
      delete _payload.crtrCtgrySn
    }

    if (refresh) {
      _payload.pageNum = 1
      delete _payload.randSeed
    }

    Object.keys(_payload).forEach(
      (key: keyof CreatorType.AllCreatorsPayload) => {
        if (typeof _payload[key] === 'undefined') {
          delete _payload[key]
        }
      }
    )

    const { data } = await CreatorService.fetchCreators({
      ..._payload,
    })

    creators.value = {
      items: {
        ...data,
        creatorList: refresh
          ? data.creatorList
          : creators.value.items.creatorList.concat(data.creatorList),
      },
      payload: _payload,
      refresh,
      last: data.moreAt === 'N',
      loading: false,
    }
  }

  /**
   * 추천 크리에이터 목록 조회
   */
  const fetchRecommendCreators = async ({
    payload,
    refresh = false,
  }: {
    payload?: Partial<CreatorType.RecommendCreatorsPayload>
    refresh?: boolean
  }) => {
    recommendCreators.value.loading = true

    const _payload: CreatorType.RecommendCreatorsPayload = {
      pageNum: payload?.pageNum || recommendCreators.value.payload.pageNum + 1,
      pageSize: payload?.pageSize || recommendCreators.value.payload.pageSize,
      crtrCtgrySn:
        payload?.crtrCtgrySn || recommendCreators.value.payload.crtrCtgrySn,
      randSeed: payload?.randSeed || recommendCreators.value.items.randSeed,
    }

    if (_payload.crtrCtgrySn === -1) {
      delete _payload.crtrCtgrySn
    }

    if (refresh) {
      _payload.pageNum = 1
      delete _payload.randSeed
    }

    Object.keys(_payload).forEach(
      (key: keyof CreatorType.RecommendCreatorsPayload) => {
        if (typeof _payload[key] === 'undefined') {
          delete _payload[key]
        }
      }
    )

    const { data } = await CreatorService.fetchRecommendCreators({
      ..._payload,
    })

    recommendCreators.value = {
      items: {
        ...data,
        creatorList: refresh
          ? data.creatorList
          : creators.value.items.creatorList.concat(data.creatorList),
      },
      payload: _payload,
      refresh,
      last: data.moreAt === 'N',
      loading: false,
    }
  }

  return {
    profile,
    myProfile,
    postsByHot,
    contentsList,
    contents,
    commentContents,
    rewardContents,
    rewardCount,
    weeklyRewardCount,
    weeklyRewardContents,
    creatorGuestBooks,
    creatorGuestBook,
    creatorNoticeList,
    tempCreatorNoticeList,
    creatorNotice,
    creatorHome,
    creatorSnsLinks,
    creatorStatItem,
    creatorShareStats,
    creatorShareVideoStats,
    commentsAndReplies,
    commentPosts,
    creatorCategories,
    creatorsByHomePersonal,
    creatorsByHomeProject,
    creatorsByAll,
    creatorsByRecommended,
    creatorPremiumSupporters,
    myCreators,
    creators,
    recommendCreators,
    fetchProfile,
    fetchMyProfile,
    fetchProfileLink,
    editProfile,
    deleteProfilePhoto,
    createProfilePhoto,
    fetchPosts,
    initContentList,
    initCommentsAndReplies,
    fetchRewardPosts,
    fetchRewardDetail,
    fetchRewardCount,
    fetchWeeklyRewardCount,
    fetchWeeklyRewardContents,
    deleteWeeklyRewardContents,
    rewardChartData,
    rewardActivityChartData,
    fetchRewardChart,
    fetchActivityRewardChart,
    fetchCheckDuplicateCreatorLink,
    reqUpdateCreatorLink,
    updateCreatorGuestBooksModel,
    fetchCreatorGuestBooks,
    fetchCreatorGuestBook,
    regCreatorGuestBook,
    updateCreatorGuestBook,
    deleteCreatorGuestBook,
    fetchCreatorHome,
    fetchCreatorHomeHotPost,
    updateCreatorHomeHotPosts,
    fetchCreatorNoticeList,
    fetchTempCreatorNoticeList,
    fetchCreatorNotice,
    initCreatorNotice,
    regCreatorNotice,
    updateCreatorNotice,
    deleteCreatorNotice,
    fetchCreatorSocials,
    updateCreatorSocials,
    fetchCreatorStatsInfo,
    fetchCreatorShareStatsList,
    fetchCreatorShareStatsVideoList,
    regCreatorIntroduction,
    fetchApplyCreatorConditions,
    reqApplyCreatorSatisfyYes,
    reqApplyCreator,
    updateCreatorContentsFollowUnfollow,
    fetchCreatorCategories,
    fetchCreatorsByHome,
    fetchCreatorsByAll,
    fetchCreatorsByRecommended,
    regCreatorImageUpdate,
    updateCreatorCategory,
    fetchPremiumTerms,
    fetchPremiumTerm,
    fetchPremium,
    fetchPremiumApplication,
    reqPremiumApplication,
    updatePremiumBenefits,
    fetchPremiumBenefits,
    fetchPremiumBenefitsAll,
    reqPremiumDonate,
    fetchPremiumPaymentTradeInfo,
    createCreatorBank,
    fetchPremiumSupporters,
    updateLiveStatus,
    updateChatStatus,
    updateCreatorProfile,
    fetchMyCreators,
    fetchCreators,
    fetchRecommendCreators,
  }
})
