<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { Swiper, SwiperSlide } from 'swiper/vue'
import type { CSSProperties } from 'vue'
import type { MenuPanelProps } from './NovaHeaderMenuPanel.types'
import { LayerType } from '@store'

const props = defineProps<MenuPanelProps>()

const { gtEvent } = useGoogleTag()
const layoutStore = useLayoutStore()
const { responsive, headerRect, bottomTabBarRect } = storeToRefs(layoutStore)
const panelContainer = ref<HTMLElement | null>(null)
const swiper = ref<typeof Swiper | null>(null)
const isOpen = computed(() => layoutStore.layers.includes(LayerType.MENU_PANEL))
const styles = computed<CSSProperties>(() => {
  const mobile: CSSProperties = {
    maxHeight: `calc(100% - ${useGetStyleSize(
      bottomTabBarRect.value?.height || 0
    )})`,
  }

  switch (responsive.value) {
    case 'mobile':
      return mobile
    default:
      return {}
  }
})
const swiperStyles = computed(() => {
  const mobileStyles: CSSProperties = {
    height: '100%',
  }
  const otherStyles: CSSProperties = {
    height: 'auto',
    maxHeight: `calc(100vh - ${useGetStyleSize(
      (headerRect.value?.height || 0) + (bottomTabBarRect.value?.height || 60)
    )})`,
  }

  return layoutStore.responsive === 'mobile' ? mobileStyles : otherStyles
})

onClickOutside(panelContainer, (evt) => {
  const clickedToggleButton =
    (evt.target as HTMLElement)
      .closest('.toggle-panel-button')
      ?.getAttribute('toggle-panel') === props.panelName

  if (!isOpen.value || clickedToggleButton) return
  layoutStore['layer:close'](LayerType.MENU_PANEL)
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '메뉴 패널 닫기',
    eventLabel: 'onClickOutside',
    eventSlot: '',
    eventI18nAddr: '',
    eventComponent: 'Layer',
  })
})

// init swiper
const handleOnSetSwiper = (evt: typeof Swiper) => {
  swiper.value = evt
}
</script>

<template>
  <div
    ref="panelContainer"
    :class="['menu-panel', { on: isOpen }]"
    :style="styles"
  >
    <div
      class="dim"
      @click="() => layoutStore['layer:close'](LayerType.MENU_PANEL)"
    />

    <NovaBoxBase :floating="true" class="menu-panel-inner" :round="0">
      <Swiper
        class="swiper"
        :allow-touch-move="false"
        :auto-height="true"
        :observer="true"
        :observe-parents="true"
        :style="swiperStyles"
        @swiper="handleOnSetSwiper"
      >
        <!-- Slide Depth: 1 -->
        <SwiperSlide>
          <NovaHeaderMenuPanelGate />
        </SwiperSlide>
      </Swiper>
    </NovaBoxBase>
  </div>
</template>

<style lang="scss" scoped>
.menu-panel {
  position: absolute;
  top: calc(74px + 16px);
  right: 16px;
  display: flex;
  justify-content: flex-end;
  width: $right-side-bar-width;
  opacity: 0;
  @include transition(top 0.2s ease-in-out, opacity 0.2s ease-in-out);
  pointer-events: none;
  z-index: $z-index-system-panel;

  &.on {
    top: 74px;
    opacity: 1;
    pointer-events: auto;
  }

  > .dim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hex-to-rgba($color-black, 0.65);
    opacity: 0;
    pointer-events: none;
    @include transition(opacity 0.2s ease-in-out);
    z-index: -1;
  }

  .menu-panel-inner {
    width: 100%;
    height: 100%;
    padding: 0;

    > .swiper {
      width: 100%;
      overflow-y: overlay;
    }
  }

  @include mobile {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

    &.on {
      top: 0;

      > .dim {
        opacity: 1;
        pointer-events: auto;
      }
    }

    > .menu-panel-inner {
      width: calc(100% - 50px);
      max-width: $mobile-side-panel-max-width;
      height: 100%;
      border-radius: 0;
      @include transition(all 0.2s ease-in-out);

      > .swiper {
        height: 100%;

        :deep(.swiper-wrapper) {
          height: 100% !important;
        }

        :deep(.swiper-slide) {
          height: 100%;
        }
      }
    }
  }
}
</style>
