<script setup lang="ts">
import type { NovaBoxPostCreateGateProps } from './NovaBoxPostCreateGate.types'
import { NovaBoxPopoverContainer } from '#components'
import { ROUTES } from '@configs'

const props = withDefaults(defineProps<NovaBoxPostCreateGateProps>(), {
  show: false,
  popoverPosition: 'bottom-start',
  onBeforeOpen: () => {},
})

const { gtEvent } = useGoogleTag()
const { show } = toRefs(props)
const { t } = useI18n()
const { userStore, showSignInDialog } = useMembershipProcess()
const popoverContainerRef = ref<typeof NovaBoxPopoverContainer>()
const createPremiumPost = computed(() => {
  const routeName = useGetRouteName()
  return routeName.includes(ROUTES.NOVAPLUS.name)
})

watch(
  () => show.value,
  (cur) => {
    if (!cur) {
      popoverHide()
    }
  }
)

const popoverHide = () => {
  popoverContainerRef.value?.hidePopover()
}

const handleOnBeforeOpenGate = async () => {
  props.onBeforeOpen()

  // 1. 로그인 체크
  if (!userStore.isSignIn) {
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '포스트 작성 버튼 클릭',
      eventLabel: t('createPost'),
      eventSlot: '로그인 체크',
      eventI18nAddr: useKoreanTranslation('createPost'),
      eventComponent: 'Button',
    })
    await showSignInDialog()
    return true
  }

  // TODO: 아래의 예외케이스가 발생할 여지가 있지만 Nova+ 페이지에서 해당 컴퍼넌트를 보여주지 않고 있다.
  // 2-1. 프리미엄 포스트 작성 조건 + 크리에터가 아닐때
  // if (createPremiumPost.value && userStore.user?.cmtyUserSeCode !== 'C') {
  //   await modalShow({
  //     component: NovaModalDialog,
  //     bind: {
  //       name: modalsName.MODAL_DIALOG,
  //       btns: [
  //         {
  //           label: t('cancel'),
  //           theme: 'transparent',
  //           size: 32,
  //           onClick: async () => {
  //             await modalHide(modalsName.MODAL_DIALOG)
  //           },
  //         },
  //         {
  //           label: t('confirm'),
  //           theme: 'primary-blue-light',
  //           size: 32,
  //           onClick: async () => {
  //             await modalHide(modalsName.MODAL_DIALOG)
  //             useNavigations({ external: false, url: '/apply-creator' )
  //           },
  //         },
  //       ],
  //     },
  //     slots: {
  //       title: t('subscribe.createPremiumPost.requireCreator.title'),
  //       content: t('subscribe.createPremiumPost.requireCreator.message', {
  //         subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
  //       }),
  //     },
  //   })
  //
  //   return
  // }

  // 2-2.프리미엄 포스트 작성 조건 + 크리에이터가 개설 하지 않음
  if (createPremiumPost.value && userStore.user?.prmbrshAt === 'N') {
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '포스트 작성 버튼 클릭',
      eventLabel: t('createPost'),
      eventSlot: '프리미엄 포스트 작성 조건 + 크리에이터가 개설 하지 않음',
      eventI18nAddr: useKoreanTranslation('createPost'),
      eventComponent: 'Button',
    })
    useToast(
      t('subscribe.createPremiumPost.requirePremiumOpened.toastMessage', {
        subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
      })
    )

    return true

    // await modalShow({
    //   component: NovaModalDialog,
    //   bind: {
    //     name: modalsName.MODAL_DIALOG,
    //     btns: [
    //       {
    //         label: t('cancel'),
    //         theme: 'transparent',
    //         size: 32,
    //         onClick: async () => {
    //           await modalHide(modalsName.MODAL_DIALOG)
    //         },
    //       },
    //       {
    //         label: t('confirm'),
    //         theme: 'primary-blue-light',
    //         size: 32,
    //         onClick: async () => {
    //           await modalHide(modalsName.MODAL_DIALOG)
    //           useNavigations(
    //               { external: false,
    //               url: `/creator/subscribe/opened/${userStore.user?.userSn}`}
    //           )
    //         },
    //       },
    //     ],
    //   },
    //   slots: {
    //     title: t('subscribe.createPremiumPost.requirePremiumOpened.title'),
    //     content: t(
    //         'subscribe.createPremiumPost.requirePremiumOpened.message',
    //         {
    //           subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
    //         }
    //     ),
    //   },
    // })
  }

  return false
}
</script>

<template>
  <NovaBoxPopoverContainer
    ref="popoverContainerRef"
    :position="popoverPosition"
    :trigger="'click'"
    :before-show="handleOnBeforeOpenGate"
  >
    <template #base>
      <slot name="button" />
    </template>

    <template #popover>
      <NovaBoxPostCreateGatePanel
        :create-premium-post="createPremiumPost"
        @on-hide="popoverHide"
      />
    </template>
  </NovaBoxPopoverContainer>
</template>

<style lang="scss" scoped></style>
