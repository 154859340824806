import * as PostTypes from './type'
import PostApi from './url'
import { api } from '@api'
import type { Hashtag } from '@store/etc/type'

export default {
  /**
   * 카테고리 목록 조회
   * @param cashing
   */
  async fetchCategories(cashing: boolean) {
    const headers = cashing ? {} : { 'If-None-Match': 1 }

    return await api.get<PostTypes.CategoryListItem[]>(
      PostApi.FETCH_CATEGORIES,
      {
        headers,
      }
    )
  },

  /**
   * 관리자 지정 해시태그 목록 조회
   * @param cashing
   */
  async fetchBaseHashtags(cashing: boolean) {
    const headers = cashing ? {} : { 'If-None-Match': 1 }
    return await api.get<Hashtag[]>(PostApi.FETCH_BASE_HASHTAGS, { headers })
  },

  /**
   * 포스트 상세 조회
   */
  fetchPost(payload: PostTypes.PostPayload) {
    return api.get<PostTypes.Post>(PostApi.FETCH_POST, {
      params: payload,
    })
  },

  /**
   * 포스트 리스트 조회
   * @param payload
   */
  fetchPosts(payload: PostTypes.PostsPayload) {
    return api.get<PostTypes.PostsItem[]>(PostApi.FETCH_POSTS, {
      params: payload,
    })
  },

  /**
   * 포스트 좋아요
   * @param payload
   */
  reqPostRecommend(payload: PostTypes.PostActionPayload) {
    return api.post<{}>(PostApi.REQ_POST_RECOMMEND, payload)
  },

  /**
   * 포스트 좋아요 취소하기
   * @param payload
   */
  reqPostRecommendCancel(payload: PostTypes.PostActionPayload) {
    return api.put<{}>(PostApi.REQ_PUT_UN_RECOMMEND, payload)
  },

  /**
   * 포스트 싫어요 하기
   * @param payload
   */
  reqPostUnRecommend(payload: PostTypes.PostActionPayload) {
    return api.post<{}>(PostApi.REQ_POST_UN_RECOMMEND, payload)
  },

  /**
   * 포스트 싫어요 취소하기
   * @param payload
   */
  reqPostUnRecommendCancel(payload: PostTypes.PostActionPayload) {
    return api.put<{}>(PostApi.REQ_POST_UN_RECOMMEND, payload)
  },

  /**
   * 포스트 스크랩 하기
   * @param payload
   */
  reqPostScrap(payload: PostTypes.PostActionPayload) {
    return api.post<{}>(PostApi.REQ_POST_SCRAP, payload)
  },

  /**
   * 포스트 스크랩 취소하기
   * @param payload
   */
  reqPostScrapCancel(payload: PostTypes.PostActionPayload) {
    return api.put<{}>(PostApi.REQ_POST_SCRAP, payload)
  },

  /**
   * 포스트 숨기기
   * @param payload
   */
  reqPostHide(payload: PostTypes.PostActionPayload) {
    return api.post<{ code: string }>(PostApi.REQ_POST_HIDE, payload)
  },

  /**
   * 포스트 신고하기
   * @param payload
   */
  reqPostReport(payload: PostTypes.PostReportPayload) {
    return api.post<{ code: string }>(PostApi.REQ_POST_REPORT, payload)
  },

  /**
   * 포스트 삭제하기
   * @param payload
   */
  reqPostRemove(payload: PostTypes.PostActionPayload) {
    return api.delete(PostApi.DELETE_POST, { params: payload })
  },

  /**
   * 포스트 공유
   */
  reqPostShare(payload: PostTypes.PostActionPayload) {
    return api.post(PostApi.REQ_POST_SHARE, payload)
  },

  /**
   * 포스트 등록
   * @param payload
   */
  createPost(payload: PostTypes.CreatePostPayload) {
    return api.post<PostTypes.PostsItem>(PostApi.CREATE_POST, payload)
  },

  /**
   * 포스트 수정
   */
  editPost(payload: PostTypes.EditPostPayload) {
    return api.put<PostTypes.PostsItem>(PostApi.EDIT_POST, payload)
  },

  /**
   * 임시 포스트 등록
   */
  createTempPost(payload: PostTypes.CreateTempPostPayload) {
    return api.post(PostApi.CREATE_TEMP_POST, payload)
  },

  /**
   * 임시 포스트 조회
   */
  fetchTempPost(payload: PostTypes.TempPostPayload) {
    return api.get<PostTypes.TempPost>(PostApi.FETCH_TEMP_POST, {
      params: payload,
    })
  },

  /**
   * 임시 포스트 삭제
   */
  deleteTempPost(payload: PostTypes.TempPostDeletePayload) {
    return api.delete(PostApi.DELETE_TEMP_POST, { params: payload })
  },

  /**
   * 임시 포스트 리스트
   */
  fetchTempPosts(payload: PostTypes.TempPostsPayload) {
    return api.get(PostApi.FETCH_TEMP_POSTS, { params: payload })
  },

  /**
   * 임시 사진 업로드
   */
  uploadTempImg(payload: FormData) {
    return api.post(PostApi.UPLOAD_TEMP_IMG, payload)
  },

  /**
   * 해시태그 랭킹 조회
   * @param payload
   */
  fetchPostsByHashtagRanking(payload: PostTypes.HashtagPostsPayload) {
    return api.get<PostTypes.HashtagPost[]>(`${PostApi.FETCH_HASHTAG_POSTS}`, {
      params: payload,
    })
  },

  /**
   * 댓글 & 답글 리스트 조회
   */
  fetchCommentsAndReplies(payload: PostTypes.CommentsAndRepliesPayload) {
    return api.get<PostTypes.CommentAndReply[]>(
      PostApi.FETCH_COMMENTS_AND_REPLIES,
      { params: payload }
    )
  },

  /**
   * 댓글 & 답글 작성
   */
  reqCommentAdd(payload: PostTypes.CommentAddPayload) {
    return api.post<PostTypes.CommentAndReply>(PostApi.REQ_COMMENT, payload)
  },

  /**
   * 댓글 & 답글 삭제
   */
  deleteComment(payload: PostTypes.CommentRemovePayload) {
    return api.delete(PostApi.REQ_COMMENT, { params: payload })
  },

  /**
   * 댓글 & 답글 수정
   */
  editComment(payload: PostTypes.CommentEditPayload) {
    return api.put<PostTypes.CommentAndReply>(PostApi.EDIT_COMMENT, payload)
  },

  /**
   * 댓글 & 답글 좋아요
   */
  reqCommentRecommend(payload: PostTypes.CommentRecommendPayload) {
    return api.post(PostApi.REQ_COMMENT_RECOMMEND, payload)
  },

  /**
   * 댓글 & 답글 좋아요 취소
   */
  reqCommentRecommendCancel(payload: PostTypes.CommentRecommendPayload) {
    return api.put(PostApi.REQ_COMMENT_RECOMMEND, payload)
  },

  /**
   * 댓글 & 답글 싫어요
   */
  reqCommentUnRecommend(payload: PostTypes.CommentRecommendPayload) {
    return api.post(PostApi.REQ_COMMENT_UN_RECOMMEND, payload)
  },

  /**
   * 댓글 & 답글 싫어요 취소
   */
  reqCommentUnRecommendCancel(payload: PostTypes.CommentRecommendPayload) {
    return api.put(PostApi.REQ_COMMENT_UN_RECOMMEND, payload)
  },

  /**
   * 댓글 & 답글 숨기기
   */
  reqCommentHide(payload: PostTypes.CommentHidePayload) {
    return api.post(PostApi.REQ_COMMENT_HIDE, payload)
  },

  /**
   * 댓글 & 답글 신고하기
   */
  reqCommentReport(payload: PostTypes.CommentReportPayload) {
    return api.post(PostApi.REQ_COMMENT_REPORT, payload)
  },

  /**
   * 크리에이터 목록 조회
   */
  fetchCreators(payload: PostTypes.CreatorsPayload) {
    return api.post<PostTypes.Creator[]>(PostApi.FETCH_CREATORS, null, {
      params: payload,
    })
  },

  /**
   * 비디오 섭네일 체크
   * @param url
   */
  checkThumbnail(url: string) {
    return api.get(url)
  },

  /**
   * 프리미엄 포스트 목록 조회
   */
  fetchPremiumPosts(payload: PostTypes.PremiumPostsPayload) {
    return api.get<PostTypes.PostsItem[]>(PostApi.FETCH_PREMIUM_POSTS, {
      params: payload,
    })
  },

  /**
   * 포스팅 후원
   */
  reqPostingDonation(payload: PostTypes.PostingDonationPayload) {
    return api.post(PostApi.REQ_POSTING_DONATION, payload)
  },

  /**
   * 포스트 발행 가능 여부 조회(카테고리 기준)
   */
  fetchPostCreateAvailable(payload: PostTypes.PostCreateAvailablePayload) {
    return api.get<PostTypes.PostCreateAvailable>(
      PostApi.FETCH_POST_CREATE_AVAILABLE,
      { params: payload }
    )
  },
}
