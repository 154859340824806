import apiPath from '@store/apiPath'

export default {
  /**
   * 프로필 정보 조회
   * @method GET
   */
  FETCH_PROFILE: `${apiPath.MYPAGE}/v1/profile`,

  /**
   * 닉네임/프로필 설명 수정
   * @method PUT
   */
  EDIT_PROFILE: `${apiPath.MYPAGE}/v1/profile`,

  /**
   * 프로필 사진 등록 / 변경
   * @method POST
   */
  CREATE_PROFILE_PHOTO: `${apiPath.MYPAGE}/v1/profile`,

  /**
   * 프로필 사진 삭제
   * @method DELETE
   */
  DELETE_PROFILE_PHOTO: `${apiPath.MYPAGE}/v1/profile`,

  /**
   * 내 포스트 목록 조회
   * @method GET
   */
  FETCH_POSTS: `${apiPath.MYPAGE}/v3/posts`,

  /**
   * 내 게시물 개수 조회
   * @method GET
   */
  FETCH_POST_COUNT: `${apiPath.MYPAGE}/v1/postCount`,

  /**
   * 좋아요 받은 리스트 목록 조회
   * @method GET
   */
  FETCH_RECEIVED_RECOMMEND_POSTS: `${apiPath.MYPAGE}/v1/receivedRecommend/posts`,

  /**
   * 좋아요 한 리스트 목록 조회
   * @method GET
   */
  FETCH_RECOMMEND_POSTS: `${apiPath.MYPAGE}/v1/recommend/posts`,

  /**
   * 내가 받은 좋아요 개수 조회
   * @method GET
   */
  FETCH_RECOMMEND_COUNT: `${apiPath.MYPAGE}/v1/recommendCount`,

  /**
   * 내 스크랩 게시물 리스트 목록 조회
   * @method GET
   */
  FETCH_SCRAP_POSTS: `${apiPath.MYPAGE}/v1/scraps`,

  /**
   * 내 스크랩 게시물 개수 조회
   * @method GET
   */
  FETCH_SCRAP_COUNT: `${apiPath.MYPAGE}/v1/scrapCount`,

  /**
   * 내 댓글 게시물 목록 조회
   * @method GET
   */
  FETCH_COMMENT_POSTS: `${apiPath.MYPAGE}/v1/replys`,

  /**
   * 내 댓글 개수 조회
   * @method GET
   */
  FETCH_COMMENT_COUNT: `${apiPath.MYPAGE}/v1/replyCount`,

  /**
   * 내 보상 목록 리스트 조회
   * @method GET
   */
  FETCH_REWARDS: `${apiPath.MYPAGE}/v2/rewards`,

  /**
   * 내 보상 상세내역 조회
   * @method GET
   */
  FETCH_REWARD_DETAIL: `${apiPath.MYPAGE}/v2/reward`,

  /**
   * 내 보상 개수 조회
   * @method GET
   */
  FETCH_REWARD_COUNT: `${apiPath.MYPAGE}/v1/reward/count`,

  /**
   * 레퍼럴 코드 등록
   * @method POST
   */
  REG_REFERRAL_CODE: `${apiPath.MYPAGE}/v1/referralCode`,

  /**
   * 후원 금액 조회
   * @method GET
   */
  FETCH_DONATION_QTY: `${apiPath.MYPAGE}/v1/donationQty`,

  /**
   * 후원 내역 조회(sent)
   * @method GET
   */
  FETCH_SENT_DONATIONS: `${apiPath.MYPAGE}/v1/donations`,

  /**
   * 후원 내역 조회(received)
   * @method GET
   */
  FETCH_RECEIVED_DONATIONS: `${apiPath.MYPAGE}/v1/receivedDonations`,

  /**
   * 마이홈 프로필 정보 조회(received)
   * @method GET
   */
  FETCH_HOME_PROFILE: `${apiPath.MYPAGE}/v1/home/profile`,

  /**
   * 마이페이지 배경이미지 등록
   */
  REG_PROFILE_BGIMG: `${apiPath.MYPAGE}/v1/background`,

  /**
   * 나의 프리미엄 가입 크리에이터 목록 조회
   */
  FETCH_PREMIUM_CREATORS: `${apiPath.MYPAGE}/v1/premium/creators`,

  /**
   * 노바+ 구독(후원) 상세 내역 조회
   * @method  GET
   */
  FETCH_PREMIUM_SPONSOR_DETAIL: `${apiPath.MYPAGE}/v1/premium/donation`,

  /**
   * 노바+ 구독(후원) 목록 조회
   * @method  GET
   */
  FETCH_PREMIUM_SPONSORS: `${apiPath.MYPAGE}/v1/premium/donations`,

  /**
   * 프리미엄 구독(후원) 만료 목록 조회
   */
  FETCH_PREMIUM_EXPIRED_LIST: `${apiPath.MYPAGE}/v1/premium/donations/expired`,
  /**
   * 팔로워 삭제
   * @method DELETE
   */
  DELETE_MY_FOLLOWER: `${apiPath.MYPAGE}/v1/follower`,
  /**
   * 팔로워 목록 조회
   * @method GET
   */
  FETCH_MY_FOLLOWERS: `${apiPath.MYPAGE}/v1/followers`,
  /**
   * 팔로잉 목록 조회
   * @method GET
   */
  FETCH_MY_FOLLOWINGS: `${apiPath.MYPAGE}/v1/followings`,
  /**
   * 사용자 차단 목록 조회
   * @method GET
   */
  FETCH_MY_BLOCKS: `${apiPath.MYPAGE}/v1/block`,
  /**
   * 마이 컬렉션 조회
   * @method GET
   */
  FETCH_MY_COLLECTIONS: `${apiPath.MYPAGE}/v1/collections`,
  /**
   * 마이 컬렉션 > nft 조회
   * @method GET
   */
  FETCH_MY_COLLECTION_NFT: `${apiPath.MYPAGE}/v1/collection/nft`,
  /**
   * 마이 컬렉션 > nft 조회 > 랜덤 박스 리빌
   * @method POST
   */
  REQ_RANDOM_BOX_REVEAL: `${apiPath.MYPAGE}/v1/reveal`,
}
