<script setup lang="ts">
import type {
  NovaBoxSocialLinksItemEditModeEmits,
  NovaBoxSocialLinksItemEditModeProps,
} from './NovaBoxSocialLinksItemEditMode.types'

const emit = defineEmits<NovaBoxSocialLinksItemEditModeEmits>()
const props = withDefaults(defineProps<NovaBoxSocialLinksItemEditModeProps>(), {
  loading: false,
})

const { source } = toRefs(props)
const { t } = useI18n()
const socialName = reactive({
  value: source.value.optionNcm,
  error: false,
})
const socialUrl = reactive({
  value: source.value.optionCharstVal,
  error: false,
})

const handleOnRemove = () => {
  emit('update:remove', props.index)
}

// 모든 에러 검사
const handleOnCheckError = (showErrToastMessage = false) => {
  if (
    isValidSocialName(socialName.value) ||
    isValidSocialUrl(socialUrl.value)
  ) {
    if (showErrToastMessage) {
      useToast(t('creatorMyPage.home.linkValidFail'))
    }

    return true
  }

  return false
}

// 유효성검사: 링크 이름
const isValidSocialName = (optionNcm: string) => {
  socialName.error = optionNcm.length === 0
  return socialName.error
}

// 유효성검사: 링크 주소
const isValidSocialUrl = (optionCharstVal: string) => {
  if (optionCharstVal.length) {
    socialUrl.error = !useCheckHttpProtocol(optionCharstVal)
  } else {
    socialUrl.error = true
  }
  return socialUrl.error
}

// hook: 링크 제목 변경
const handleOnUpdateTitle = (value: string) => {
  socialName.value = value
  emit(
    'update:modify',
    {
      ...source.value,
      optionNcm: socialName.value,
    },
    props.index
  )
}

// hook: 링크 url 변경
const handleOnUpdateUrl = (value: string) => {
  socialUrl.value = value
  emit(
    'update:modify',
    {
      ...source.value!,
      optionCharstVal: socialUrl.value,
    },
    props.index
  )
}

defineExpose({
  handleOnCheckError,
})
</script>

<template>
  <div class="social-link-item">
    <NovaInputSimple
      :model-value="socialName.value"
      :placeholder="$t('creatorMyPage.home.linkTitlePlaceholder')"
      :readonly="loading"
      :error="socialName.error"
      class="link-name"
      @update:model-value="handleOnUpdateTitle"
    />

    <NovaInputSimple
      :model-value="socialUrl.value"
      :placeholder="$t('creatorMyPage.home.linkUrlPlaceholder')"
      :full-width="true"
      :readonly="loading"
      :error="socialUrl.error"
      class="link-url"
      @update:model-value="handleOnUpdateUrl"
    />

    <NovaButtonIcon
      :icon="{ type: 'outline', name: 'remove' }"
      :theme="'square-bg'"
      :size="32"
      :loading="loading"
      :bg-bright="'light'"
      class="btn-remove"
      @click="handleOnRemove"
    />
  </div>
</template>

<style lang="scss" scoped>
.social-link-item {
  display: flex;
  align-items: center;
  gap: 6px;
  overflow: hidden;

  .link-name {
    flex-shrink: 0;
    width: 80px;
    overflow: hidden;
  }

  .link-url {
    flex-grow: 1;
  }

  .btn-remove {
    flex-shrink: 0;
  }
}
</style>
