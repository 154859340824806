<script setup lang="ts">
import { Tippy } from 'vue-tippy'

const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const userStore = useUserStore()
const creatorStore = useCreatorStore()
const editMode = ref(false)
const introduce = ref(userStore.user?.introduce || '')
const loading = ref(false)

watch(
  () => editMode.value,
  () => {
    initFormUnit()
  }
)

const handleOnToggleEditMode = () => {
  editMode.value = !editMode.value
  gtEvent('userAction', {
    eventCategory: '사용자',
    eventAction: `크리에이터 소개 수정 ${editMode.value ? '열기' : '닫기'}`,
    eventLabel: t('accountPanel.settings.creatorIntro.title'),
    eventSlot: '프로필 수정 > 크리에이터 소개',
    eventI18nAddr: useKoreanTranslation(
      'accountPanel.settings.creatorIntro.title'
    ),
    eventComponent: 'Button',
  })
}

const initFormUnit = () => {
  introduce.value = userStore.user?.introduce || ''
}

const handleOnCancel = () => {
  editMode.value = false
  gtEvent('userAction', {
    eventCategory: '클릭',
    eventAction: '크리에이터 소개 수정 취소 버튼 클릭',
    eventLabel: t('cancel'),
    eventSlot: '프로필 수정 > 크리에이터 소개 변경 > 취소',
    eventI18nAddr: useKoreanTranslation('cancel'),
    eventComponent: 'Button',
  })
}

const handleOnSave = () => {
  try {
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '저장 버튼 클릭',
      eventLabel: t('save'),
      eventSlot: '프로필 수정 > 크리에이터 소개 변경 > 저장',
      eventI18nAddr: useKoreanTranslation('save'),
      eventComponent: 'Button',
    })

    loading.value = true
    creatorStore.regCreatorIntroduction({
      optionCharstVal: introduce.value,
    })
    editMode.value = false
    useToast(t('accountPanel.settings.creatorIntro.messages.success'))
  } catch (err) {
    useToast(t('commonError.network.message'))
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <NovaBoxAccountSection :title="t('accountPanel.settings.creatorIntro.title')">
    <template #contents>
      <div class="account-section-wrap">
        <div v-if="!editMode" class="inner">
          <p class="text">{{ userStore.user?.introduce || '-' }}</p>
        </div>

        <div v-else class="inner">
          <NovaTextarea
            v-model="introduce"
            :placeholder="t('creatorMyPage.home.introductionPlaceholder')"
            :rows="6"
            :max-length="1000"
            :disabled="loading"
          />

          <div class="actions">
            <NovaButtonText
              :size="32"
              :label="t('cancel')"
              :theme="'secondary-gray'"
              :loading="loading"
              @click="handleOnCancel"
            />

            <NovaButtonText
              :size="32"
              :label="t('save')"
              :theme="'primary-blue'"
              :loading="loading"
              @click="handleOnSave"
            />
          </div>
        </div>
      </div>
    </template>

    <template #tools>
      <Tippy
        :append-to="'parent'"
        :content="t('modify')"
        :interactive="true"
        :theme="'simple'"
        :placement="'bottom'"
      >
        <NovaButtonIcon
          :icon="{ type: 'outline', name: 'write' }"
          :size="16"
          :loading="loading"
          class="btn-clipboard"
          @click="handleOnToggleEditMode"
        />
      </Tippy>
    </template>
  </NovaBoxAccountSection>
</template>

<style lang="scss" scoped>
.account-section-wrap {
  > .inner {
    display: flex;
    flex-direction: column;
    gap: 12px;

    > .actions {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      letter-spacing: -0.025em;
      text-align: left;
      color: $color-text-12;
    }
  }
}
</style>
