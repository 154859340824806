<script setup lang="ts">
import type { NovaBoxCreatorProfileProps } from './NovaBoxCreatorProfile.types'

const { t } = useI18n()
const props = withDefaults(defineProps<NovaBoxCreatorProfileProps>(), {
  isCreatorProfile: false,
})

const creatorDescription = computed(() =>
  (props.profile.proflDc || '').replace(/(?:\r\n|\r|\n)/g, '<br />')
)

const creatorIntroduction = computed(() =>
  (props.profile.introduction || '').replace(/(?:\r\n|\r|\n)/g, '<br />')
)

const creatorIntroductionShowMore = ref(true)
const creatorIntroductionOverFlow = ref(true)

const onHandleCreatorIntroduction = () => {
  creatorIntroductionShowMore.value = !creatorIntroductionShowMore.value
}

onMounted(async () => {
  await nextTick()
  const elCreatorIntroduction = document.getElementById(
    'content-box-creator-intro'
  )
  if (
    elCreatorIntroduction &&
    elCreatorIntroduction.clientHeight >= elCreatorIntroduction.scrollHeight
  ) {
    creatorIntroductionOverFlow.value = false
  }
})
</script>

<template>
  <section
    :class="[
      'creator-profile-box',
      { 'not-used-background': isCreatorProfile },
    ]"
  >
    <div
      v-if="!isCreatorProfile"
      :style="{ backgroundImage: `url(${profile.backgroundImage})` }"
      class="creator-profile-bg"
    />

    <div class="creator-profile">
      <div class="creator-portrait">
        <NovaPortraitContainer
          :size="'xl'"
          :stroke="true"
          :image-url="profile.userProflUrl"
        />

        <NovaCreatorGradeBadge
          :creator-grade="profile.crtrSpclGradCode"
          :creator-organization="profile.crtrOrgnztTyCode"
          :size="'lg'"
          class="creator-badge"
        />
      </div>

      <div v-if="isCreatorProfile" class="creator-info">
        <h4 class="creator-nickname">{{ profile.userNcnm }}</h4>
        <div class="content-box">
          <div
            id="content-box-creator-intro"
            v-dompurify-html="creatorIntroduction"
            :class="['text', { show: !creatorIntroductionShowMore }]"
          />
          <div v-if="creatorIntroductionOverFlow" class="btn-more">
            <NovaButtonText
              :label="
                creatorIntroductionShowMore
                  ? t('mypage.myCollection.button.showMore')
                  : t('mypage.myCollection.button.showLess')
              "
              :size="32"
              class="btn-action"
              @click="onHandleCreatorIntroduction"
            />
          </div>
        </div>
      </div>

      <div v-else class="creator-info">
        <h4 class="creator-nickname">{{ profile.userNcnm }}</h4>
        <p v-dompurify-html="creatorDescription" class="creator-description" />
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.not-used-background {
}
.creator-profile-box {
  position: relative;
  background-color: $color-white;
  z-index: 1;
  .creator-profile-bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 245px;
    border-radius: 16px 16px 0 0;
    background: linear-gradient(
      97.77deg,
      $color-secondary-blue-light-80 -5.67%,
      $color-highlight 126.11%
    );
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 245px;
      border-radius: 16px 16px 0 0;
      background: hex-to-rgba($color-bg-custom-7, 0.3);
    }
  }

  .creator-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    color: $color-text-4;
    z-index: 2;
    padding: 32px 20px 12px;
    .creator-portrait {
      position: relative;
      padding: 0 12px;

      .creator-badge {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .creator-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      .creator-nickname {
        @include text-style($text-heading1-bold);
        text-align: center;
        color: $color-text-2;
        word-break: break-all;
      }

      .creator-description {
        @include text-style($text-body-14-regular);
        text-align: center;
        color: $color-text-2;
      }
    }
  }
  .content-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%;
    margin: 10px auto 0;
    @include mobile {
      width: 100%;
    }
    :deep(.content-box-creator-intro) {
      width: 100%;
    }
    .text {
      display: inline;
      color: $color-text-2;
      display: -webkit-box;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      word-break: break-all;
      &.show {
        display: block;
        height: auto;
        -webkit-line-clamp: none;
      }
    }

    .btn-more {
      width: 100%;
      text-align: right;
      .btn {
        padding: 8px 0;
        @include text-style($text-body-14-medium);
        color: $color-text-13;
        &:hover {
          color: $color-black;
        }
      }
    }
  }
}
</style>
