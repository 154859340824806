import * as BridgeTypes from './types'
import { handlerKey } from './key'
import {
  formatMessage,
  toApp,
  toWeb,
  BridgeException,
  bridgeExceptionStatus,
} from './utils'
import { fcmCallHandler, FcmPayloadMin } from '@firebaseModule'
import { ROUTES } from '@configs'
// import { NovaModalDialog } from '#components'

/**
 * 제공된 데이터로 페이로드 객체를 생성하고 그것을 JSON 문자열로 반환.
 * @param {BridgeKey} handlerKey - 핸들러의 키.
 * @param {'success' | 'fail'} status - 페이로드의 상태.
 * @param {R} data - 페이로드에 포함될 데이터.
 * @returns {string} - 페이로드 객체를 JSON 문자열로 변환.
 */
const buildPayload = <R>(
  handlerKey: BridgeTypes.BridgeKey,
  status: 'success' | 'fail',
  data: R
): string => {
  const payload: BridgeTypes.BridgeResponse<R> = {
    handlerKey,
    message: '',
    status,
    data,
  }
  return JSON.stringify(payload)
}

/**
 * 브릿지 메시지를 웹 페이로드로 변환.
 * @template R - 페이로드 데이터의 타입.
 * @param {BridgeKey} handlerKey - 브릿지 핸들러 키.
 * @param {string | undefined} message - 브릿지 메시지.
 * @returns {string} - 변환된 웹 페이로드를 문자열 또는 Payload 인스턴스로 반환.
 */
const convertBridgeToWebPayload = <R>(
  handlerKey: BridgeTypes.BridgeKey,
  message: string | undefined
): string => {
  if (typeof message === 'string' && message.trim() !== '') {
    const formattedMessage = formatMessage(message || '')
    const parsedMessage = formattedMessage
      ? JSON.parse(formattedMessage)
      : undefined

    if (parsedMessage?.handlerKey) {
      return formattedMessage
    } else {
      return buildPayload<R>(handlerKey, 'success', parsedMessage?.data || null)
    }
  }

  return buildPayload<null>(handlerKey, 'success', null)
}

/**
 * bridges to app
 * @direction WEB -> APP
 */
export const bridgeToApp = {
  /**
   * 브릿지 연결 준비 완료 알리기: 요청
   */
  [handlerKey.IS_READY_TO_BRIDGE]: () =>
    toApp<
      BridgeTypes.IsInitToBridgePayload,
      BridgeTypes.IsInitToBridgeResponse
    >({
      payload: {
        key: handlerKey.IS_READY_TO_BRIDGE,
        message: undefined,
      },
    }),

  /**
   * 새로운 브라우저 열기: 요청
   * @param payload
   */
  [handlerKey.OPEN_WEB]: (payload: BridgeTypes.OpenWebPayload) =>
    toApp<BridgeTypes.OpenWebPayload, BridgeTypes.OpenWebResponse>({
      payload: {
        key: handlerKey.OPEN_WEB,
        message: payload,
      },
    }),

  /**
   * 웹뷰 닫기
   * @param payload
   */
  [handlerKey.CLOSE_WEB]: () =>
    toApp<BridgeTypes.CloseWebPayload, BridgeTypes.CloseWebResponse>({
      payload: {
        key: handlerKey.CLOSE_WEB,
        message: undefined,
      },
    }),

  /**
   * 웹뷰 닫고 이전 웹뷰 화면 이동
   * @param payload
   */
  [handlerKey.CLOSE_WEB_AND_REDIRECTION]: () =>
    toApp<
      BridgeTypes.CloseWebAndRedirectionPayload,
      BridgeTypes.CloseWebAndRedirectionResponse
    >({
      payload: {
        key: handlerKey.CLOSE_WEB_AND_REDIRECTION,
        message: undefined,
      },
    }),

  /**
   * 홈으로 이동
   * @param payload
   */
  [handlerKey.MOVE_HOME]: () =>
    toApp<BridgeTypes.MoveHomePayload, BridgeTypes.MoveHomeResponse>({
      payload: {
        key: handlerKey.MOVE_HOME,
        message: undefined,
      },
    }),

  /**
   * 앱 저장소에 데이터 저장
   * @param payload
   */
  [handlerKey.SAVE_STORAGE]: (payload: BridgeTypes.SaveStoragePayload) =>
    toApp<BridgeTypes.SaveStoragePayload, BridgeTypes.SaveStorageResponse>({
      payload: {
        key: handlerKey.SAVE_STORAGE,
        message: payload,
      },
    }),

  /**
   * 앱 저장소 데이터 조회
   * @param payload
   */
  [handlerKey.GET_STORAGE]: () =>
    toApp<BridgeTypes.GetStoragePayload, BridgeTypes.GetStorageResponse>({
      payload: {
        key: handlerKey.GET_STORAGE,
        message: undefined,
      },
    }),

  /**
   * 앱 저장소 데이터 삭제
   * @param payload
   */
  [handlerKey.CLEAR_STORAGE]: () =>
    toApp<BridgeTypes.ClearStoragePayload, BridgeTypes.ClearStorageResponse>({
      payload: {
        key: handlerKey.CLEAR_STORAGE,
        message: undefined,
      },
    }),

  /**
   * 앱 에서 웹 로컬 스토리지 삭제
   * @param payload
   */
  [handlerKey.CLEAR_WEB_LOCAL_STORAGE]: () =>
    toApp<
      BridgeTypes.ClearWebLocalStoragePayload,
      BridgeTypes.ClearWebLocalStorageResponse
    >({
      payload: {
        key: handlerKey.CLEAR_WEB_LOCAL_STORAGE,
        message: undefined,
      },
    }),

  /**
   * 앱 에서 웹 쿠키 삭제
   * @param payload
   */
  [handlerKey.CLEAR_WEB_COOKIE]: () =>
    toApp<
      BridgeTypes.ClearWebCookiePayload,
      BridgeTypes.ClearWebCookieResponse
    >({
      payload: {
        key: handlerKey.CLEAR_WEB_COOKIE,
        message: undefined,
      },
    }),

  /**
   * 앱 종료
   * @param payload
   */
  [handlerKey.TERMINATE_APP]: () =>
    toApp<BridgeTypes.TerminateAppPayload, BridgeTypes.TerminateAppResponse>({
      payload: {
        key: handlerKey.TERMINATE_APP,
        message: undefined,
      },
    }),

  /**
   * 권한 체크
   * @param payload
   */
  [handlerKey.CHECK_PERMISSION]: (
    payload: BridgeTypes.CheckPermissionPayload
  ) =>
    toApp<
      BridgeTypes.CheckPermissionPayload,
      BridgeTypes.CheckPermissionResponse
    >({
      payload: {
        key: handlerKey.CHECK_PERMISSION,
        message: payload,
      },
    }),

  /**
   * 권한 요청
   * @param payload
   */
  [handlerKey.REQUEST_PERMISSION]: (
    payload: BridgeTypes.RequestPermissionPayload
  ) =>
    toApp<
      BridgeTypes.RequestPermissionPayload,
      BridgeTypes.RequestPermissionResponse
    >({
      payload: {
        key: handlerKey.REQUEST_PERMISSION,
        message: payload,
      },
    }),

  /**
   * 앱 업데이트
   * @param payload
   */
  [handlerKey.FORCE_UPDATE]: (payload: BridgeTypes.ForceUpdatePayload) =>
    toApp<BridgeTypes.ForceUpdatePayload, BridgeTypes.ForceUpdateResponse>({
      payload: {
        key: handlerKey.FORCE_UPDATE,
        message: payload,
      },
    }),

  /**
   * 사용자 위치 정보 조회
   * @param payload
   */
  [handlerKey.GEO_LOCATION]: (payload: BridgeTypes.GeoLocationPayload) =>
    toApp<BridgeTypes.GeoLocationPayload, BridgeTypes.GeoLocationResponse>({
      payload: {
        key: handlerKey.GEO_LOCATION,
        message: payload,
      },
    }),

  /**
   * 풀 투 리프레쉬 on
   * @param payload
   */
  [handlerKey.SET_PULL_TO_REFRESH]: (
    payload: BridgeTypes.PullToRefreshPayload
  ) =>
    toApp<BridgeTypes.PullToRefreshPayload, BridgeTypes.PullToRefreshResponse>({
      payload: {
        key: handlerKey.SET_PULL_TO_REFRESH,
        message: payload,
      },
    }),

  /**
   * 풀 스크린 on/off 상태 업데이트
   */
  [handlerKey.FULL_SCREEN_STATUS]: (
    payload: BridgeTypes.FullscreenStatusPayload
  ) =>
    toApp<
      BridgeTypes.FullscreenStatusPayload,
      BridgeTypes.FullscreenStatusResponse
    >({
      payload: {
        key: handlerKey.FULL_SCREEN_STATUS,
        message: payload,
      },
    }),

  /**
   * 언어 변경
   */
  [handlerKey.CHANGE_LOCALE]: (payload: BridgeTypes.ChangeLocalePayload) =>
    toApp<BridgeTypes.ChangeLocalePayload, BridgeTypes.ChangeLocaleResponse>({
      payload: {
        key: handlerKey.CHANGE_LOCALE,
        message: payload,
      },
    }),
}

/**
 * bridge to web
 * @direction APP -> WEB
 */
export const bridgeToWeb: {
  [Key in BridgeTypes.BridgeKey]: (message: string) => void
} = {
  /**
   * 브릿지 연결 준비 완료 알리기: 응답
   * @param message
   */
  [handlerKey.IS_READY_TO_BRIDGE]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.IsInitToBridgeResponse>>(
      message
    ),

  /**
   * 새로운 브라우저 열기: 응답
   * @param message
   */
  [handlerKey.OPEN_WEB]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.OpenWebResponse>>(message),

  /**
   * 브라우저 닫기: 응답
   * @param message
   */
  [handlerKey.CLOSE_WEB]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.CloseWebResponse>>(message),

  /**
   * 웹 닫고 리다이렉트: 응답
   * @param message
   */
  [handlerKey.CLOSE_WEB_AND_REDIRECTION]: (message: string) =>
    toWeb<
      BridgeTypes.BridgeResponse<BridgeTypes.CloseWebAndRedirectionResponse>
    >(message),

  /**
   * 홈으로 이동: 응답
   * @param message
   */
  [handlerKey.MOVE_HOME]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.MoveHomeResponse>>(message),

  /**
   * 앱 저장소에 데이터 저장: 응답
   * @param message
   */
  [handlerKey.SAVE_STORAGE]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.SaveStorageResponse>>(message),

  /**
   * 앱 저장소 데이터 조회: 응답
   * @param message
   */
  [handlerKey.GET_STORAGE]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.GetStorageResponse>>(message),

  /**
   * 앱 저장소 데이터 삭제: 응답
   * @param message
   */
  [handlerKey.CLEAR_STORAGE]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.ClearStorageResponse>>(
      message
    ),

  /**
   * 앱에서 웹 로컬 스토리지 삭제: 응답
   * @param message
   */
  [handlerKey.CLEAR_WEB_LOCAL_STORAGE]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.ClearWebLocalStorageResponse>>(
      message
    ),

  /**
   * 앱에서 웹 쿠키 삭제: 응답
   * @param message
   */
  [handlerKey.CLEAR_WEB_COOKIE]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.ClearWebCookieResponse>>(
      message
    ),

  /**
   * 앱 종료: 응답
   * @param message
   */
  [handlerKey.TERMINATE_APP]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.TerminateAppResponse>>(
      message
    ),

  /**
   * 앱 권한 조회: 응답
   * @param message
   */
  [handlerKey.CHECK_PERMISSION]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.CheckPermissionResponse>>(
      message
    ),

  /**
   * 앱 권한 요청: 응답
   * @param message
   */
  [handlerKey.REQUEST_PERMISSION]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.RequestPermissionResponse>>(
      message
    ),

  /**
   * 앱 강제 버전 업데이트 요청: 응답
   * @param message
   */
  [handlerKey.FORCE_UPDATE]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.ForceUpdateResponse>>(message),

  /**
   * 사용자 위치 정보 조회: 응답
   * @param message
   */
  [handlerKey.GEO_LOCATION]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.GeoLocationResponse>>(message),

  /**
   * PullToRefresh on, off 상태 호출: 응답
   * @param message
   */
  [handlerKey.SET_PULL_TO_REFRESH]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.PullToRefreshResponse>>(
      message
    ),

  /**
   * 풀 스크린 상태 호출: 응답
   * @param message
   */
  [handlerKey.FULL_SCREEN_STATUS]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.FullscreenStatusResponse>>(
      message
    ),

  /**
   * 언어 변경(응답)
   * @param message
   */
  [handlerKey.CHANGE_LOCALE]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.ChangeLocaleResponse>>(
      message
    ),

  /**
   *
   *
   * 아래 부터는 APP -> WEB 요청
   *
   *
   */

  /**
   * 뒤로 가기: 요청
   * @param message
   */
  [handlerKey.GO_BACK]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.GoBackResponse>>(
      convertBridgeToWebPayload<BridgeTypes.GoBackResponse>(
        handlerKey.GO_BACK,
        message
      )
    ),

  /**
   * 화면 갱신: 요청
   * @param message
   */
  [handlerKey.REDRAW]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.RedrawResponse>>(
      convertBridgeToWebPayload<BridgeTypes.RedrawResponse>(
        handlerKey.REDRAW,
        message
      )
    ),

  /**
   * 앱 상태 변경: 요청
   * @param message
   */
  [handlerKey.ON_APP_STATUS_CHANGE]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.OnAppStatusChangeResponse>>(
      convertBridgeToWebPayload<BridgeTypes.OnAppStatusChangeResponse>(
        handlerKey.ON_APP_STATUS_CHANGE,
        message
      )
    ),

  /**
   * 딥 링크: 요청
   * @param message
   */
  [handlerKey.DEEP_LINK]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.DeepLinkResponse>>(
      convertBridgeToWebPayload<BridgeTypes.DeepLinkResponse>(
        handlerKey.DEEP_LINK,
        message
      )
    ),

  /**
   * 인앱 브라우저 닫았을때: 요청
   * @param message
   */
  [handlerKey.ON_CLOSE_DIALOG]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.DeepLinkResponse>>(
      convertBridgeToWebPayload<BridgeTypes.DeepLinkResponse>(
        handlerKey.ON_CLOSE_DIALOG,
        message
      )
    ),

  /**
   * 특정 data로 전달된 push Message 정보 전체를 Web으로 전달
   * @param message
   */
  [handlerKey.PUSH_MESSAGE_TO_WEB]: (message: string) =>
    toWeb<BridgeTypes.BridgeResponse<BridgeTypes.PushMessagePayload>>(
      convertBridgeToWebPayload<BridgeTypes.PushMessagePayload>(
        handlerKey.PUSH_MESSAGE_TO_WEB,
        message
      )
    ),
}

/**
 * bridge 초기화
 * @description 마운트 후 초기화
 */
export const initBridgeToWeb = async () => {
  const appStore = useAppStore()
  const layoutStore = useLayoutStore()

  // 1. check use only client side & mounted after
  if (!process.client || !window) {
    throw new BridgeException({
      message: 'bridge use only client side',
      cause: {
        status: bridgeExceptionStatus.BRIDGE_ONLY_USE_CSR,
      },
    })
  }

  // 2. init app by store
  await appStore.initApp()

  // 4. init bridge handler
  window.handlerToWeb = bridgeToWeb

  // 5. always listening bridge
  if (appStore.isApp) {
    // goBack
    toApp<BridgeTypes.GoBackPayload, BridgeTypes.GoBackResponse>({
      payload: {
        key: handlerKey.GO_BACK,
        message: undefined,
      },
      options: {
        callHandler: false,
        limit: 'infinity',
      },
      doDone: () => {
        const routeName = useGetRouteName()

        if (layoutStore.layers.length) {
          layoutStore['layer:close'](layoutStore.layers[0])
          return
        }

        if (routeName === ROUTES.HOME.name) {
          bridgeToApp.terminateApp()
          return
        }

        if (window.history.state.back) {
          useNavigations({ type: 'back' })
          return
        }

        useNavigations({ url: ROUTES.HOME.path })
      },
    })

    // redraw
    toApp<BridgeTypes.RedrawPayload, BridgeTypes.RedrawResponse>({
      payload: {
        key: handlerKey.REDRAW,
        message: undefined,
      },
      options: {
        callHandler: false,
        limit: 'infinity',
      },
      doDone: () => {
        console.log('redraw')
        location.reload()
      },
    })

    // onAppStatusChangePayload
    toApp<undefined, BridgeTypes.OnAppStatusChangePayload>({
      payload: {
        key: handlerKey.ON_APP_STATUS_CHANGE,
        message: undefined,
      },
      options: {
        callHandler: false,
        limit: 'infinity',
      },
      doDone: ({ data }) => {
        appStore.updateAppStatus(data)
      },
    })

    // deepLink
    toApp<undefined, BridgeTypes.DeepLinkPayload>({
      payload: {
        key: handlerKey.DEEP_LINK,
        message: undefined,
      },
      options: {
        callHandler: false,
        limit: 'infinity',
      },
      doDone: ({ data }) => {
        useNavigations({ external: false, url: data.url, type: 'push' })
      },
    })

    // onCloseDialog
    toApp<BridgeTypes.OnCloseDialogPayload, BridgeTypes.OnCloseDialogResponse>({
      payload: {
        key: handlerKey.ON_CLOSE_DIALOG,
        message: undefined,
      },
      options: {
        callHandler: false,
        limit: 'infinity',
      },
      doDone: () => {
        layoutStore.updateLoadingIndicatorGlobal({ show: false })
        delete window.popup
      },
    })

    // push message
    toApp<undefined, BridgeTypes.PushMessagePayload>({
      payload: {
        key: handlerKey.PUSH_MESSAGE_TO_WEB,
        message: undefined,
      },
      options: {
        callHandler: false,
        limit: 'infinity',
      },
      doDone: (res) => {
        fcmCallHandler(new FcmPayloadMin(res.data))
      },
    })
  }
}
