<script setup lang="ts">
import type { NovaBoxSocialLinksItemProps } from './NovaBoxSocialLinksItem.types'
import type { IconType } from '@components/NovaIcon/NovaIcon.types'

const props = withDefaults(defineProps<NovaBoxSocialLinksItemProps>(), {
  theme: 'default',
})
const { gtEvent } = useGoogleTag()
const icon = computed<IconType>(() => {
  switch (props.source.optionTyCode) {
    case 'FCBK':
      return { type: 'outline', name: 'social-facebook-circle' }
    case 'TWIR':
      return { type: 'outline', name: 'social-x' }
    case 'YUTB':
      return { type: 'outline', name: 'social-youtube' }
    case 'INTA':
      return { type: 'outline', name: 'social-instagram' }
    case 'HMPG':
    default:
      return { type: 'outline', name: 'social-web' }
  }
})
const sendGaEvent = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '크리에이터 홈 > 링크',
    eventLabel: props.source.optionNcm,
    eventSlot: '',
    eventI18nAddr: '',
    eventComponent: 'Button',
    optionCharstVal: props.source.optionCharstVal,
  })
}
</script>

<template>
  <a
    :href="source.optionCharstVal"
    :class="['social-link-item', `theme-${theme}`]"
    target="_blank"
    @click="sendGaEvent"
  >
    <div class="icon-wrap">
      <NovaIcon
        :icon="icon"
        :class="{ unknown: icon.name === 'web' }"
        :size="24"
      />
    </div>

    <span class="social-name">{{ source.optionNcm || '-' }}</span>
  </a>
</template>

<style lang="scss" scoped>
.social-link-item {
  display: flex;
  align-items: center;
  gap: 16px;
  overflow: hidden;
  word-break: break-all;
  overflow: hidden;

  .icon-wrap {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $color-black;

    i:before {
      color: $color-white;
    }
  }

  .social-name {
    flex-grow: 1;
    font-size: 15px;
    font-weight: 500;
    line-height: 21.72px;
    text-align: left;
    color: $color-black;

    @include ellipsis(1);
  }

  .social-link {
    @include text-style($text-body-14-regular);
    color: $color-text-2;
  }

  &.theme-bar {
    position: relative;
    background-color: $color-white;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 #00000014;
    padding: 9px 6px 9px 6px;
    height: 50px;
    .icon-wrap {
      position: absolute;
      left: 6px;
    }
    .social-name {
      padding: 0 40px;
      text-align: center;
    }
  }
}
</style>
