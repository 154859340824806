import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src1716140950/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src1716140950/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src1716140950/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src1716140950/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/codebuild/output/src1716140950/src/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src1716140950/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src1716140950/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/codebuild/output/src1716140950/src/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src1716140950/src/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src1716140950/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/codebuild/output/src1716140950/src/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/codebuild/output/src1716140950/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/codebuild/output/src1716140950/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import vueChart_vvfvWlffra from "/codebuild/output/src1716140950/src/plugins/vueChart.ts";
import vueDomPurifyHtml_AkelajEzbZ from "/codebuild/output/src1716140950/src/plugins/vueDomPurifyHtml.ts";
import vueFinalModal_D975ZryQAR from "/codebuild/output/src1716140950/src/plugins/vueFinalModal.ts";
import vueTippy_vbilL1c1UW from "/codebuild/output/src1716140950/src/plugins/vueTippy.ts";
import vueVirtualScroller_wJE4gJWrIh from "/codebuild/output/src1716140950/src/plugins/vueVirtualScroller.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  vueChart_vvfvWlffra,
  vueDomPurifyHtml_AkelajEzbZ,
  vueFinalModal_D975ZryQAR,
  vueTippy_vbilL1c1UW,
  vueVirtualScroller_wJE4gJWrIh
]