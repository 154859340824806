import type { RouterConfig } from '@nuxt/schema'
import { ROUTES } from '@configs'
export default <RouterConfig>{
  scrollBehavior(to, from) {
    const layoutStore = useLayoutStore()
    const { routeScrollPositions } = storeToRefs(layoutStore)
    const toRouteName = useGetRouteName(String(to.name))
    const toParentRouteNameByConfig = useGetRouteConst(toRouteName)?.parentName
    const toRestoreScrollPosition =
      routeScrollPositions.value[toRouteName]?.scrollPosition || 0

    // console.error('to', to)
    // console.error('from', from)
    // console.error(toRestoreScrollPosition)
    // console.error('toParentRouteNameByConfig', toParentRouteNameByConfig)
    // console.error(to.query.modals)

    if (
      typeof to.query.modals !== 'undefined' ||
      typeof from.query.modals !== 'undefined'
    ) {
      return {
        top: window?.scrollY || 0,
      }
    }
    /**
     * 추가 한 이유: creator-id 를 이용에서 스크롤 포지션이 저장 되는데,
     *    A 크리에이터 홈 -> B 크리에이터 홈 으로 이동했을 때
     *    저장 된 스크롤 포지션으로 이동 하지 않게 하려
     * 조건: 1. 크리에이터 홈, 2. 스토어에 저장된 User 정보와 진입한 크리에이터 홈 유저 정보가 다를떄
     * 결과: 저장 된 스크롤 포지션으로 이동하지 않음
     */
    if (
      [ROUTES.CREATOR.name, ROUTES.USER.name].includes(toRouteName) &&
      useGetRouteName(String(to.params.id)) !==
        routeScrollPositions.value[toRouteName]?.userId
    ) {
      return
    }

    // 1. 리스트 페이지 > 상세페이지
    if (
      toParentRouteNameByConfig &&
      routeScrollPositions.value[toParentRouteNameByConfig]
    ) {
      return new Promise((resolve) => {
        const top =
          routeScrollPositions.value[toParentRouteNameByConfig].scrollPosition

        // 리스트 페이지가 상세페이지 넘어가기 전 스크롤 포지션이 0이 되는것을 막음
        setTimeout(() => {
          return resolve({ top })
        })

        // 상세 페이지 진입 후 다시 스크롤 포지션을 0으로 변경
        setTimeout(() => {
          window.scrollTo({ top: 0 })
        }, 100)
      })
    }

    // 2. 리스트 페이지 진입시 스크롤 포지션 복원
    return new Promise((resolve) => {
      setTimeout(() => resolve({ top: toRestoreScrollPosition }), 300)
    })
  },
}
