<script setup lang="ts">
import type { RouteParams } from 'vue-router'
import type { ModalCreatorRewardParams } from './NovaModalCreatorReward.types'
import { NovaLayoutScrollContainer } from '#components'

defineOptions({
  inheritAttrs: false,
})

const myPageStore = useMyPageStore()
const creatorStore = useCreatorStore()
const userStore = useUserStore()

const { hide: modalHide } = useModal()
const { gtEvent } = useGoogleTag()
const show = ref(false)
const creatorRewardTotalRef = ref<InstanceType<
  typeof NovaLayoutScrollContainer
> | null>(null)

const creatorRewardWeekRef = ref<InstanceType<
  typeof NovaLayoutScrollContainer
> | null>(null)
const handleOnGetParams = (evt: RouteParams) => {
  const payload = evt.value as unknown as ModalCreatorRewardParams
  modalType.value = payload.creatorRewardData.type
  headerTitle.value =
    payload.creatorRewardData.type === 'week'
      ? t('mypage.reward.title.weekly')
      : t('mypage.reward.title.total')
  totalReward.value = payload.creatorRewardData.totalReward
  sortItems.value = payload.creatorRewardData.sortItems
  activeSortItem.value = sortItems.value[0]
}

const totalReward = ref()
const headerTitle = ref('')
const modalType = ref('')
const sortItems = ref()
const activeSortItem = ref()
const isError = ref(false)
const isLoading = ref(false)
const { t } = useI18n()
const handleOnChangeActiveSortItem = (sortItem: any) => {
  activeSortItem.value = sortItem
}

const fetchRewards = async (refresh: boolean, isRecycle?: boolean) => {
  try {
    isLoading.value = true
    isError.value = false
    await myPageStore.fetchRewards(
      {
        ...myPageStore.rewards.payload,
        orderBy: activeSortItem.value.id,
        userCmmnSn: userStore.user!.userCmmnSn,
      },
      refresh
    )
    if (isRecycle) {
      gtEvent('scrollEvent', {
        eventCategory: '더보기',
        eventAction: '총 리워드 더보기',
        eventLabel: 'loadMore',
        eventSlot: '',
        eventI18nAddr: '',
        eventComponent: 'RecyclerView',
        pageNum: myPageStore.rewards.payload.pageNum,
        pageSize: myPageStore.rewards.payload.pageSize,
      })
    }
  } catch {
    isError.value = true
  } finally {
    isLoading.value = false
  }
}

const fetchWeeklyRewards = async (refresh: boolean, isRecycle?: boolean) => {
  try {
    isLoading.value = true
    isError.value = false
    await creatorStore.fetchWeeklyRewardContents(
      {
        ...creatorStore.weeklyRewardContents.payload,
        svcActGroupClCode: activeSortItem.value.id,
      },
      refresh
    )
    if (isRecycle) {
      gtEvent('scrollEvent', {
        eventCategory: '더보기',
        eventAction: '주간 보상 예상 리워드 더보기',
        eventLabel: 'loadMore',
        eventSlot: '',
        eventI18nAddr: '',
        eventComponent: 'RecyclerView',
        pageNum: creatorStore.weeklyRewardContents.payload.pageNum,
        pageSize: creatorStore.weeklyRewardContents.payload.pageSize,
      })
    }
  } catch {
    isError.value = true
  } finally {
    isLoading.value = false
  }
}

const fetchWeeklyRewardCount = () => {
  creatorStore.fetchWeeklyRewardCount()
}

/**
 * 팝업 닫기: 버튼 클릭
 */
const handleOnClose = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction:
      modalType.value === 'week'
        ? '주간 예상 리워드 모달 닫기'
        : '총 리워드 모달 닫기',
    eventLabel:
      modalType.value === 'week'
        ? t('mypage.reward.title.weekly')
        : t('mypage.reward.title.total'),
    eventSlot: '크리에이터 홈 > 리워드 > 리워드 상세 모달',
    eventI18nAddr:
      modalType.value === 'week'
        ? useKoreanTranslation('mypage.reward.title.weekly')
        : useKoreanTranslation('mypage.reward.title.total'),
    eventComponent: 'Button',
  })
  await fetchWeeklyRewardCount()
  await modalHide(modalsName.MODAL_CREATOR_REWARD_LIST, false)
}

/**
 * 팝업 닫은 후 스토어에서 데이터 초기화
 */
const handleOnClosed = () => {
  creatorStore.deleteWeeklyRewardContents()
}

watch(
  () => activeSortItem.value,
  async () => {
    if (modalType.value === 'week') {
      gtEvent('clickEvent', {
        eventCategory: '클릭',
        eventAction: '주간 리워드 모달 > 목록 변경(좋아요, 팔로우)',
        eventLabel: activeSortItem.value.name,
        eventSlot: '크리에이터 홈 > 리워드 > 리워드 상세 모달',
        eventI18nAddr: activeSortItem.value.name,
        eventComponent: 'Button',
      })
      await fetchWeeklyRewards(true)
    }
    if (modalType.value === 'total') {
      console.log(activeSortItem.value)
      gtEvent('clickEvent', {
        eventCategory: '클릭',
        eventAction: '총 리워드 모달 > 정렬 순서 변경',
        eventLabel: activeSortItem.value.name,
        eventSlot: '크리에이터 홈 > 리워드 > 리워드 상세 모달',
        eventI18nAddr: useKoreanTranslation(
          `mypage.sort.reward.${activeSortItem.value.id}`
        ),
        eventComponent: 'Button',
      })
      await fetchRewards(true)
    }
  }
)
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_CREATOR_REWARD_LIST"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @closed="handleOnClosed"
    @click-outside="handleOnClose"
  >
    <ClientOnly>
      <NovaBoxBase v-if="show" class="modal-box">
        <div class="modal-header">
          <div class="on-desktop">
            <h4 class="title">{{ headerTitle }}</h4>
            <div class="close-wrap" @click="handleOnClose">
              <NovaButtonIcon
                :icon="{ type: 'outline', name: 'close-extend' }"
                class="btn-close"
              />
            </div>
          </div>
          <div :class="[`total-reward-${modalType}`]">{{ totalReward }} LM</div>
        </div>

        <NovaLayoutScrollContainer
          v-if="modalType === 'total'"
          ref="creatorRewardTotalRef"
          class="modal-body"
        >
          <NovaBoxSorter
            :sort-items="sortItems"
            :active-sort-item="activeSortItem"
            @on-change-active-sort-item="handleOnChangeActiveSortItem"
          />

          <NovaBoxEmptyMessage
            v-if="
              myPageStore.rewards.refresh &&
              !myPageStore.rewards.items.length &&
              !isError
            "
            :message="$t('statusMessages.mypageList.reward.empty')"
            :fill="true"
          />

          <NovaBoxEmptyMessage
            v-if="!myPageStore.rewards.items.length && isError"
            :message="$t('statusMessages.mypageList.reward.error')"
            :fill="true"
          />

          <NovaRecyclerGrid
            v-if="myPageStore.rewards.items.length"
            :items="myPageStore.rewards.items"
            :is-last="myPageStore.rewards.last"
            :is-loading="isLoading"
            :is-error="isError"
            :is-refresh="myPageStore.rewards.refresh"
            :key-name="'id'"
            :page-mode="false"
            :error-message="$t('statusMessages.mypageList.reward.error')"
            :responsive="{
              mobile: { columns: 1, rowGap: 12, columnGap: 0 },
              tablet: { columns: 1, rowGap: 12, columnGap: 0 },
              desktop: { columns: 1, rowGap: 12, columnGap: 0 },
            }"
            :bottom-white-space="0"
            @load-more="() => fetchRewards(false, true)"
          >
            <template #item="{ item, index }">
              <NovaBoxCreatorRewardListItem :key="index" :source="item" />
            </template>
          </NovaRecyclerGrid>
        </NovaLayoutScrollContainer>
        <NovaLayoutScrollContainer
          v-else
          ref="creatorRewardWeekRef"
          class="modal-body"
        >
          <NovaBoxSorter
            :sort-items="sortItems"
            :active-sort-item="activeSortItem"
            @on-change-active-sort-item="handleOnChangeActiveSortItem"
          />

          <NovaBoxEmptyMessage
            v-if="
              creatorStore.weeklyRewardContents.refresh &&
              !creatorStore.weeklyRewardContents.items.length &&
              !isError
            "
            :message="$t('statusMessages.mypageList.reward.empty')"
            :fill="true"
          />

          <NovaBoxEmptyMessage
            v-if="!creatorStore.weeklyRewardContents.items.length && isError"
            :message="$t('statusMessages.mypageList.reward.error')"
            :fill="true"
          />

          <NovaRecyclerGrid
            v-if="creatorStore.weeklyRewardContents.items.length"
            :items="creatorStore.weeklyRewardContents.items"
            :is-last="creatorStore.weeklyRewardContents.last"
            :is-loading="isLoading"
            :is-error="isError"
            :is-refresh="creatorStore.weeklyRewardContents.refresh"
            :key-name="'id'"
            :page-mode="false"
            :error-message="$t('statusMessages.mypageList.reward.error')"
            :responsive="{
              mobile: { columns: 1, rowGap: 12, columnGap: 0 },
              tablet: { columns: 1, rowGap: 12, columnGap: 0 },
              desktop: { columns: 1, rowGap: 12, columnGap: 0 },
            }"
            :bottom-white-space="0"
            @load-more="() => fetchWeeklyRewards(false, true)"
          >
            <template #item="{ item, index }">
              <NovaBoxCreatorRewardWeeklyListItem :key="index" :source="item" />
            </template>
          </NovaRecyclerGrid>
        </NovaLayoutScrollContainer>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(30px + 24px + 12px) 32px 32px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  .modal-box {
    width: 100%;
    padding: 0;
    max-width: 670px;
    max-height: 100%;
    border-radius: 20px !important;
    pointer-events: auto;
    background-color: $color-border-1;
    .modal-header {
      .on-desktop {
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
        justify-content: flex-end;
        padding: 20px;
        flex-grow: 1;
        background-color: $color-white;
        border-radius: 20px 20px 0 0;
        @include text-style($text-display-medium);
        > .title {
          flex-grow: 1;
          color: $color-black;
        }
        .close-wrap {
          .btn-close {
            color: $color-text-2;
          }
        }
      }
      .total-reward-week {
        display: flex;
        padding: 15px 20px;
        @include text-style($text-custom-30-bold);
        color: $color-white;
        border-radius: 0 0 20px 20px;
        background-color: $color-blue-700;
      }
      .total-reward-total {
        display: flex;
        padding: 15px 20px;
        @include text-style($text-custom-30-bold);
        border-radius: 0 0 20px 20px;
        background-color: $color-white;
        border-top: 1px solid $color-gray-1;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      }
    }

    .modal-body {
      display: flex;
      gap: 12px;
      padding: 20px 10px 32px 10px;
      border-radius: 20px;
      min-height: 396px;
      > .post {
        border-radius: 0;
        box-shadow: none;
      }

      .async-status-message {
        min-height: 200px;
      }
    }
  }
}

@include mobile {
  :deep(.modal-content) {
    width: 100%;
    height: 100%;
    padding: 0;

    .modal-box {
      width: 100%;
      max-width: 100%;
      height: 100%;
      border-radius: 0 !important;

      .modal-body {
        border-radius: 0;

        > .post {
          max-height: initial;
        }
      }
    }
  }
}
</style>
