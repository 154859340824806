import type { MyPageState } from './type'

export const initMyPageState: MyPageState = {
  profile: null,
  posts: {
    items: [],
    payload: {
      pageNum: 0,
      pageSize: 20,
      nextPageSize: 20,
      nttSndbrdLiveAt: 'N',
      orderBy: 'newest',
      userNcnm: '',
    },
    refresh: false,
    last: false,
  },
  postCount: {
    privatePostCount: 0,
    publicPostCount: 0,
  },
  recommendPosts: {
    get: {
      items: [],
      payload: {
        pageNum: 0,
        pageSize: 20,
        nextPageSize: 20,
      },
      refresh: false,
      last: false,
    },
    set: {
      items: [],
      payload: {
        pageNum: 0,
        pageSize: 20,
        nextPageSize: 20,
        orderBy: 'newest',
      },
      refresh: false,
      last: false,
    },
  },
  recommendCount: {
    receivedRecommendPostCount: 0,
    recommendPostCount: 0,
  },
  commentPosts: {
    items: [],
    payload: {
      pageNum: 0,
      pageSize: 20,
      nextPageSize: 20,
      orderBy: 'newest',
    },
    refresh: false,
    last: false,
  },
  commentCount: 0,
  scrapPosts: {
    items: [],
    payload: {
      pageNum: 0,
      pageSize: 20,
      nextPageSize: 20,
      orderBy: 'newest',
    },
    refresh: false,
    last: false,
  },
  scrapCount: 0,
  rewards: {
    items: [],
    payload: {
      pageNum: 0,
      pageSize: 20,
      nextPageSize: 20,
      orderBy: 'newest',
      userCmmnSn: -1,
    },
    refresh: false,
    last: false,
  },
  rewardCount: {
    rwardStdrBeginDtStr: '',
    rwardStdrEndDtStr: '',
    rwardSmLM: 0,
  },
  showRewards: true,
  donationQty: null,
  receivedDonations: {
    items: [],
    payload: {
      pageNum: 0,
      pageSize: 20,
      nextPageSize: 20,
      orderBy: 'newest',
    },
    refresh: false,
    last: false,
  },
  sentDonations: {
    items: [],
    payload: {
      pageNum: 0,
      pageSize: 20,
      nextPageSize: 20,
      orderBy: 'newest',
    },
    refresh: false,
    last: false,
  },
  homeProfile: null,
  premiumCreators: {
    data: [],
    loading: false,
    error: false,
  },
  premiumSponsors: {
    items: [],
    payload: {
      orderBy: 'newest',
      filterType: 'all',
      pageNum: 1,
      pageSize: 6,
      searchKeyword: '',
    },
    pagination: {
      currentPage: 1,
      itemPerPage: 1,
      pagePerScope: 5,
      totals: 0,
      pageStartIdx: 0,
    },
  },
  myFollowers: {
    items: [],
    payload: {
      lastUserCmtyFlwSn: -1,
      pageNum: 0,
      pageSize: 20,
      userSn: -1,
    },
    refresh: false,
    last: false,
  },
  myFollowings: {
    items: [],
    payload: {
      lastUserCmtyFlwSn: -1,
      pageNum: 0,
      pageSize: 20,
      userSn: -1,
    },
    refresh: false,
    last: false,
  },
  myBlocks: {
    items: [],
    payload: {
      lastUserCmtyFlwSn: -1,
      pageNum: 0,
      pageSize: 20,
    },
    refresh: false,
    last: false,
  },
  myCollections: {
    items: [],
    payload: {
      orderBy: 'rarityDesc',
      pageNum: 0,
      size: 20,
    },
    refresh: false,
    last: false,
  },
  myCollectionNft: null,
}
