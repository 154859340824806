<script setup lang="ts">
import { v4 } from 'uuid'
import type { NovaPortraitContainerProps } from './NovaPortraitContainer.types'

const props = withDefaults(defineProps<NovaPortraitContainerProps>(), {
  imageUrl: null,
  size: 'md',
  stroke: false,
  highlightStroke: false,
  hasAction: false,
  emptyType: 'portrait',
})

const sizeClass = computed(() => `size-${props.size}`)
const hasActionClass = computed(() => (props.hasAction ? 'has-action' : ''))
</script>

<template>
  <div
    :class="[
      'portrait-container',
      sizeClass,
      hasActionClass,
      { 'highlight-stroke': highlightStroke },
    ]"
  >
    <div
      :class="[
        'inner',
        { stroke },
        emptyType,
        { 'highlight-stroke': highlightStroke },
      ]"
    >
      <NovaImageContainer
        :key="imageUrl || v4()"
        :image-url="imageUrl"
        :empty-type="emptyType"
        :ratio="'1:1'"
        :stroke="stroke"
        :portrait-xs="size === 'xs'"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.portrait-container {
  position: relative;
  flex-shrink: 0;
  display: block;
  &.highlight-stroke {
    &:before {
      position: absolute;
      top: -12px;
      left: 50%;
      transform: translateX(-50%);
      display: inline-block;
      padding: 1px 5px;
      content: 'LIVE';
      @include text-style($text-body-14-bold);
      letter-spacing: 1px;
      background: $color-primary-green;
      color: $color-white;
      z-index: 1;
      border-radius: 4px;
    }
  }
  &.has-action {
    cursor: pointer;
  }

  @mixin size($size) {
    width: $size;
    height: $size;

    > .inner {
      border-radius: calc($size / 2);

      &.magnifier {
        border-radius: 0;
      }
    }
  }

  &.size-xs {
    @include size(28px);

    > .inner {
      &.stroke {
        border: 1px solid $color-bg-3;
      }

      &.highlight-stroke {
        border: 2px solid $color-primary-green;
      }

      :deep(.empty-image.portrait) {
        background-size: 14px auto;
      }
    }
  }

  &.size-sm {
    @include size(40px);

    > .inner {
      &.stroke {
        border: 2px solid $color-bg-3;
      }

      &.highlight-stroke {
        border: 2px solid $color-primary-green;
        box-shadow: 0 0 0 2px $color-primary-green;
      }

      :deep(.empty-image.portrait) {
        background-size: 20px auto;
      }
    }
  }

  &.size-md {
    @include size(50px);

    > .inner {
      &.stroke {
        border: 2px solid $color-bg-3;
      }

      &.highlight-stroke {
        box-shadow: 0 0 0 3px $color-primary-green;
      }
    }
  }

  &.size-lg {
    @include size(100px);

    > .inner {
      &.stroke {
        border: 4px solid $color-bg-3;
      }
    }
  }

  &.size-xl {
    @include size(110px);
    > .inner {
      background-color: transparent;
      &.stroke {
        border: 4px solid transparent;
      }
      &.highlight-stroke {
        border: 4px solid $color-primary-green;
      }
    }
  }

  &.size-60 {
    @include size(60px);

    > .inner {
      &.stroke {
        border: 4px solid transparent;
      }

      &.highlight-stroke {
        border: 4px solid $color-primary-green;
      }
    }
  }

  &.size-56 {
    @include size(56px);

    > .inner {
      &.stroke {
        border: 2px solid transparent;
      }

      &.highlight-stroke {
        border: 2px solid $color-primary-green;
      }
    }
  }

  &.size-80 {
    @include size(80px);
    > .inner {
      &.stroke {
        border: 2px solid $color-bg-3;
      }

      &.highlight-stroke {
        box-shadow: 0 0 0 4px $color-primary-green;
      }
    }
  }
  > .inner {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: $color-bg-1;
  }
}
</style>
