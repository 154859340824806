import type { IconType } from '@components/NovaIcon/NovaIcon.types'

interface Social {
  icon: IconType
  url: string
  size: number
}

/**
 * 메뉴 > 소셜 목록
 */
export const SOCIALS: Array<Social> = [
  {
    icon: { type: 'outline', name: 'social-instagram' },
    url: 'https://www.instagram.com/ilikelm.official',
    size: 24,
  },
  {
    icon: { type: 'outline', name: 'social-x' },
    url: 'https://x.com/ILIKELMofficial',
    size: 20,
  },
]
