<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import type { NovaBoxCreatorRewardListItemProps } from './NovaBoxCreatorRewardListItem.types'
import type { RewardDetailPayload } from '@store'

const props = defineProps<NovaBoxCreatorRewardListItemProps>()

const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const myPageStore = useMyPageStore()
const isExtend = ref(false)
const isLoading = ref(false)
const isError = ref(false)
const rewardLabel = computed(() => {
  switch (props.source.tradeTyDetailCode) {
    case 'RP05':
    case 'RP07':
      return t('mypage.reward.messages.activity')
    case 'RP06':
    case 'RP08':
      return t('mypage.reward.messages.post')
    case 'RP09':
      return t('mypage.reward.messages.nft')
    case 'RP11':
      return t('mypage.reward.messages.fixed')
    case 'RP10':
      return t('mypage.reward.messages.incentive')
    case 'RP12':
      return t('mypage.reward.messages.monthly')
    case 'RP13':
    case 'RP14':
    case 'RP15':
    case 'RP16':
    case 'RP52':
      return t('mypage.reward.messages.referral')
    case 'RP20':
      return t('mypage.reward.messages.superCreator')
    case 'RP21':
      return t('mypage.reward.messages.megaCreator')
    case 'RP25':
      return t('mypage.reward.messages.novaPlus')
    case 'RP51':
    case 'RP53':
    case 'RP54':
      return t('mypage.reward.messages.weeklyReward')
    case 'RP71':
    case 'RP72':
      return t('mypage.reward.messages.missionReward')
    default:
      throw new Error('reward type is not match')
  }
})
const lm = computed(() =>
  useFormatThousandsSeparators(props.source.cxRwardExpectSmQty, 2)
)

const handleOnToggleExtend = async () => {
  if (props.source.useDetailAt === 'N') {
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '리워드 리스트 아이템 클릭',
      eventLabel: '',
      eventSlot: '상세보기 없음',
      eventI18nAddr: '',
      eventComponent: 'Button',
      rwardOdrSn: props.source.rwardOdrSn,
      cxRwardExpectSmQty: props.source.cxRwardExpectSmQty,
    })
    return
  }
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '리워드 리스트 상세보기 클릭',
    eventLabel: '',
    eventSlot: isExtend.value ? '닫기' : '열기',
    eventI18nAddr: '',
    eventComponent: 'Button',
    rwardOdrSn: props.source.rwardOdrSn,
    cxRwardExpectSmQty: props.source.cxRwardExpectSmQty,
  })

  isExtend.value = !isExtend.value

  if (!props.source.detail && isExtend) {
    try {
      isLoading.value = true
      isError.value = false
      const payload: RewardDetailPayload = {
        rwardOdrSn: props.source.rwardOdrSn,
        tradeTyDetailCode: props.source.tradeTyDetailCode,
        id: props.source.id,
      }

      await myPageStore.fetchRewardDetail(payload)
    } catch {
      isError.value = true
    } finally {
      isLoading.value = false
    }
  }
}
</script>

<template>
  <div :class="['reword-item', { extend: isExtend }]">
    <div class="contents-box" @click="handleOnToggleExtend">
      <div class="contents">
        <div v-if="source.tradeTyDetailCode !== 'RP25'" class="icon">
          <NovaIcon :icon="{ type: 'outline', name: 'gift-box' }" :size="20" />
        </div>

        <div v-else class="icon icon-nova-plus">
          <img src="../../assets/images/empty-image-illustration.png" alt="" />
        </div>

        <div class="info-wrap">
          <p class="label">{{ rewardLabel }}</p>
          <p class="create-at">{{ source.batchExcDt }}</p>
        </div>
      </div>

      <div class="actions">
        <div class="lm-wrap">
          <Tippy
            v-if="!source.cxRwardExpectSmQty"
            :interactive="true"
            :theme="'gray'"
            :placement="'top'"
          >
            <span class="tooltip-label" />

            <template #content>
              <p v-dompurify-html="$t('mypage.reward.zeroGuide')" />
            </template>
          </Tippy>
          <span :class="['lm', { negative: !source.cxRwardExpectSmQty }]">
            {{ source.cxRwardExpectSmQty ? '+' : '' }}
            {{ lm }}<span class="unit">LM</span>
          </span>
        </div>
        <div v-if="source.useDetailAt === 'Y'" class="extend-wrap">
          <NovaButtonIcon
            :icon="{ type: 'outline', name: 'chev-compact-bottom' }"
            :size="16"
            class="chev-icon"
          />
        </div>
      </div>
    </div>
    <div v-if="isExtend" class="activity-box">
      <div v-if="!isLoading" class="inner">
        <span class="label">{{ $t('mypage.reward.active') }}</span>
        <div class="contents">
          <div
            v-for="(item, index) in source.detail"
            :key="index"
            class="action"
          >
            <span>
              {{ item.rwardStdCodeNm }}
              <em :class="['highlight', `color-${item.color.toLowerCase()}`]">
                {{ item.rwardActCnt }}
              </em>
            </span>
          </div>
        </div>
      </div>
      <NovaLoadingIndicator
        v-else
        :bg-bright="'light'"
        class="loading-indicator"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.reword-item {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: $color-bg-3;
  border-radius: 16px;

  &.extend {
    .contents-box > .actions .extend-wrap .chev-icon {
      transform: rotate(180deg);
    }
  }

  .contents-box {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    cursor: pointer;

    > .contents {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 10px;

      > .icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        font-size: 0;
        color: $color-blue-700;
        background-color: #dbeafe;
        border-radius: 6px;
        overflow: hidden;

        &.icon-nova-plus {
          background-color: $color-white;
          border: 2px solid $color-secondary-red-light-60;

          > img {
            width: 28px;
          }
        }
      }

      .info-wrap {
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding: 4px 0;

        > .label {
          @include text-style($text-body-14-medium);
          line-height: 1;
          letter-spacing: 0;
          color: $color-text-2;
        }

        .create-at {
          @include text-style($text-body-12-regular);
          line-height: 1;
          letter-spacing: 0;
          color: $color-text-3;
        }
      }
    }

    > .actions {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      gap: 10px;

      .lm-wrap {
        display: flex;
        align-items: center;
        gap: 4px;
      }

      .lm {
        @include text-style($text-display-bold);
        line-height: 1;
        letter-spacing: 0;
        color: $color-blue-700;

        &.negative {
          color: $color-text-1;
        }

        .unit {
          font-weight: 400;
        }
      }

      .extend-wrap {
        width: 16px;
        height: 16px;
        color: $color-text-3;

        .chev-icon {
          transition: transform 0.2s ease-in-out;
        }
      }
    }
  }

  .activity-box {
    border-top: 1px solid $color-bg-1;
    margin-top: 10px;
    padding-top: 10px;

    > .inner {
      display: flex;
      flex-direction: row;
      gap: 10px;

      > .label {
        flex-shrink: 0;
        display: inline;
        @include text-style($text-body-13-medium);
        line-height: 1;
        letter-spacing: 0;
        color: $color-text-2;
      }

      > .contents {
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        //gap: 6px;
        row-gap: 6px;

        > .action {
          display: inline-flex;
          @include text-style($text-body-13-regular);
          line-height: 13px;
          letter-spacing: 0;

          &:first-of-type:before {
            display: none;
          }

          &:before {
            display: inline-block;
            content: '•';
            color: $color-text-5;
            font-size: 20px;
            line-height: 13px;
          }

          em.highlight {
            font-weight: 700;

            &.color-blue {
              color: $color-primary-blue;
            }

            &.color-red {
              color: $color-primary-red;
            }
          }
        }
      }
    }

    > .loading-indicator {
      height: 13px;
      min-height: 13px;
    }
  }
}

.tooltip-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: $color-bg-custom-11;

  &:after {
    content: '?';
    color: $color-bg-3;
    font-size: 10px;
    line-height: 1;
  }
}
</style>
