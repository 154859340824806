import type { PremiumDonate } from '@store'
import { SECOND, MINUTE } from '@configs'
import { NovaModalDialog } from '#components'
import { useReCaptcha } from '@composables/useRechaptcha'

/**
 * 구독 결제 모듈 콜백
 */
export interface PayCallbackType {
  merchantOrderNo: string
  creatorUserSn: number // 후원(구독) 신청 완료 후 크리에이터 모델 업데이트 및 자동 팔로우를 위한 크리에이터 일련번호
  autoFollow: boolean // 후원(구독) 신청 완료 후 자동 팔로우 여부
}

/**
 * 구독 결제 메세지
 */
export type PayMessageType = {
  type: 'pay'
  res: PayCallbackType
}

export const usePay = () => {
  const nuxtApp = useNuxtApp()
  const creatorStore = useCreatorStore()
  const layoutStore = useLayoutStore()
  const commStore = useCommStore()
  const { show: modalShow, hide: modalHide } = useModal()
  const { t } = nuxtApp.$i18n
  const { public: config } = nuxtApp.$config

  // 결제 콜백 받을 URL
  const callbackUrlPay = `${config.APP_URL}/callback/pay`

  // 결제 윈도우 팝업 열기
  const openPay = (payload: PremiumDonate) => {
    useOpenPopupAtOnce(payload.url, 'pay')
  }

  // 결제 콜백 URL 을 통해 받은 정보로 결제 상태 확인 API를 폴링 방식으로 확인
  const payCheck = (payload: PayCallbackType) => {
    retryFetchPremiumPaymentTradeInfo(payload)
  }

  const retryFetchPremiumPaymentTradeInfo = (payload: PayCallbackType) => {
    layoutStore.updateLoadingIndicatorGlobal({
      show: true,
      message: t('subscribe.supportPayProcess.loading'),
    })

    const loading = ref(false)
    const timeLimit = ref(MINUTE * 3)
    const interval = setInterval(async () => {
      timeLimit.value = timeLimit.value - SECOND

      if (timeLimit.value <= 0) {
        await showFailDialog(
          t('subscribe.supportPayProcess.fail.failMessage.clientTimeout')
        )
        turnOffRetry()
        return
      }

      await fetchPremiumPaymentTradeInfo()
    }, SECOND)
    const oldHref = document.location.href

    // 라우터 이동 감지시 리트라이 금지
    const routeObserver = new MutationObserver(() => {
      console.log(oldHref)
      console.log(document.location.href)
      if (oldHref !== document.location.href) {
        turnOffRetry()
      }
    })
    routeObserver.observe(document.querySelector('body')!, {
      childList: true,
      subtree: true,
    })

    // 리트라이 끄기
    const turnOffRetry = () => {
      clearInterval(interval)
      layoutStore.updateLoadingIndicatorGlobal({ show: false })
      loading.value = false
      routeObserver.disconnect()
    }

    // 결제 상태 조회
    const fetchPremiumPaymentTradeInfo = async () => {
      // 로딩 중이면 호출하지 않는다.
      if (loading.value) return

      try {
        loading.value = true
        const { success, data, returnMsg } =
          await creatorStore.fetchPremiumPaymentTradeInfo({
            merchantOrderNo: payload.merchantOrderNo,
          })

        if (!success) {
          turnOffRetry()
          await showFailDialog(
            t('subscribe.supportPayProcess.fail.message', {
              subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
              failMessage: returnMsg,
            })
          )
        }

        if (success) {
          switch (data?.status) {
            case 'NEW':
              // 라우터 이동전까지 계속 리트라이
              break
            case 'PAY_SUCCESS':
              turnOffRetry()
              await showSuccessDialog()

              // 결제 성공으로 자동 팔로우
              if (payload.autoFollow) {
                const { generateReCaptchaToken } = useReCaptcha()
                await commStore.reqFollow({
                  flwUserSn: payload.creatorUserSn,
                  generateReCaptchaToken,
                })
              }
              break
            case 'PAY_FAIL':
              turnOffRetry()
              await showFailDialog(
                t('subscribe.supportPayProcess.fail.failMessage.fail', {
                  subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
                })
              )
              break
            case 'TIMEOUT':
              turnOffRetry()
              await showFailDialog(
                t('subscribe.supportPayProcess.fail.message', {
                  subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
                  failMessage: t(
                    'subscribe.supportPayProcess.fail.failMessage.serverTimeout',
                    {
                      subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
                    }
                  ),
                })
              )
              break
            default:
              turnOffRetry()
              await showFailDialog(
                t('subscribe.supportPayProcess.fail.failMessage.requireRetry')
              )
              break
          }
        }
      } catch {
        turnOffRetry()
        await showFailDialog(
          t('subscribe.supportPayProcess.fail.failMessage.requireRetry')
        )
      } finally {
        loading.value = false
      }
    }

    // 성공 다이얼로그 열기
    const showSuccessDialog = async () => {
      await modalShow({
        component: NovaModalDialog,
        bind: {
          name: modalsName.MODAL_DIALOG,
          btns: [
            {
              label: t('confirm'),
              theme: 'primary-blue-light',
              size: 32,
              onClick: async () => {
                await modalHide(modalsName.MODAL_DIALOG)
                await useNavigations({
                  external: false,
                  url: '/nova-plus',
                  type: 'replace',
                })
              },
            },
          ],
          contentsTextAlign: 'center',
        },
        slots: {
          title: t('subscribe.supportPayProcess.success.title'),
          content: t('subscribe.supportPayProcess.success.message', {
            subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
          }),
        },
      })
    }

    // 실패 다이얼로그 열기
    const showFailDialog = async (message: string) => {
      await modalShow({
        component: NovaModalDialog,
        bind: {
          name: modalsName.MODAL_DIALOG,
          btns: [
            {
              label: t('confirm'),
              theme: 'primary-blue-light',
              size: 32,
              onClick: async () => {
                await modalHide(modalsName.MODAL_DIALOG)
              },
            },
          ],
          contentsTextAlign: 'center',
        },
        slots: {
          title: t('subscribe.supportPayProcess.fail.title'),
          content: message,
        },
      })
    }
  }

  return {
    callbackUrlPay,
    openPay,
    payCheck,
  }
}
