<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import type {
  NovaTipTapMenuEmits,
  NovaTipTapMenuProps,
} from './NovaTipTapMenu.types'
const emits = defineEmits<NovaTipTapMenuEmits>()
const props = withDefaults(defineProps<NovaTipTapMenuProps>(), {
  imageCount: 0,
})
const appendToRef = ref<Element | null>(null)
const textColorTippy = ref<typeof Tippy | null>(null)
const highlightColorTippy = ref<typeof Tippy | null>(null)
const linkTippy = ref<typeof Tippy | null>(null)
const videoTippy = ref<typeof Tippy | null>(null)
const state = reactive({
  colorSet: {
    sort: 'color',
    colors: [
      '#000000',
      '#cc0000',
      '#ff9424',
      '#237200',
      '#002089',
      '#5a31c2',
      '#777777',
    ],
  },
  highlightSet: {
    sort: 'highlight',
    colors: [
      '#f4cccc',
      '#fce5cd',
      '#fffbb9',
      '#d9ead3',
      '#cfe2f3',
      '#e7dffb',
      '#e8e8e8',
    ],
  },
  currentColor: '#000',
  currentHighlight: '#000',
  isColor: false,
  isHighlight: false,
  isLink: false,
  isVideo: false,
})
const { t } = useI18n()
const postStore = usePostStore()
const appStore = useAppStore()
const { checkPermission, requestPermission } = useAppPermission()
const fileUploadInput = ref<HTMLInputElement | null>(null)

onMounted(() => {
  setAppendRef()
})

const handleOnClickUploadImage = async () => {
  // 앱 일때 스토리지 권한 체크
  if (appStore.isApp) {
    const { data: permission } = await requestPermission('storage')

    if (permission.status === 'off') {
      const accessAble = await checkPermission({
        permission: 'storage',
        permissionType: 'post',
      })
      if (!accessAble) return
    }
  }

  if (props.imageCount >= 10) return
  fileUploadInput.value!.click()
}

const handleOnChangeUploadImage = (evt: Event) => {
  try {
    const input = evt.target as HTMLInputElement
    if (!input.files?.length) {
      return
    }

    const file = input.files[0]
    const reader = new FileReader()

    reader.onloadstart = () => {
      emits('onLoading', true)
    }

    reader.onload = async () => {
      const formData = new FormData()
      formData.append('imgFile', file)

      if (!props.editor.isEmpty) {
        props.editor.commands.insertContent('<p></p>')
      }

      try {
        const res = await postStore.uploadTempImg(formData)
        props.editor.commands.setCustomImage({ src: res })
      } catch (err: any) {
        if (err.response) {
          switch (err.response.status) {
            case 2005:
              await useToast(t('postCreate.toastMessage.imgError2005'))
              break
            default:
              await useToast(t('postCreate.toastMessage.imgError'))
              break
          }
        }
      } finally {
        emits('onLoading', false)
      }
    }

    reader.onerror = () => {
      useToast(t('postCreate.toastMessage.imgError'))
    }

    reader.readAsArrayBuffer(file) // 파일을 읽기 시작
  } catch (err: any) {
    if (err.response) {
      switch (err.response.status) {
        case 2005:
          useToast(t('postCreate.toastMessage.imgError2005'))
          break
        default:
          useToast(t('postCreate.toastMessage.imgError'))
          break
      }
    }
  } finally {
    emits('onLoading', false)
  }
}

const handleOnLinkBox = (sort: string) => {
  const selectBlock = window.getSelection() as Selection | null

  if (!selectBlock?.isCollapsed) {
    if (
      selectBlock?.focusNode?.parentElement?.classList[0] === sort ||
      selectBlock?.focusNode?.parentElement?.nodeName !== 'A'
    ) {
      sort === 'link' ? (state.isLink = true) : (state.isVideo = true)
    } else {
      useToast(t('postCreate.toastMessage.havingLink'))
    }
  } else {
    useToast(t('postCreate.toastMessage.noLinkSelect'))
  }
}

const handleOnCloseLinkBox = (sort: string) => {
  sort === 'link' ? (state.isLink = false) : (state.isVideo = false)
}

const handleOnCloseColorBox = (sort: string, item: string) => {
  if (sort === 'color') {
    textColorTippy.value?.hide()
    state.isColor = false
    state.currentColor = item
  } else {
    highlightColorTippy.value?.hide()
    state.isHighlight = false
    state.currentHighlight = item
  }
}

const handleOnOpenAddLinkPanel = () => {
  if (!state.isLink) return false
}

const handleOnCloseAddLinkPanel = () => {
  linkTippy.value?.hide()
}

const handleOnOpenAddVideoPanel = () => {
  if (!state.isVideo) return false
}

const handleOnCloseAddVideoPanel = () => {
  videoTippy.value?.hide()
}

const setAppendRef = () => {
  if (document.body) {
    appendToRef.value = document.body
  }
}
</script>

<template>
  <div class="editor-menu-bar">
    <!-- Menu Category: heading -->
    <div class="button-group">
      <NovaTipTapMenuButton
        :icon="{ type: 'outline', name: 'text-heading-1' }"
        :is-active="editor?.isActive('heading', { level: 1 })"
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
      />
      <NovaTipTapMenuButton
        :icon="{ type: 'outline', name: 'text-heading-2' }"
        :is-active="editor?.isActive('heading', { level: 2 })"
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
      />
      <NovaTipTapMenuButton
        :icon="{ type: 'outline', name: 'text-heading-3' }"
        :is-active="editor?.isActive('heading', { level: 3 })"
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
      />
      <NovaTipTapMenuButton
        :icon="{ type: 'outline', name: 'text-paragraph' }"
        :is-active="editor?.isActive('paragraph')"
        @click="editor.chain().focus().setParagraph().run()"
      />
    </div>

    <!-- Menu Category: font -->
    <div class="button-group">
      <NovaTipTapMenuButton
        :icon="{ type: 'outline', name: 'text-bold' }"
        :is-active="editor?.isActive('bold')"
        @click="editor.chain().focus().toggleBold().run()"
      />
      <NovaTipTapMenuButton
        :icon="{ type: 'outline', name: 'text-italic' }"
        :is-active="editor?.isActive('italic')"
        @click="editor.chain().focus().toggleItalic().run()"
      />
      <NovaTipTapMenuButton
        :icon="{ type: 'outline', name: 'text-underline' }"
        :is-active="editor?.isActive('underline')"
        @click="editor.chain().focus().toggleUnderline().run()"
      />
      <NovaTipTapMenuButton
        :icon="{ type: 'outline', name: 'text-strikethrow' }"
        :is-active="editor?.isActive('strike')"
        @click="editor.chain().focus().toggleStrike().run()"
      />
    </div>

    <!-- Menu Category: Font Color  -->
    <div class="button-group">
      <div :class="['tooltip-area', state.isColor ? 'on' : '']">
        <Tippy
          ref="textColorTippy"
          :append-to="appendToRef"
          :trigger="'click'"
          :theme="'popover'"
          :placement="'bottom-start'"
          :interactive="true"
          :z-index="1100"
        >
          <NovaTipTapMenuButton
            :icon="{ type: 'outline', name: 'text-color' }"
            :is-active="state.isColor"
            :color="state.currentColor"
            @click="() => textColorTippy.hide()"
          />

          <template #content>
            <NovaTipTapMenuColorPicker
              :label="$t('colorPickerPanel.textColorLabel')"
              :editor="editor"
              :data-set="state.colorSet"
              @handle-on-close-box="handleOnCloseColorBox"
            />
          </template>
        </Tippy>
      </div>

      <!-- Menu Category: Font Highlight -->
      <div :class="['tooltip-area', state.isHighlight ? 'on' : '']">
        <Tippy
          ref="highlightColorTippy"
          :append-to="appendToRef"
          :trigger="'click'"
          :theme="'popover'"
          :placement="'bottom-start'"
          :interactive="true"
          :z-index="1100"
        >
          <NovaTipTapMenuButton
            :icon="{ type: 'outline', name: 'text-color-picker' }"
            :is-active="state.isHighlight"
            @click="state.isHighlight = !state.isHighlight"
          />

          <template #content>
            <NovaTipTapMenuColorPicker
              :label="$t('colorPickerPanel.highlightColorLabel')"
              :editor="editor"
              :data-set="state.highlightSet"
              @handle-on-close-box="handleOnCloseColorBox"
            />
          </template>
        </Tippy>
        <!--        <NovaTipTapMenuButton
          :icon="{type: 'outline', name: 'text-color-picker'}"
          :is-active="state.isHighlight"
          @click="state.isHighlight = !state.isHighlight"
        />
        <NovaTipTapMenuColorPicker
          v-if="state.isHighlight"
          :label="$t('colorPickerPanel.highlightColorLabel')"
          :editor="editor"
          :data-set="state.highlightSet"
          @handle-on-close-box="handleOnCloseColorBox"
        />-->
      </div>
    </div>

    <!-- Menu Category: Align  -->
    <div class="button-group">
      <NovaTipTapMenuButton
        :icon="{ type: 'outline', name: 'align-left' }"
        :is-active="editor?.isActive('align-left')"
        @click="editor.chain().focus().setTextAlign('left').run()"
      />
      <NovaTipTapMenuButton
        :icon="{ type: 'outline', name: 'align-middle' }"
        :is-active="editor?.isActive('align-center')"
        @click="editor.chain().focus().setTextAlign('center').run()"
      />
      <NovaTipTapMenuButton
        :icon="{ type: 'outline', name: 'align-right' }"
        :is-active="editor?.isActive('align-right')"
        @click="editor.chain().focus().setTextAlign('right').run()"
      />
    </div>

    <!-- List -->
    <div class="button-group">
      <NovaTipTapMenuButton
        :icon="{ type: 'outline', name: 'list-number' }"
        :is-active="editor?.isActive('orderedList')"
        @click="editor.chain().focus().toggleOrderedList().run()"
      />
      <NovaTipTapMenuButton
        :icon="{ type: 'outline', name: 'list-point' }"
        :is-active="editor?.isActive('bulletList')"
        @click="editor.chain().focus().toggleBulletList().run()"
      />
    </div>

    <!-- Menu Category: Link Upload  -->
    <div class="button-group">
      <div :class="['tooltip-area', state.isLink ? 'on' : '']">
        <Tippy
          ref="linkTippy"
          :append-to="appendToRef"
          :trigger="'click'"
          :theme="'popover'"
          :placement="'bottom-start'"
          :interactive="true"
          :z-index="1100"
          :on-show="handleOnOpenAddLinkPanel"
          :on-hide="() => handleOnCloseLinkBox('link')"
        >
          <NovaTipTapMenuButton
            :icon="{ type: 'outline', name: 'link' }"
            :is-active="editor?.isActive('link', { class: 'link' })"
            @click="handleOnLinkBox('link')"
          />

          <template #content>
            <NovaTipTapMenuAddLink
              :editor="editor"
              :is-open="state.isLink"
              @on-close="handleOnCloseAddLinkPanel"
            />
          </template>
        </Tippy>
      </div>

      <div class="tooltip-area">
        <Tippy
          ref="videoTippy"
          :append-to="appendToRef"
          :trigger="'click'"
          :theme="'popover'"
          :placement="'bottom-start'"
          :interactive="true"
          :z-index="1100"
          :on-show="handleOnOpenAddVideoPanel"
          :on-hide="() => handleOnCloseLinkBox('video')"
        >
          <NovaTipTapMenuButton
            :icon="{ type: 'outline', name: 'video' }"
            :is-active="editor?.isActive('link', { class: 'video' })"
            @click="handleOnLinkBox('video')"
          />
          <template #content>
            <NovaTipTapMenuAddVideo
              :editor="editor"
              :is-open="state.isVideo"
              @on-close="handleOnCloseAddVideoPanel"
            />
          </template>
        </Tippy>
      </div>

      <NovaTipTapMenuButton
        :icon="{ type: 'outline', name: 'image-compact-sm' }"
        :is-active="false"
        :color="imageCount >= 10 ? '#DADADA' : '#3F4354'"
        @click="handleOnClickUploadImage"
      />

      <input
        ref="fileUploadInput"
        class="upload-image-input"
        accept="image/*"
        type="file"
        onclick="this.value = null"
        @change="handleOnChangeUploadImage"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.editor-menu-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px 0;
  width: 100%;
  height: auto;
  padding: 12px;
  background-color: $color-bg-2;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .button-group {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    padding-right: 12px;

    &:not(:last-of-type):after {
      position: absolute;
      top: 50%;
      right: 6px;
      width: 1px;
      height: 16px;
      content: '';
      background-color: $color-black-10p;
      transform: translateY(-50%);
    }

    .popup {
      position: absolute;
      top: 32px;
      width: 250px;
      padding: 16px;
      background: #fff;
      border: 2px solid #ddd;
      z-index: 100;

      & em {
        margin-right: 8px;
      }
      & label {
        display: block;
        margin-top: 8px;
      }

      & input {
        border: 1px solid #eee;
      }
    }
  }

  .upload-image-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none;
  }

  .tooltip-area {
    position: relative;
  }
}
</style>
