import apiPath from '@store/apiPath'

export default {
  /**
   * 카테고리 목록 조회
   * @method GET
   */
  FETCH_CATEGORIES: `${apiPath.POST}/v2/categorys`,

  /**
   * 관리자 해시태그 목록 조회
   * @method GET
   */
  FETCH_BASE_HASHTAGS: `${apiPath.POST}/v1/hashtags`,

  /**
   * 포스트 상세 조회
   * @method GET
   */
  FETCH_POST: `${apiPath.POST}/v3/post`,

  /**
   * 크리에이터 포스트 상세 조회
   * @method GET
   */
  FETCH_CREATOR_POST: `${apiPath.CREATOR}/post`,

  /**
   * 포스트 목록 조회
   * @method GET
   */
  FETCH_POSTS: `${apiPath.POST}/v2/posts`,

  /**
   * 사용자 포스트 목록 조회
   * @method GET
   */
  FETCH_USER_POSTS: `${apiPath.POST}/v1/posts/user`,

  /**
   * 사용자 포스트 목록 조회
   * @method GET
   */
  FETCH_HASHTAG_POSTS: `${apiPath.POST}/v1/posts-hashtag`,

  /**
   * 포스트 좋아요, 좋아요 취소
   * @method POST, PUT
   */
  REQ_POST_RECOMMEND: `${apiPath.POST}/v2/post/recommend`,

  /**
   * 포스트 좋아요 취소
   * @method PUT
   */
  REQ_PUT_UN_RECOMMEND: `${apiPath.POST}/v1/post/recommend`,

  /**
   * 포스트 싫어요하기, 싫어요취소
   * @method POST, PUT
   */
  REQ_POST_UN_RECOMMEND: `${apiPath.POST}/v1/post/unrecommend`,

  /**
   * 포스트 스크랩, 스크랩취소
   * @method POST, PUT
   */
  REQ_POST_SCRAP: `${apiPath.POST}/v1/scrap`,

  /**
   * 포스트 숨기기
   * @method POST
   */
  REQ_POST_HIDE: `${apiPath.POST}/v1/post/hide`,

  /**
   * 포스트 신고하기
   * @method POST
   */
  REQ_POST_REPORT: `${apiPath.POST}/v1/post/report`,

  /**
   * 포스트 등록
   * @method POST
   */
  CREATE_POST: `${apiPath.POST}/v2/post`,

  /**
   * 포스트 삭제
   * @method DELETE
   */
  DELETE_POST: `${apiPath.POST}/v1/post`,

  /**
   * 포스트 수정
   * @method PUT
   */
  EDIT_POST: `${apiPath.POST}/v1/post`,

  /**
   * 임시 포스트 등록
   * @method POST
   */
  CREATE_TEMP_POST: `${apiPath.POST}/v1/temp-post`,

  /**
   * 임시 포스트 조회
   * @method GET
   */
  FETCH_TEMP_POST: `${apiPath.POST}/v1/temp-post`,

  /**
   * 임시 포스트 삭제
   * @method Delete
   */
  DELETE_TEMP_POST: `${apiPath.POST}/v1/temp-post`,

  /**
   * 임시 포스트 리스트 조회
   * @method GET
   */
  FETCH_TEMP_POSTS: `${apiPath.POST}/v1/temp-posts`,

  /**
   * 포스트 임시 이미지 업로드
   * @method GET
   */
  UPLOAD_TEMP_IMG: `${apiPath.IMG}/v1/upload`,

  /**
   * 댓글 & 답글 리스트 조회
   * @method GET
   */
  FETCH_COMMENTS_AND_REPLIES: `${apiPath.POST}/v1/replys`,

  /**
   * 댓글 & 답글 작성, 삭제
   * @method POST, DELETE
   */
  REQ_COMMENT: `${apiPath.POST}/v1/reply`,

  /**
   * 댓글 & 답글 수정
   * @method PUT
   */
  EDIT_COMMENT: `${apiPath.POST}/v1/reply`,

  /**
   * 댓글 & 답글 숨기기
   * @method POST
   */
  REQ_COMMENT_HIDE: `${apiPath.POST}/v1/reply/hide`,

  /**
   * 댓글 & 답글 좋아요, 좋아요 취소
   * @method POST, PUT
   */
  REQ_COMMENT_RECOMMEND: `${apiPath.POST}/v1/reply/recommend`,

  /**
   * 댓글 & 답글 싫어요, 싫어요취소
   * @method POST, PUT
   */
  REQ_COMMENT_UN_RECOMMEND: `${apiPath.POST}/v1/reply/unrecommend`,

  /**
   * 댓글 & 답글 신고하기
   * @method POST
   */
  REQ_COMMENT_REPORT: `${apiPath.POST}/v1/reply/report`,

  /**
   * 포스트 공유
   * @method POST
   */
  REQ_POST_SHARE: `${apiPath.POST}/v1/post/share`,

  /**
   * 크리에이터 목록 조회
   * @method GET
   */
  FETCH_CREATORS: `${apiPath.POST}/v1/creators`,

  /**
   * 프리미엄 포스트 목록 조회
   * @method GET
   */
  FETCH_PREMIUM_POSTS: `${apiPath.POST}/v2/premium/posts`,

  /**
   * 게시글 후원
   * @method POST
   */
  REQ_POSTING_DONATION: `${apiPath.POST}/v1/donation`,

  /**
   * 게시글 작성 가능 여부 조회(카테고리 조건)
   */
  FETCH_POST_CREATE_AVAILABLE: `${apiPath.POST}/v1/category/count-permission`,
}
