import type {
  PrivateKey,
  PrivateKeyPayload,
  BooleanYn,
  FirebaseDevice,
} from '@store'
import { bridgeToApp } from '@bridge'
import { authConfig } from '@configs'
import {
  fcmPayloadDataUrlConverter,
  fcmUrlHandlerGating,
} from '@firebaseModule'
import { BlockAccessType } from '@components/NovaModalBlockAccess/NovaModalBlockAccess.types'
import { NovaModalDialog } from '#components'
/**
 * 로그인 콜백
 */
export interface SignInCallbackType {
  userCmmnSn: number // 공통 사용자 일련번호
  userSn: number // 사용자 일련번호
  fcmDevice: FirebaseDevice // FCM Device 코드
  autoLogin?: boolean // 자동로그인 여부
  deepLink?: string // 로그인 후 리다이렉트할 deepLink
  cf1?: string
  cf2?: string
  cf3?: string

  // 클라이언트에서 사용하는 모델
  firstCheckBlockAccess?: boolean // 최초 로그인 후 사용자 차단 상태 체크 여부(사용자 차단 상태일경우 모달 여는 조건)
}

/**
 * 회원 가입 콜백
 */
export interface SignUpCallbackType {
  [Key: string]: any
  fcmDevice: FirebaseDevice
  userCmmnSn: number
  recommenderId?: string // 추천인 이메일
  referralCode?: string // 레퍼럴(크리에이터 링크)
  recomendAffltsId?: string // 제휴사 코드
  recomendAffltsSbscrbAt?: BooleanYn // 제휴사 코드 사용여부(제휴사 코드가 있을 경우 'Y')
  autoLogin?: boolean // 자동 로그인 여부
  cf1?: string
  cf2?: string
  cf3?: string
}

/**
 * 로그인 메세지
 */
export type SignInMessageType = {
  type: 'signin'
  res: SignInCallbackType
}

/**
 * 회원가입 메세지
 */
export type SignUpMessageType = {
  type: 'signup'
  res: SignUpCallbackType
}

/**
 * 비밀 번호 변경 메세지
 */
export type SearchPwMessageType = {
  type: 'searchpw'
  res: null
}
// interface MembershipServiceParams {
//   clientId: string // 클라이언트 아이디(각 서비스 아이디)
//   endpoint: MembershipService // 통합회원을 사용하는 목적
//   callback: string // 통합회원 목적 달성후 콜백 받을 url
//   userCmmnSn?: number // KYC 인증 받을 메일 조회용
//   stplatId?: string // 이용약관 상세 페이지 다이렉트 호출용 약관 ID
//   langCode?: string // 언어 코드
//   referrel?: string // 커뮤니티 레퍼럴(크리에이터 링크) 회원가입
//   cf1?: string // 커스텀 필드 1
//   cf2?: string // 커스텀 필드 2
//   cf3?: string // 커스텀 필드 3
// }

export const useMembershipProcess = () => {
  const { $i18n } = useNuxtApp()
  const config = useRuntimeConfig()
  const layoutStore = useLayoutStore()
  const userStore = useUserStore()
  const mypageStore = useMyPageStore()
  const etcStore = useEtcStore()
  const appStore = useAppStore()

  const {
    localStorageItems,
    get: getLocalStorage,
    set: setLocalStorage,
  } = useLocalStorage()

  /**
   * 프라이빗 키가 로컬스토리지에 있으면 리턴, 아니면 api로 받아와 로컬스토리지에 저장하고 리턴
   * @description 크리에이터를 후원하기(Transfer) 위한 key를 로컬스토리지에 저장
   * @param userSn
   */
  const getPrivateKey = async (
    userSn: PrivateKeyPayload['userSn']
  ): Promise<PrivateKey['privateKey'] | null> => {
    const key = localStorageItems.PRIVATE_KEY.key.replace(
      '{userSn}',
      String(userSn)
    )
    let privateKey = await getLocalStorage<string, PrivateKey['privateKey']>(
      key
    ).catch(() => null)

    if (!privateKey) {
      const value = await userStore.fetchPrivateKey({
        userSn,
      })

      if (value.privateKey) {
        await setLocalStorage<string, typeof value.privateKey>(
          key,
          value.privateKey,
          localStorageItems.PRIVATE_KEY.expireDays
        )
        privateKey = value.privateKey
      }
    }

    return privateKey
  }

  /**
   * URL 랜딩(최초 접근) 시 비휘발성 메모리에 담겨있는 사용자 아이디를 사용하여 사용자 정보 조회
   * @description 사용자 정보 조회(common, profile, notification 정보 조회)
   * @param payload
   */
  const fetchAllUserInfoByAccessToken = async (payload: SignUpCallbackType) => {
    await userStore.fetchUserCommonInfo({ userCmmnSn: payload.userCmmnSn })
    await userStore.fetchKycStatus({ userCmmnSn: payload.userCmmnSn })
    await mypageStore.fetchProfile()
  }

  /**
   * 로그인 프로세스
   * @param payload
   */
  const signIn = async (payload: SignInCallbackType) => {
    const { gtEvent } = useGoogleTag()
    try {
      const { show: modalShow, hide: modalHide } = useModal()
      const { cookieNames, setCookie } = useCookies()
      layoutStore.updateLoadingIndicatorGlobal({ show: true })

      // 회원정보 조회
      await userStore.postSignin({
        autoLogin: payload.autoLogin,
        fcmDevice: payload.fcmDevice,
        userCmmnSn: payload.userCmmnSn,
        userSn: payload.userSn,
      })
      await userStore.fetchUserCommonInfo({ userCmmnSn: payload.userCmmnSn })
      await userStore.fetchKycStatus({ userCmmnSn: payload.userCmmnSn })
      await mypageStore.fetchProfile()
      await etcStore.fetchNotificationSettings()
      gtEvent('userAction', {
        eventCategory: '사용자',
        eventAction: '로그인',
        eventLabel: $i18n.t('sign.in'),
        eventSlot: '유저 로그인',
        eventI18nAddr: useKoreanTranslation('sign.in'),
        eventComponent: '',
        userSn: payload.userSn,
      })

      // 앱, 온보딩에서 건너띄기를 통한 로그인 시도시 2번째 온보딩을 보여주지 않기 위해 쿠키에 특정 정보 저장
      if (payload.cf2 === 'onBoarding') {
        setCookie(cookieNames.IS_SHOW_ON_BOARDING_ONCE, true, 5, 'minutes')
      }

      const signinAfterAction = async () => {
        if (payload.deepLink) {
          const deepLink = payload.deepLink.replace('@@', '#')
          const _url = fcmPayloadDataUrlConverter(deepLink)
          await fcmUrlHandlerGating(_url)
        } else {
          location.reload()
        }
      }

      // 최초 로그인시 사용자 차단 상태 검사 요건 확인 후 사용자 차단 상태 일대 모달 호출
      if (
        payload.firstCheckBlockAccess &&
        userStore.user?.svcActSanctnsAt === 'Y'
      ) {
        await modalShow(modalsName.MODAL_BLOCK_ACCESS, {
          blockAccessType: BlockAccessType.SIGNIN,
          onClickConfirm: async () => {
            await modalHide(modalsName.MODAL_BLOCK_ACCESS)
            await signinAfterAction()
          },
        })
      } else {
        await signinAfterAction()
      }
    } catch (err) {
      await showDialog(
        $i18n.t('dialog.resultSignIn.fail.title'),
        $i18n.t('dialog.resultSignIn.fail.content'),
        () => userStore.signOut()
      )
    } finally {
      layoutStore.updateLoadingIndicatorGlobal({ show: false })
    }
  }

  /**
   * 회원가입 프로세스
   * @description 회원가입 성공 후 사용자 통합 회원 정보 조회 및 사용자 프로필 조회
   * @param payload
   */
  const signUp = async (payload: SignUpCallbackType) => {
    const { gtEvent } = useGoogleTag()
    try {
      layoutStore.updateLoadingIndicatorGlobal({ show: true })

      await userStore.postSignUp({
        fcmDevice: payload.fcmDevice,
        recomendAffltsId: payload.recomendAffltsId,
        recommenderId: payload.recommenderId,
        referralCode: payload.referralCode,
        userCmmnSn: payload.userCmmnSn,
        autoLogin: !!payload.autoLogin,
      })
      await userStore.fetchUserCommonInfo({
        userCmmnSn: payload.userCmmnSn,
      })
      await mypageStore.fetchProfile()
      await etcStore.fetchNotificationSettings()

      await showDialog(
        $i18n.t('dialog.resultSignUp.success.title'),
        $i18n.t('dialog.resultSignUp.success.content'),
        () => useNavigations({ url: '/' })
      )
      gtEvent('userAction', {
        eventCategory: '사용자',
        eventAction: '회원 가입',
        eventLabel: $i18n.t('sign.in'),
        eventSlot: '유저 회원 가입',
        eventI18nAddr: useKoreanTranslation('sign.in'),
        eventComponent: '',
        userCmmnSn: payload.userCmmnSn,
      })
    } catch (err) {
      await showDialog(
        $i18n.t('dialog.resultSignUp.fail.title'),
        $i18n.t('dialog.resultSignUp.fail.content')
      )
    } finally {
      layoutStore.updateLoadingIndicatorGlobal({ show: false })
    }
  }

  /**
   * 통합 멤버쉽 팝업 열기
   * @param type
   */
  const openMembershipServicePopup = {
    signin: (
      deepLink?: string,
      customFields?: Pick<SignInCallbackType, 'cf2' | 'cf3'>
    ) => {
      const appStore = useAppStore()
      const type = 'signin'
      const payload: any = {
        clientId: authConfig.AUTH_PARAMS.CLIENT_ID,
        callback: window.location.origin,
        endpoint: type,
        langCode: $i18n.locale.value.toUpperCase(),
        ...customFields,
      }

      if (appStore.isApp) {
        payload.cf1 = 'appMode'
      }

      if (deepLink) {
        payload.deepLink = deepLink.replace('#', '@@')
      }

      const params = new URLSearchParams(payload).toString()

      useOpenPopupAtOnce(`${config.public.AUTH_URL}?${params}`, type)
    },
    signup: (referralCode = '') => {
      const appStore = useAppStore()
      const type = 'signup'
      const payload: any = {
        clientId: authConfig.AUTH_PARAMS.CLIENT_ID,
        callback: window.location.origin,
        endpoint: type,
        langCode: $i18n.locale.value.toUpperCase(),
      }

      if (referralCode) {
        payload.referral = referralCode
      }

      if (appStore.isApp) {
        payload.cf1 = 'appMode'
      }

      const params = new URLSearchParams(payload).toString()

      useOpenPopupAtOnce(`${config.public.AUTH_URL}?${params}`, type)
    },
    kyc: () => {
      const type = 'kyc'
      const params = new URLSearchParams({
        clientId: authConfig.AUTH_PARAMS.CLIENT_ID,
        callback: window.location.origin,
        endpoint: type,
        userCmmnSn: userStore.user!.userCmmnSn.toString(),
        langCode: $i18n.locale.value.toUpperCase(),
      }).toString()
      const url = `${config.public.AUTH_URL}?${params}`

      if (appStore.isApp) {
        bridgeToApp.openWeb({ url, type: 'external' })
      } else {
        useOpenPopupAtOnce(url, type)
      }
    },
    searchPW: () => {
      const type = 'searchpw'
      const params = new URLSearchParams({
        clientId: authConfig.AUTH_PARAMS.CLIENT_ID,
        endpoint: type,
        scope: 'authorization',
        userCmmnSn: userStore.user!.userCmmnSn.toString(),
        callback: window.location.origin,
        langCode: $i18n.locale.value.toUpperCase(),
      }).toString()
      const url = `${config.public.AUTH_URL}?${params}`
      useOpenPopupAtOnce(url, type)
    },
  }

  /**
   * 로그인 필요시 다이얼로그 모달 열기
   * @async
   * @param {string} [deepLink] - 로그인 프로세스 엔드포인트로 딥링크.
   * @param customFields
   * @returns {Promise<void>} - 로그인 다이얼로그가 표시되면 해결되는 프로미스.
   */
  const showSignInDialog = async (
    deepLink?: string,
    customFields?: Pick<SignInCallbackType, 'cf2' | 'cf3'>
  ): Promise<void> => {
    const { show: modalShow, hide: modalHide } = useModal()
    await modalShow({
      component: NovaModalDialog,
      bind: {
        name: modalsName.MODAL_REQUIRE_SIGN_IN,
        btns: [
          {
            label: $i18n.t('dialog.requireSignIn.action.negative'),
            theme: 'transparent',
            size: 32,
            onClick: async () => {
              await modalHide(modalsName.MODAL_REQUIRE_SIGN_IN)
            },
          },
          {
            label: $i18n.t('dialog.requireSignIn.action.positive'),
            theme: 'primary-blue-light',
            size: 32,
            onClick: async () => {
              openMembershipServicePopup.signin(deepLink, customFields)
              await modalHide(modalsName.MODAL_REQUIRE_SIGN_IN)
            },
          },
        ],
      },
      slots: {
        title: $i18n.t('dialog.requireSignIn.title'),
        content: $i18n.t('dialog.requireSignIn.description'),
      },
    })
  }

  /**
   * 회원가입 완료 및 에러 다이얼로그 모달
   */
  const showDialog = async (
    title: string,
    content: string,
    event?: Function
  ) => {
    const { show: modalShow, hide: modalHide } = useModal()

    await modalShow({
      component: NovaModalDialog,
      bind: {
        name: modalsName.MODAL_DIALOG,
        btns: [
          {
            label: $i18n.t('dialog.resultSignUp.confirm'),
            theme: 'primary-blue-light',
            size: 32,
            onClick: async () => {
              event?.()
              await modalHide(modalsName.MODAL_DIALOG)
            },
          },
        ],
      },
      slots: { title, content },
    })
  }

  /**
   * 같은 브라우저에서 2개 이상의 탭의 로그인한 사용자가 다를때 안내 모달 열기
   */
  const showMultipleSignInDialog = async () => {
    const { show: modalShow, hide: modalHide } = useModal()
    await modalShow({
      component: NovaModalDialog,
      bind: {
        name: modalsName.MODAL_MULTIPLE_SIGN_IN,
        btns: [
          {
            label: $i18n.t('dialog.multipleSignIn.action.positive'),
            theme: 'primary-blue-light',
            size: 32,
            onClick: async () => {
              await modalHide(modalsName.MODAL_MULTIPLE_SIGN_IN)
              userStore.signOut()
            },
          },
        ],
      },
      slots: {
        title: $i18n.t('dialog.multipleSignIn.title'),
        content: $i18n.t('dialog.multipleSignIn.description'),
      },
    })
  }

  return {
    layoutStore,
    userStore,
    mypageStore,
    openMembershipServicePopup,
    signIn,
    signUp,
    fetchAllUserInfoByAccessToken,
    showSignInDialog,
    showMultipleSignInDialog,
    getPrivateKey,
  }
}
