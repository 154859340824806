interface UseGetFilesProps {
  accept: string
  multiple?: boolean
  formDataName: string
}
const MAX_FILE_SIZE_MB = 500
const WARNING_FILE_SIZE_MB = 100

const useGetFiles = ({
  accept,
  multiple = false,
  formDataName,
}: UseGetFilesProps): Promise<{ formData: FormData; files: FileList | null }> =>
  new Promise((resolve) => {
    const { t } = useNuxtApp().$i18n
    // 1. 기존 남아있는 input 제거
    const existingInput = document.getElementById('file-input')
    if (existingInput) {
      document.body.removeChild(existingInput)
    }

    // 2. 새로운 input 요소 생성
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', accept)
    input.setAttribute('id', 'file-input') // 고유 ID 설정
    if (multiple) {
      input.setAttribute('multiple', '')
    }

    // 3. input 스타일 숨기기
    input.style.cssText = `
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      opacity: 0;
      pointer-events: none;
    `

    // 4. 파일 선택 이벤트 처리
    input.addEventListener('change', (evt) => {
      const files = (evt.target as HTMLInputElement).files
      const formData = new FormData()

      if (files) {
        // 1. 500MB 초과 파일 검사
        const isOverMaxSize = Array.from(files).some(
          (file) => file.size > MAX_FILE_SIZE_MB * 1024 * 1024
        )

        if (isOverMaxSize) {
          useToast(t('postCreate.toastMessage.videoFileSize.over'))
          cleanup()
          return
        }

        // 2. 100MB 초과 시 경고 메시지 표시
        const isOverWarningSize = Array.from(files).some(
          (file) => file.size > WARNING_FILE_SIZE_MB * 1024 * 1024
        )

        if (isOverWarningSize) {
          useToast(t('postCreate.toastMessage.videoFileSize.warning'))
        }

        // 3. 유효한 파일을 FormData에 추가
        Array.from(files).forEach((file) => {
          formData.append(formDataName, file)
        })
      }

      cleanup()
      resolve({ formData, files })
    })

    // 6. input을 DOM에 추가하고 클릭 트리거
    document.body.appendChild(input)
    setTimeout(() => input.click(), 0)

    // 7. input 정리 함수
    function cleanup() {
      input.removeEventListener('change', () => {})
      if (input.parentElement) {
        document.body.removeChild(input)
      }
    }
  })

export default useGetFiles
