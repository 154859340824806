<script setup lang="ts">
import { ROUTES } from '@configs'
import { bridgeToApp } from '@bridge'

const appStore = useAppStore()
const config = useRuntimeConfig()
const layoutStore = useLayoutStore()
const { mainBgWhite, bottomTabBarRect } = storeToRefs(layoutStore)
const userStore = useUserStore()
const { gtSet } = useGoogleTag()
const rootRef = ref<Element | null>(null)
const { receiver } = usePostMessage()
const showDevTools = computed(() => config.public.MODE !== 'production')
const { show: modalShow } = useModal()
const { localStorageItems, get: getLocalStorage } = useLocalStorage()
const { cookieNames, getCookie, deleteCookie } = useCookies()
const checkRoute = computed(() => {
  const routeName = useGetRouteName()
  return !(
    routeName.includes(ROUTES.CREATOR.name) ||
    routeName.includes(ROUTES.CALLBACK_SIGNIN.name) ||
    routeName.includes(ROUTES.CALLBACK_SIGNUP.name) ||
    routeName.includes(ROUTES.CALLBACK_PAY.name) ||
    routeName.includes(ROUTES.REFERRAL.name)
  )
})

console.log('MODE:', config.public.MODE)
console.log('API_URL:', config.public.API_URL)
console.log('APP_NAME:', config.public.APP_NAME)

watch(
  () => mainBgWhite.value,
  (cur) => {
    const body = document.body
    if (body) {
      body.classList[cur ? 'add' : 'remove']('bg-white')
    }
  }
)

watch(
  () => layoutStore.modals.length,
  (cur) => {
    if (appStore.isApp) {
      setPullToRefresh(cur === 0)
    }
  }
)
// web to app pull to refresh control
const setPullToRefresh = (type: boolean) => {
  if (type) bridgeToApp.setPullToRefreshEnabled('true')
}

// Init AuthGate Listener
const initAuthGateListener = () => {
  const routeName = useGetRouteName()
  const disabledRouteNameList = [
    ROUTES.CALLBACK_SIGNUP.name,
    ROUTES.CALLBACK_SIGNIN.name,
    ROUTES.CALLBACK_PAY.name,
  ]
  if (!disabledRouteNameList.some((item) => routeName.includes(item))) {
    window.addEventListener('message', receiver)
  }
}

// Init BlockRouteBack
const initBlockRouteBack = () => {
  window.popStateDetected = false
  window.addEventListener('popstate', () => {
    window.popStateDetected = true
  })
}

// Init Device
const initDevice = () => {
  const isMobile = useCheckMobile()
  document.documentElement.classList.add(isMobile ? 'mobile' : 'desktop')
}

// GA config 세팅
const setGaUserInformation = () => {
  const data = userStore.userCommonInfo
    ? {
        userSn: userStore.userCommonInfo.userSn,
        userAge: userStore.userCommonInfo.birth
          ? useAgeCalculate(userStore.userCommonInfo.birth)
          : null,
        userGender: userStore.userCommonInfo.gender,
      }
    : {
        userSn: null,
        userAge: null,
        userGender: null,
      }

  gtSet('user_properties', data)
}
const onBoardingCarousel = async () => {
  const apps = ['AOS', 'IOS']
  const appType = getCookie<string>(cookieNames.APP_TYPE)
  const isShowOnBoardingOnce = getCookie<boolean>(
    cookieNames.IS_SHOW_ON_BOARDING_ONCE
  )
  const isAppCheck = !!(appType && apps.includes(appType))

  if (isShowOnBoardingOnce) {
    deleteCookie(cookieNames.IS_SHOW_ON_BOARDING_ONCE)
    return
  }

  if (isAppCheck && checkRoute.value) {
    try {
      const data = await getLocalStorage<
        typeof localStorageItems.ON_BOARDING.key,
        boolean
      >(localStorageItems.ON_BOARDING.key)

      if (data) {
        modalShow(modalsName.MODAL_ON_BOARDING_CAROUSEL)
      }
    } catch (err) {
      modalShow(modalsName.MODAL_ON_BOARDING_CAROUSEL)
    }
  }
}

onMounted(() => {
  initAuthGateListener()
  initBlockRouteBack()
  initDevice()
  setGaUserInformation()
  onBoardingCarousel()
})
</script>

<template>
  <div id="root" ref="rootRef">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <!-- 전역 컴퍼넌트 -->
    <NovaLoadingIndicatorGlobal />
    <NovaFloating
      :bottom-position="
        layoutStore.responsive === 'mobile' ? bottomTabBarRect?.height || 0 : 0
      "
    />

    <!-- 전역 컨테이너 -->
    <NovaContainerGlobalModals />
    <NovaContainerNaverAnalytics />
    <NovaContainerKakaoSdk />
    <NovaContainerPremiumExpiredNotification />
    <NovaContainerBridgeAndFirebase />
    <NovaContainerShowModal />
    <NovaContainerLayerControl />
    <NovaDevTools v-if="showDevTools" />
    <!--
    2024-08-21-채팅, 라이브 진입 삭제
    <ClientOnly>
      <NovaChatButton v-if="showChatButton" :mode="ChatButtonMode.FLOATING" />
    </ClientOnly>-->
    <!--  <NovaContainerNaverSearchAdvisor />-->
  </div>
</template>
