<script setup lang="ts">
import type { RouteParams } from 'vue-router'
import type { CreatorProfile } from '@store'
// import { Tippy } from 'vue-tippy'
// import { ChatMode } from '@sendbird'

defineOptions({
  inheritAttrs: false,
})
// const { enterChat, createChat } = useSendbirdGate()
// const { checkCreateLiveAvailable } = useSendbirdGate()
const { hide: modalHide } = useModal()
const { gtEvent } = useGoogleTag()
const { t } = useI18n()

const creatorProfile = ref<CreatorProfile | null | undefined>()
// const creatorStore = useCreatorStore()
// const userStore = useUserStore()

const show = ref(false)
const isLoading = ref(false)
// const loading = ref(false)
// 모달 오픈전 사전 작업 수행
const handleOnBeforeOpen = (evt: RouteParams) => {
  const params = evt.value as unknown as {
    creatorProfile: CreatorProfile
  }

  creatorProfile.value = params.creatorProfile
}
// const toggleLoading = (_loading: boolean) => {
//   loading.value = _loading
// }
// 팝업 닫기
const handleOnClose = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '크리에이터 소개 팝업 닫기',
    eventLabel: '',
    eventSlot: '모달',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  await modalHide(modalsName.MODAL_CREATOR_PROFILE)
}

// const handleOnLive = () => {
//   gtEvent('clickEvent', {
//     eventCategory: '클릭',
//     eventAction: 'LIVE 버튼 클릭',
//     eventLabel: creatorStore.profile?.liveOnAirAt === 'Y' ? 'On air' : 'LIVE',
//     eventSlot: '크리에이터 소개 팝업 > LIVE 버튼',
//     eventI18nAddr: '',
//     eventComponent: 'Button',
//   })
//
//   if (!userStore.user) return
//
//   if (creatorStore.profile?.liveOnAirAt === 'Y') {
//     useToast(t('sendbird.createLiveEvent.errors.alreadyOnAir'))
//     return
//   }
//
//   checkCreateLiveAvailable({
//     userId: userStore.user.sndbrdUserId!,
//     onLoading: toggleLoading,
//   })
// }

// const { profile } = storeToRefs(creatorStore)
// const isEqualUser = computed(
//   () => profile.value?.userSn === userStore.user?.userSn
// )
// const tippyRef = ref<typeof Tippy | null>(null)
// const changeLoading = (_loading: boolean) => {
//   loading.value = _loading
// }
// const hideTippy = () => {
//   tippyRef.value?.hide()
// }
// const showCondition = () => {
//   if (!userStore.isSignIn && profile.value?.channelUrl) return true
//
//   // 같은 사용자가 아니고 크리에이터가 채널을 개섫했다면 툴팁 오픈
//   if (!isEqualUser.value && profile.value?.channelUrl) return true
//
//   // 같은 사용자이며, 채널을 개설하지 않았다면 툴팁 오픈
//   return isEqualUser.value && !profile.value?.channelUrl
// }
//
// const handleOnOpenChatGate = (mode: ChatMode) => {
//   if (loading.value) return
//
//   if (
//     profile.value?.userSn === userStore.user?.userSn &&
//     !userStore.user?.channelUrl
//   )
//     return
//   gtEvent('clickEvent', {
//     eventCategory: '클릭',
//     eventAction: '채팅 버튼 클릭',
//     eventLabel: 'chat',
//     eventSlot: '유저가 크리에이터 홈 채팅 아이콘 클릭',
//     eventI18nAddr: '',
//     eventComponent: 'Button',
//   })
//   switch (mode) {
//     case ChatMode.CHANNEL:
//       if (!profile.value?.channelUrl) {
//         useToast(t('sendbird.enterChatChannel.errors.createChannelNotYet'))
//       } else {
//         enterChat({
//           mode,
//           channelUrl: profile.value?.channelUrl,
//           onLoading: changeLoading,
//         })
//       }
//       break
//     case ChatMode.CHANNEL_LIST:
//       enterChat({
//         mode,
//         onLoading: changeLoading,
//       })
//       break
//     default:
//       break
//   }
// }
// const handleOnCreateChat = () => {
//   gtEvent('clickEvent', {
//     eventCategory: '클릭',
//     eventAction: '팬 채팅방 개설',
//     eventLabel: t('sendbird.createChatChannel.actions.create'),
//     eventSlot: '크리에이터 홈 > 팬 채팅방 개설하기',
//     eventI18nAddr: useKoreanTranslation(
//       'sendbird.createChatChannel.actions.create'
//     ),
//     eventComponent: 'Button',
//   })
//   if (loading.value) return
//   createChat({ mode: 'showCreateChannelModal', onLoading: changeLoading })
//   hideTippy()
// }

// const handleOnEnterChat = (mode: ChatMode) => {
//   gtEvent('clickEvent', {
//     eventCategory: '클릭',
//     eventAction: '채팅 목록',
//     eventLabel: t('sendbird.enterChatChannel.actions.enter'),
//     eventSlot: '크리에이터 홈 > 채팅 목록',
//     eventI18nAddr: useKoreanTranslation(
//       'sendbird.enterChatChannel.actions.enter'
//     ),
//     eventComponent: 'Button',
//   })
//   if (loading.value) return
//   switch (mode) {
//     case ChatMode.CHANNEL:
//       if (!profile.value?.channelUrl) {
//         useToast(t('sendbird.enterChatChannel.errors.createChannelNotYet'))
//       } else {
//         enterChat({
//           mode,
//           channelUrl: profile.value?.channelUrl,
//           onLoading: changeLoading,
//         })
//       }
//       break
//     case ChatMode.CHANNEL_LIST:
//       enterChat({
//         mode,
//         onLoading: changeLoading,
//       })
//       break
//     default:
//       break
//   }
// }
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_CREATOR_PROFILE"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnBeforeOpen(evt.ref.params)"
    @click-outside="handleOnClose"
  >
    <ClientOnly>
      <NovaLoadingIndicator
        v-if="isLoading"
        :fill="true"
        :bg-bright="'dark'"
        class="async-status-message"
      />

      <NovaBoxBase
        v-if="!isLoading && show"
        class="modal-box creator-profile-modal-wrap"
      >
        <div class="modal-header">
          <h4 class="title">
            {{ t('accountPanel.settings.creatorIntro.title') }}
          </h4>

          <NovaButtonIcon
            :icon="{ type: 'outline', name: 'close-extend' }"
            :theme="'transparent'"
            :size="20"
            class="btn-close"
            @click="handleOnClose"
          />
        </div>

        <NovaLayoutScrollContainer class="modal-body">
          <NovaBoxCreatorProfile
            :profile="creatorProfile"
            :is-creator-profile="true"
          />
          <div class="actions">
            <NovaBoxSocialLinks
              :socials="creatorProfile?.snslinks || []"
              :edit-mode="false"
              :theme="'bar'"
            />
            <NovaBoxEmptyMessage
              v-if="!(creatorProfile?.snslinks || []).length"
              :message="$t('creatorMyPage.home.linkEmpty')"
            />
            <!--            <hr class="hr" />-->
            <!--            <div class="box-live">-->
            <!--              <button-->
            <!--                :class="[-->
            <!--                  'live-button',-->
            <!--                  { on: creatorStore.profile?.liveOnAirAt === 'Y' },-->
            <!--                ]"-->
            <!--                type="button"-->
            <!--                @click="handleOnLive"-->
            <!--              >-->
            <!--                <div class="button-item">-->
            <!--                  <div class="icon-wrap">-->
            <!--                    <NovaIcon-->
            <!--                      class="icon"-->
            <!--                      :icon="{ type: 'outline', name: 'stream-line' }"-->
            <!--                    />-->
            <!--                  </div>-->
            <!--                  <NovaLoadingIndicator-->
            <!--                    v-if="loading"-->
            <!--                    :bg-bright="'dark'"-->
            <!--                    :fill="true"-->
            <!--                  />-->
            <!--                  <span class="label">LIVE</span>-->
            <!--                </div>-->
            <!--              </button>-->
            <!--              <button class="chat-button" type="button">-->
            <!--                <Tippy-->
            <!--                  ref="tippyRef"-->
            <!--                  class="button-item"-->
            <!--                  :append-to="'parent'"-->
            <!--                  :interactive="true"-->
            <!--                  :theme="'chat-gate'"-->
            <!--                  :placement="'bottom'"-->
            <!--                  :on-show="showCondition"-->
            <!--                  :tag="'button'"-->
            <!--                  :role="'button'"-->
            <!--                  :trigger="'click'"-->
            <!--                  @click.stop="() => handleOnOpenChatGate(ChatMode.CHANNEL)"-->
            <!--                >-->
            <!--                  <div class="icon-wrap">-->
            <!--                    <NovaIcon-->
            <!--                      class="icon"-->
            <!--                      :icon="{ type: 'outline', name: 'chat' }"-->
            <!--                    />-->
            <!--                  </div>-->
            <!--                  <span class="label">CHAT</span>-->
            <!--                  <template #content>-->
            <!--                    <div-->
            <!--                      v-if="!isEqualUser && profile?.channelUrl"-->
            <!--                      class="chat-gate"-->
            <!--                    >-->
            <!--                      {{ t('sendbird.enterChatChannel.enterCreatorChannel') }}-->
            <!--                    </div>-->

            <!--                    <div-->
            <!--                      v-if="isEqualUser && !profile?.channelUrl"-->
            <!--                      class="chat-gate"-->
            <!--                    >-->
            <!--                      <button-->
            <!--                        type="button"-->
            <!--                        class="chat-gate-item"-->
            <!--                        @click.stop="handleOnCreateChat"-->
            <!--                      >-->
            <!--                        <span class="label">-->
            <!--                          {{ t('sendbird.createChatChannel.actions.create') }}-->
            <!--                        </span>-->
            <!--                        <NovaIcon-->
            <!--                          :icon="{-->
            <!--                            type: 'outline',-->
            <!--                            name: 'chev-compact-right',-->
            <!--                          }"-->
            <!--                          class="chev"-->
            <!--                        />-->
            <!--                      </button>-->
            <!--                      <button-->
            <!--                        type="button"-->
            <!--                        class="chat-gate-item"-->
            <!--                        @click.stop="-->
            <!--                          () => handleOnEnterChat(ChatMode.CHANNEL_LIST)-->
            <!--                        "-->
            <!--                      >-->
            <!--                        <span class="label">-->
            <!--                          {{ t('sendbird.enterChatChannel.actions.enter') }}-->
            <!--                        </span>-->
            <!--                        <NovaIcon-->
            <!--                          :icon="{-->
            <!--                            type: 'outline',-->
            <!--                            name: 'chev-compact-right',-->
            <!--                          }"-->
            <!--                          class="chev"-->
            <!--                        />-->
            <!--                      </button>-->
            <!--                    </div>-->
            <!--                  </template>-->
            <!--                </Tippy>-->
            <!--              </button>-->
            <!--            </div>-->
          </div>
        </NovaLayoutScrollContainer>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 66px 32px 32px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  .modal-box {
    display: flex;
    width: 100%;
    padding: 0;
    max-width: 670px;
    max-height: 100%;
    border-radius: 20px !important;
    pointer-events: auto;
    background-color: $color-bg-2;
    overflow: hidden;
    .modal-header {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f0f0f0;
      background-color: $color-bg-3;
      .title {
        @include text-style($text-heading3-bold);
      }
    }
  }
  .actions {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px 20px 20px 20px;
    @include mobile {
      padding: 0 20px 80px 20px;
    }
    .box-live {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .live-button,
      .chat-button {
        display: flex;
        align-items: center;
        gap: 16px;
        overflow: hidden;
        word-break: break-all;
        width: 100%;
        height: 50px;
        padding: 9px 6px 9px 6px;
        background-color: $color-white;
        box-shadow: 0px 2px 4px 0px #00000014;
        border-radius: 5px;
        &.on {
          .icon-wrap {
            background-color: $color-primary-green;
          }
        }
        .button-item {
          position: relative;
          width: 100%;
          display: flex;
          align-items: center;
          gap: 16px;
        }
        .icon-wrap {
          position: absolute;
          left: 0;
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 32px;
          height: 32px;
          background-color: $color-black;
          border-radius: 50%;
          i:before {
            color: $color-white;
          }
        }
        .label {
          flex-grow: 1;
          color: $color-black;
          @include text-style($text-body-14-regular);
          @include ellipsis(1);
          padding: 0 40px;
        }
        &.loading {
          pointer-events: none;

          > .label,
          > .icon {
            opacity: 0.4;
          }
        }
      }
    }
  }
}
.hr {
  width: calc(100% + 40px);
  height: 4px;
  margin-left: -20px;
  background-color: $color-gray-1;
  border: none;
}
@include mobile {
  :deep(.modal-content) {
    width: 100%;
    height: 100%;
    padding: 0;

    .modal-box {
      width: 100%;
      max-width: 100%;
      height: 100%;
      padding: 0;
      border-radius: 0 !important;

      .modal-header {
        flex-shrink: 0;
        height: 56px;
        padding: 0 20px 20px 20px;

        .on-mobile {
          display: flex;
        }

        .on-desktop {
          .btn-close {
            display: none;
          }
        }
      }

      .modal-body {
        border-radius: 0;
        gap: 20px;
      }
    }
  }
}

.chat-button-wrap {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &.loading {
    > .inner .button-wrap .chat-icon {
      opacity: 0.3;
    }
  }

  > .inner {
    .button-wrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 48px;
      height: 48px;
      background-color: $color-text-4;
      border-radius: 8px;

      .chat-icon {
        font-size: 32px !important;
        color: $color-white;
      }

      .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.chat-gate {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .chat-gate-item {
    align-items: center;
    display: flex;
    gap: 20px;
    height: 30px;
    justify-content: space-between;
    white-space: nowrap;
    transition: font-weight 0.12s ease-in-out;
    .label {
      color: #767676;
      font-size: 14px;
      text-align: left !important;
      font-weight: 500;
      letter-spacing: -0.55px;
      line-height: 22px;
      padding: 0 !important;
    }
  }
}
</style>
