<script setup lang="ts">
import type { NovaBoxAccountSectionProps } from './NovaBoxAccountSection.types'

withDefaults(defineProps<NovaBoxAccountSectionProps>(), {
  guide: '',
})

const slots = useSlots()
</script>

<template>
  <div class="account-section">
    <div class="header">
      <div class="title-bar">
        <h4 class="title">{{ title }}</h4>

        <div v-if="slots['tools']" class="tools">
          <slot name="tools" />
        </div>
      </div>

      <p v-if="guide" class="guide">{{ guide }}</p>
    </div>

    <div v-if="slots['contents']" class="contents">
      <slot name="contents" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.account-section {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  background-color: $color-bg-3;

  .header {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .title-bar {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;

      .title {
        flex-grow: 1;
        @include text-style($text-body-14-bold);
        color: $color-text-1;
      }

      .tools {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

    .guide {
      @include text-style($text-body-12-regular);
      color: $color-text-2;
    }
  }

  .contents {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
</style>
