<script setup lang="ts">
import type { MenuItemProps } from '@components/NovaHeaderMenuItem/NovaHeaderMenuItem.types'

withDefaults(defineProps<MenuItemProps>(), {
  handleClickEvent: undefined,
})
const slots = useSlots()
</script>

<template>
  <button type="button" class="menu-item">
    <div class="left">
      <slot name="prefix" />
      <span class="label">{{ label }}</span>
    </div>
    <NovaIcon
      v-if="!slots['extra']"
      :icon="{ type: 'outline', name: 'chev-thin-right' }"
    />
    <slot name="extra" />
  </button>
</template>

<style lang="scss" scoped>
.menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  padding: 6px 0;

  .left {
    display: flex;
    align-items: center;
    gap: 4px;

    > .label {
      @include text-style($text-body-14-regular);
      line-height: 20px;
      color: $color-text-2;
      text-align: left;
      @include ellipsis(2);
      word-break: keep-all;
    }
  }
}
</style>
