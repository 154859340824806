<script setup lang="ts">
import { v4 } from 'uuid'
import { ROUTES } from '@configs'

const { t } = useI18n()
const userStore = useUserStore()
const { user } = storeToRefs(userStore)
const isPortraitLoadSuccess = ref(false)
const { gtEvent } = useGoogleTag()
const { openMembershipServicePopup } = useMembershipProcess()

const handleOnClickPortrait = () => {
  // 로그인: 각 사용자 홈 이동
  if (user.value) {
    switch (user.value.cmtyUserSeCode) {
      case 'C':
        gtEvent('clickEvent', {
          eventCategory: '클릭',
          eventAction: '크리에이터 홈 이동 클릭',
          eventLabel: t('linkCreatorHome'),
          eventSlot: '유저 패널',
          eventI18nAddr: useKoreanTranslation('linkCreatorHome'),
          eventComponent: 'Button',
          creatorLink: user.value.creatorLink,
        })
        useNavigations({
          url: useRoutePathIdChange(ROUTES.CREATOR.path, {
            id: `@${user.value.creatorLink}`,
          }),
        })
        break
      default:
        gtEvent('clickEvent', {
          eventCategory: '클릭',
          eventAction: '유저 홈 이동 클릭',
          eventLabel: t('linkMyHome'),
          eventSlot: '유저 패널',
          eventI18nAddr: useKoreanTranslation('linkMyHome'),
          eventComponent: 'Button',
          userNcnm: user.value!.userNcnm,
        })
        useNavigations({
          url: useRoutePathIdChange(ROUTES.USER.path, {
            id: `@${user.value.userNcnm}`,
          }),
        })
        break
    }
  }

  // 비로그인: 로그인 유도
  else {
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '로그인 버튼',
      eventLabel: t('sign.in'),
      eventSlot: 'NovaBottomTabBar',
      eventI18nAddr: useKoreanTranslation('sign.in'),
      eventComponent: 'Button',
    })
    openMembershipServicePopup.signin()
  }
}

const handleOnPortraitLoadSuccess = () => {
  isPortraitLoadSuccess.value = true
}
</script>

<template>
  <button
    type="button"
    :class="[
      'nova-header-tools-user',
      { 'portrait-loaded': isPortraitLoadSuccess },
    ]"
  >
    <ClientOnly>
      <NovaImageContainer
        :key="user?.userProflUrl || v4()"
        :image-url="user?.userProflUrl"
        :ratio="'1:1'"
        :empty-type="'user-circle'"
        class="user-portrait toggle-panel-button"
        toggle-panel="userPanel"
        @on-img-load="handleOnPortraitLoadSuccess"
        @click.stop="handleOnClickPortrait"
      />
    </ClientOnly>
  </button>
</template>

<style scoped lang="scss">
.nova-header-tools-user {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  overflow: hidden;
  text-align: left;

  &:before {
    position: absolute;
    content: '\e9a0';
    color: $color-text-4;
    font-family: 'lmnova';
    font-size: 26px !important;

    @include mobile {
      font-size: 22px !important;
    }
  }

  &.portrait-loaded:before {
    display: none;
  }

  .user-portrait {
    color: $color-text-4;
    &::after {
      font-size: 26px !important;

      @include mobile {
        font-size: 22px !important;
      }
    }
  }

  .icon {
    color: $color-text-4;
    font-size: 26px !important;

    @include mobile {
      font-size: 22px !important;
    }
  }
}
</style>
