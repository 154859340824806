import * as MyPageTypes from './type'
import MyPageApi from './url'
import type { PostsItem } from '@store/post/type'
import { api } from '@api'
export default {
  /**
   * 프로필 정보 조회
   */
  fetchProfile() {
    return api.get<MyPageTypes.Profile>(MyPageApi.FETCH_PROFILE)
  },

  /**
   * 프로필 닉네임/소개 변경
   * @param payload
   */
  editProfile(payload: MyPageTypes.ProfilePayload) {
    return api.put<{}>(MyPageApi.EDIT_PROFILE, payload)
  },

  /**
   * 프로필 사진 등록 및 수정
   * @param payload
   */
  createProfilePhoto(payload: FormData) {
    return api.post<string>(MyPageApi.CREATE_PROFILE_PHOTO, payload)
  },

  /**
   * 프로필 사진 삭제
   */
  deleteProfilePhoto() {
    return api.delete(MyPageApi.DELETE_PROFILE_PHOTO)
  },

  /**
   * 내 포스트 목록 조회
   * @param payload
   */
  fetchPosts(payload: MyPageTypes.UserPostsPayload) {
    return api.get<PostsItem[]>(MyPageApi.FETCH_POSTS, {
      params: payload,
    })
  },

  /**
   * 내 게시물 개수 조회
   */
  fetchPostCount(payload: MyPageTypes.PostCountPayload) {
    return api.get<MyPageTypes.PostsCount>(MyPageApi.FETCH_POST_COUNT, {
      params: payload,
    })
  },

  /**
   * 내가 받은 좋아요 수 조회
   */
  fetchRecommendCount() {
    return api.get<MyPageTypes.RecommendCount>(MyPageApi.FETCH_RECOMMEND_COUNT)
  },

  /**
   * 좋아요 받은 리스트 목록 조회
   * @param payload
   */
  fetchGetRecommendPosts(payload: MyPageTypes.GetRecommendsPayload) {
    return api.get<MyPageTypes.GetRecommendItem[]>(
      MyPageApi.FETCH_RECEIVED_RECOMMEND_POSTS,
      { params: payload }
    )
  },

  /**
   * 좋아요 한 리스트 목록 조회
   * @param payload
   */
  fetchSetRecommendPosts(payload: MyPageTypes.SetRecommendsPayload) {
    return api.get<MyPageTypes.SetRecommendItem[]>(
      MyPageApi.FETCH_RECOMMEND_POSTS,
      { params: payload }
    )
  },

  /**
   * 내 스크랩 게시물 리스트 목록 조회
   * @param payload
   */
  fetchScrapPosts(payload: MyPageTypes.ScrapPostsPayload) {
    return api.get<MyPageTypes.ScrapPostItem[]>(MyPageApi.FETCH_SCRAP_POSTS, {
      params: payload,
    })
  },

  /**
   * 내 스크랩 게시물 개수 조회
   */
  fetchScrapCount() {
    return api.get<number>(MyPageApi.FETCH_SCRAP_COUNT)
  },

  /**
   * 내 댓글 게시물 목록 조회
   * @param payload
   */
  fetchCommentPosts(payload: MyPageTypes.MyCommentsPayload) {
    return api.get<MyPageTypes.MyCommentItem[]>(MyPageApi.FETCH_COMMENT_POSTS, {
      params: payload,
    })
  },

  /**
   * 내 댓글 개수 조회
   */
  fetchCommentCount() {
    return api.get<number>(MyPageApi.FETCH_COMMENT_COUNT)
  },

  /**
   * 내 보상 리스트 조회
   * @param payload
   */
  fetchRewards(payload: MyPageTypes.RewardsPayload) {
    return api.get<MyPageTypes.RewardItem[]>(MyPageApi.FETCH_REWARDS, {
      params: payload,
    })
  },

  /**
   * 내 보상 상세내역 조회
   * @param payload
   */
  fetchRewardDetail(payload: MyPageTypes.RewardDetailPayload) {
    return api.get<MyPageTypes.RewardDetail[]>(MyPageApi.FETCH_REWARD_DETAIL, {
      params: payload,
    })
  },

  /**
   * 내 보상 개수 조회
   * @param payload
   */
  fetchRewardCount(payload: MyPageTypes.RewardCountPayload) {
    return api.get<MyPageTypes.RewardCount>(MyPageApi.FETCH_REWARD_COUNT, {
      params: payload,
    })
  },

  /**
   * 레퍼럴 코드 등록
   * @param payload
   */
  regReferralCode(payload: MyPageTypes.RegReferralCodePayload) {
    return api.post<{}>(MyPageApi.REG_REFERRAL_CODE, payload)
  },

  /**
   * 후원 금액 조회
   */
  fetchDonationQty() {
    return api.get<MyPageTypes.DonationQty>(MyPageApi.FETCH_DONATION_QTY)
  },

  /**
   * 후원 내역(received) 조회
   * @param payload
   */
  fetchReceivedDonations(payload: MyPageTypes.DonationsPayload) {
    return api.get<MyPageTypes.Donation[]>(MyPageApi.FETCH_RECEIVED_DONATIONS, {
      params: payload,
    })
  },

  /**
   * 후원 내역(sent) 조회
   * @param payload
   */
  fetchSentDonations(payload: MyPageTypes.DonationsPayload) {
    return api.get<MyPageTypes.Donation[]>(MyPageApi.FETCH_SENT_DONATIONS, {
      params: payload,
    })
  },

  /**
   * 마이홈 프로필 조회
   * @param payload
   */
  fetchHomeProfile(payload: MyPageTypes.HomeProfilePayload) {
    return api.get<MyPageTypes.HomeProfile>(MyPageApi.FETCH_HOME_PROFILE, {
      params: payload,
    })
  },

  /**
   * 프로필 배경이미지 및 수정
   * @param payload
   */
  regProfileBgImg(payload: FormData) {
    return api.post<string>(MyPageApi.REG_PROFILE_BGIMG, payload)
  },

  /**
   * 나의 프리미엄 가입 크리에이터 목록 조회
   */
  fetchPremiumCreators() {
    return api.get<MyPageTypes.PremiumCreator[]>(
      MyPageApi.FETCH_PREMIUM_CREATORS
    )
  },

  /**
   * 노바+ 구독(후원) 상세 내역 조회
   * @param payload
   */
  fetchSponsorDetail(payload: MyPageTypes.PremiumSponsorDetailPayload) {
    return api.get<MyPageTypes.PremiumSponsorDetail>(
      MyPageApi.FETCH_PREMIUM_SPONSOR_DETAIL,
      {
        params: payload,
      }
    )
  },

  /**
   * 노바+ 구독(후원) 목록 조회
   * @param payload
   */
  fetchPremiumSponsors(payload: MyPageTypes.PremiumSponsorsPayload) {
    return api.get<MyPageTypes.PremiumSponsorRes>(
      MyPageApi.FETCH_PREMIUM_SPONSORS,
      {
        params: payload,
      }
    )
  },

  /**
   * 프리미엄 구독(후원) 만료 목록 조회
   */
  fetchPremiumExpiredList() {
    return api.get<MyPageTypes.PremiumExpiredItem[]>(
      MyPageApi.FETCH_PREMIUM_EXPIRED_LIST
    )
  },
  /**
   * 팔로워 삭제
   * @param payload
   */
  deleteMyFollower(payload: MyPageTypes.DeleteFollowerPayload) {
    return api.delete(MyPageApi.DELETE_MY_FOLLOWER, {
      data: payload,
    })
  },

  /**
   * 팔로워 목록 조회
   */
  fetchMyFollowers(payload: MyPageTypes.MyFollowPayload) {
    return api.get<MyPageTypes.MyFollowers[]>(MyPageApi.FETCH_MY_FOLLOWERS, {
      params: payload,
    })
  },
  /**
   * 팔로잉 목록 조회
   */
  fetchMyFollowings(payload: MyPageTypes.MyFollowPayload) {
    return api.get<MyPageTypes.MyFollowings[]>(MyPageApi.FETCH_MY_FOLLOWINGS, {
      params: payload,
    })
  },
  /**
   * 사용자 차단 목록 조회
   */
  fetchMyBlocks(payload: MyPageTypes.MyBlockPayload) {
    return api.get<MyPageTypes.MyBlocks[]>(MyPageApi.FETCH_MY_BLOCKS, {
      params: payload,
    })
  },
  /**
   * 마이 컬렉션 조회
   */
  fetchMyCollections(payload: MyPageTypes.MyCollectionsPayload) {
    return api.get<MyPageTypes.MyCollections[]>(
      MyPageApi.FETCH_MY_COLLECTIONS,
      {
        params: payload,
      }
    )
  },
  /**
   * 마이 컬렉션 상세 > NFT 조회
   */
  fetchMyCollectionNft(payload: MyPageTypes.MyCollectionNftPayload) {
    return api.get<MyPageTypes.MyCollectionNft>(
      MyPageApi.FETCH_MY_COLLECTION_NFT,
      {
        params: payload,
      }
    )
  },
  /**
   * 마이 컬렉션 상세 > NFT 조회 > 랜덤 박스 리빌
   */
  reqRandomBoxReveal(payload: MyPageTypes.NftRevealPayload) {
    const url = `${MyPageApi.REQ_RANDOM_BOX_REVEAL}/${payload.randboxSn}`
    return api.post<MyPageTypes.MyCollectionNft>(url, {})
  },
}
