<script setup lang="ts">
import { AxiosError } from 'axios'
import type { NovaBoxFollowUserProps } from './NovaBoxFollowUser.types'
import { BlockingTarget, useCreatorStore } from '@store'
import { BlockType } from '@composables/useBlockStatus'

const { gtEvent } = useGoogleTag()
const props = withDefaults(defineProps<NovaBoxFollowUserProps>(), {
  popoverAppendTarget: null,
  showToggleFollowBtn: true,
  showBlockBtn: true,
  showMoreBtn: false,
  cmtyUserSeCode: null,
})
const { t } = useI18n()
const isLoading = ref(false)
const { hide: modalHide } = useModal()
const { userStore, showSignInDialog } = useMembershipProcess()
const { showFollowErrorMessage, showUnfollowErrorMessage } = useFollow()
const commStore = useCommStore()
const mypageStore = useMyPageStore()
const creatorStore = useCreatorStore()
const route = useRoute()
const { generateReCaptchaToken } = useReCaptcha()
// 토글 팔로우
const handleOnToggleFollow = async () => {
  if (!userStore.isSignIn) {
    await showSignInDialog()
    return
  }

  const currentPageId = String(route.params.id).replace(/^@/, '')
  const followFlag = props.source.followFlag
  const isSamePage =
    currentPageId === mypageStore.profile?.creatorLink ||
    currentPageId === mypageStore.profile?.userNcnm

  const updateFollowingCount = (increment: number) => {
    if (userStore.user?.cmtyUserSeCode === 'G') {
      mypageStore.homeProfile!.followingCount += increment
    } else if (userStore.user?.cmtyUserSeCode === 'C') {
      creatorStore.profile!.followingCount += increment
    }
  }

  if (followFlag === '0') {
    if (isSamePage) {
      updateFollowingCount(1)
    }
    await handleOnFollow()
  } else if (followFlag === '1') {
    if (isSamePage) {
      updateFollowingCount(-1)
    }
    await handleOnUnFollow()
  }
}

// 차단 해제
const handleOnUnBlock = () => {
  const params = {
    target: BlockingTarget.CREATOR,
    type: BlockType.UNBLOCKING,
    userSn: props.source.userSn,
    userNcnm: props.source.userNcnm,
  }

  callBlockDialog(params)
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '차단 해제',
    eventLabel: t('mypage.myFollow.button.cancel'),
    eventSlot: '팔로워 모달 > 차단 목록',
    eventI18nAddr: useKoreanTranslation('mypage.myFollow.button.cancel'),
    eventComponent: 'Button',
    target: BlockingTarget.CREATOR,
    type: BlockType.UNBLOCKING,
    userSn: props.source.userSn,
    userNcnm: props.source.userNcnm,
  })
}

// 팔로우
const handleOnFollow = async () => {
  try {
    isLoading.value = true
    const recaptchaToken = await generateReCaptchaToken('FOLLOW')
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '팔로우 버튼 클릭',
      eventLabel: t('mypage.myFollow.button.follow'),
      eventSlot: '팔로우/팔로잉 모달 창 > 팔로우, 팔로잉',
      eventI18nAddr: useKoreanTranslation('mypage.myFollow.button.follow'),
      eventComponent: 'Button',
      flwUserSn: props.source.userSn,
    })
    await Promise.all([
      await useSleeper(),
      await commStore.reqFollow({
        flwUserSn: props.source.userSn,
        recaptchaToken,
      }),
    ])
    await gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '팔로우',
      eventLabel: t('mypage.myFollow.button.follow'),
      eventSlot: '팔로우/팔로잉 모달 창 > 팔로우, 팔로잉',
      eventI18nAddr: useKoreanTranslation('mypage.myFollow.button.follow'),
      eventComponent: 'Button',
      flwUserSn: props.source.userSn,
    })
  } catch (err) {
    if (err instanceof AxiosError) {
      showFollowErrorMessage(err, props.source.userNcnm)
    }
  } finally {
    isLoading.value = false
  }
}

// 팔로잉 취소
const handleOnUnFollow = async () => {
  try {
    isLoading.value = true
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '언팔로우 버튼 클릭',
      eventLabel: t('mypage.myFollow.button.follow'),
      eventSlot: '팔로우/팔로잉 모달 창 > 팔로우, 팔로잉',
      eventI18nAddr: useKoreanTranslation('mypage.myFollow.button.follow'),
      eventComponent: 'Button',
      flwUserSn: props.source.userSn,
    })
    await Promise.all([
      await useSleeper(),
      await commStore.reqUnfollow({ flwUserSn: props.source.userSn }),
    ])
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '언팔로우',
      eventLabel: t('mypage.myFollow.button.following'),
      eventSlot: '팔로우/팔로잉 모달 창 > 팔로우, 팔로잉',
      eventI18nAddr: useKoreanTranslation('mypage.myFollow.button.following'),
      eventComponent: 'Button',
      unFlwUserSn: props.source.userSn,
    })
  } catch (err) {
    if (err instanceof AxiosError) {
      showUnfollowErrorMessage(err, props.source.userNcnm)
    }
  } finally {
    isLoading.value = false
  }
}
/**
 * 사용자 홈 가기
 * */
const handleOnGoUserHomePage = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '유저 홈 이동',
    eventLabel: props.source.userNcnm,
    eventSlot: '팔로우/팔로잉 모달 창 > 유저',
    eventI18nAddr: '',
    eventComponent: 'Button',
    userSn: props.source.userSn,
  })
  await useGoUserHome(
    props.source.userSn,
    () => {
      if (props.viewType === 'follower') {
        modalHide(modalsName.MODAL_MY_FOLLOWERS)
      }
      if (props.viewType === 'following') {
        modalHide(modalsName.MODAL_MY_FOLLOWINGS)
      }
    },
    () => {
      useToast(t('mypage.myFollow.message.unfollow.error.unknown'))
    }
  )
}
</script>

<template>
  <article class="follow-item">
    <a class="user-box" @click="handleOnGoUserHomePage">
      <div class="user-info">
        <NovaPortraitContainer :image-url="source.userProflUrl" :size="'sm'" />
        <div class="more-info">
          <div class="user-name">
            <p class="name">
              {{ source.userNcnm }}
            </p>
            <span v-if="source.followBackFlag === '1'" :class="['backFlag']">
              {{ $t('mypage.myFollow.following.backFlag') }}
            </span>
          </div>
          <p class="user-dc">
            {{ source.proflDc }}
          </p>
        </div>
      </div>
    </a>
    <div class="follow-box">
      <NovaButtonText
        v-if="showToggleFollowBtn"
        :on="source.followFlag === '1'"
        :label="
          $t(
            source.followFlag === '0'
              ? 'mypage.myFollow.button.follow'
              : 'mypage.myFollow.button.following'
          )
        "
        :theme="'sky-blue'"
        :width="90"
        :loading="isLoading"
        @click.stop="handleOnToggleFollow"
      />
      <NovaButtonText
        v-if="showBlockBtn"
        :label="$t('mypage.myFollow.button.cancel')"
        :theme="'sky-blue'"
        :loading="isLoading"
        :width="90"
        @click.stop="handleOnUnBlock"
      />
      <ClientOnly>
        <!--        viewType === 'follower' &&-->
        <!--        userStore.user?.userSn === creatorStore.profile?.userSn-->
        <NovaBoxFollowActionPrivate
          v-if="showMoreBtn"
          :source="source"
          :popover-append-target="popoverAppendTarget"
        />
      </ClientOnly>
    </div>
  </article>
</template>

<style lang="scss" scoped>
.follow-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  .user-box {
    display: block;
    width: auto;
    max-width: calc(100% - 120px);
    cursor: pointer;
    @include mobile {
      max-width: calc(100% - 100px);
    }
  }

  .user-info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .more-info {
      padding-left: 10px;

      .user-name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;

        .name {
          @include text-style($text-heading3-bold);
          line-height: 1.2em;
          @include ellipsis(1);
          padding-right: 10px;
        }
        .backFlag {
          display: block;
          color: #347ae2;
          font-size: 14px;
          letter-spacing: -1px;
          white-space: nowrap;
          @include mobile {
            font-size: 11px;
            letter-spacing: -1px;
          }
        }
      }

      .user-dc {
        @include text-style($text-body-14-medium);
        @include ellipsis(1);
      }
    }
  }

  .follow-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0 10px;
  }

  @include mobile {
    width: 100%;
    border-top: none;
  }
}
</style>
