<script setup lang="ts">
import type { NovaBoxCreatorRewardWeeklyListItemProps } from './NovaBoxCreatorRewardWeeklyListItem.types'

const props = defineProps<NovaBoxCreatorRewardWeeklyListItemProps>()
const { t, locale } = useI18n()
const rewardLabel = computed(() => {
  switch (props.source.svcActGroupClCode) {
    case 'RC':
      return t('mypage.reward.modal.recommend')
    case 'FW':
      return t('mypage.reward.modal.follow')
    default:
      throw new Error('reward type is not match')
  }
})
const lm = computed(() =>
  useFormatThousandsSeparators(props.source.weeklyEstimatedReward, 2)
)
</script>

<template>
  <div class="reword-item">
    <div class="contents-box">
      <div class="contents">
        <div class="icon">
          <NovaIcon
            :icon="
              props.source.svcActGroupClCode === 'RC'
                ? {
                    type: 'outline',
                    name: 'like-fill',
                  }
                : { type: 'outline', name: 'user-circle' }
            "
            :size="20"
          />
        </div>

        <div class="info-wrap">
          <p>
            {{ source.userNcnm }}
            <strong>{{ locale === 'ko' ? '님이' : '' }}</strong>
          </p>
          <p class="label">{{ rewardLabel }}</p>
          <p class="create-at">{{ source.svcActFrstRegistDt }}</p>
        </div>
      </div>

      <div class="actions">
        <div class="lm-wrap">
          <span :class="['lm', { negative: !source.weeklyEstimatedReward }]">
            {{ source.weeklyEstimatedReward ? '+' : '' }}
            {{ lm }}<span class="unit">LM</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.reword-item {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: $color-bg-3;
  border-radius: 16px;

  &.extend {
    .contents-box > .actions .extend-wrap .chev-icon {
      transform: rotate(180deg);
    }
  }

  .contents-box {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    cursor: pointer;

    > .contents {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 10px;

      > .icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        font-size: 0;
        color: $color-blue-700;
        background-color: #dbeafe;
        border-radius: 6px;
        overflow: hidden;

        &.icon-nova-plus {
          background-color: $color-white;
          border: 2px solid $color-secondary-red-light-60;

          > img {
            width: 28px;
          }
        }
      }

      .info-wrap {
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding: 4px 0;

        > .label {
          @include text-style($text-body-14-medium);
          line-height: 1;
          letter-spacing: 0;
          color: $color-text-2;
        }

        .create-at {
          @include text-style($text-body-12-regular);
          line-height: 1;
          letter-spacing: 0;
          color: $color-text-3;
        }
      }
    }

    > .actions {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      gap: 10px;

      .lm-wrap {
        display: flex;
        align-items: center;
        gap: 4px;
      }

      .lm {
        @include text-style($text-display-bold);
        line-height: 1;
        letter-spacing: 0;
        color: $color-blue-700;

        &.negative {
          color: $color-text-1;
        }

        .unit {
          font-weight: 400;
        }
      }

      .extend-wrap {
        width: 16px;
        height: 16px;
        color: $color-text-3;

        .chev-icon {
          transition: transform 0.2s ease-in-out;
        }
      }
    }
  }

  .activity-box {
    border-top: 1px solid $color-bg-1;
    margin-top: 10px;
    padding-top: 10px;

    > .inner {
      display: flex;
      flex-direction: row;
      gap: 10px;

      > .label {
        flex-shrink: 0;
        display: inline;
        @include text-style($text-body-13-medium);
        line-height: 1;
        letter-spacing: 0;
        color: $color-text-2;
      }

      > .contents {
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        //gap: 6px;
        row-gap: 6px;

        > .action {
          display: inline-flex;
          @include text-style($text-body-13-regular);
          line-height: 13px;
          letter-spacing: 0;

          &:first-of-type:before {
            display: none;
          }

          &:before {
            display: inline-block;
            content: '•';
            color: $color-text-5;
            font-size: 20px;
            line-height: 13px;
          }

          em.highlight {
            font-weight: 700;

            &.color-blue {
              color: $color-primary-blue;
            }

            &.color-red {
              color: $color-primary-red;
            }
          }
        }
      }
    }

    > .loading-indicator {
      height: 13px;
      min-height: 13px;
    }
  }
}

.tooltip-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: $color-bg-custom-11;

  &:after {
    content: '?';
    color: $color-bg-3;
    font-size: 10px;
    line-height: 1;
  }
}
</style>
