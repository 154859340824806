import env, { type Envs } from '../env'

const mode = (process.env.MODE || 'development') as Envs

interface MetaDefaultItem {
  title: string
  description: string
  keywords: string
}

/**
 * 절대 환경변수를 필요로 하는 메타 정보를 선언하지 말것
 */
export const metaDefault: { [Key: string]: MetaDefaultItem } = {
  ko: {
    title: 'I LIKE LM : Web3 크리에이터 DAO 커뮤니티',
    description:
      '창작의 열정을 보상으로, 응원의 힘을 보상으로 돌려드립니다. Web3 크리에이터 DAO 커뮤니티 l LIKE LM에서 당신의 가능성을 펼치고, 함께 성장해보세요. 지금 바로 참여하세요! 새로운 시대 새로운 크리에이터들을 기다립니다.',
    keywords:
      'I LIKE LM, 아이라이크엘엠, DAO, LM NOVA, 엘엠노바, NOVA, 노바, Web3, 웹3, creator, 크리에이터, community, 커뮤니티, 팬덤, 창작, 콘텐츠, 수익화, LM, 레저메타, LeisureMeta, 더문랩스, The Moon Labs, 가우디 아시아 퍼시픽, gaudi Asia Pacific, 더문엔터테인먼트, The Moon Entertainment, 앱테크, N잡, AI 부업, AI 그림, AI 창작, AI 플랫폼, 웹3 앱, 디지털아트, 코인으로 돈벌기, 보상형 플랫폼',
  },
  en: {
    title: 'I LIKE LM : Web3 Creator DAO Community',
    description:
      "Unlock your creative potential and join the Web3 Creator DAO Community! With I LIKE LM, you'll find the reward, support and enthusiasm you need to thrive. Embrace the new era for creators and become part of a dynamic, innovative community. Join us now and let's succeed together!",
    keywords:
      'I LIKE LM, 아이라이크엘엠, DAO, LM NOVA, 엘엠노바, NOVA, 노바, Web3, 웹3, creator, 크리에이터, community, 커뮤니티, 팬덤, 창작, 콘텐츠, 수익화, LM, 레저메타, LeisureMeta, 더문랩스, The Moon Labs, 가우디 아시아 퍼시픽, gaudi Asia Pacific, 더문엔터테인먼트, The Moon Entertainment, 앱테크, N잡, AI 부업, AI 그림, AI 창작, AI 플랫폼, 웹3 앱, 디지털아트, 코인으로 돈벌기, 보상형 플랫폼',
  },
  th: {
    title: 'I LIKE LM : Web3 Creator DAO Community',
    description:
      "Unlock your creative potential and join the Web3 Creator DAO Community! With I LIKE LM, you'll find the reward, support and enthusiasm you need to thrive. Embrace the new era for creators and become part of a dynamic, innovative community. Join us now and let's succeed together!",
    keywords:
      'I LIKE LM, 아이라이크엘엠, DAO, LM NOVA, 엘엠노바, NOVA, 노바, Web3, 웹3, creator, 크리에이터, community, 커뮤니티, 팬덤, 창작, 콘텐츠, 수익화, LM, 레저메타, LeisureMeta, 더문랩스, The Moon Labs, 가우디 아시아 퍼시픽, gaudi Asia Pacific, 더문엔터테인먼트, The Moon Entertainment, 앱테크, N잡, AI 부업, AI 그림, AI 창작, AI 플랫폼, 웹3 앱, 디지털아트, 코인으로 돈벌기, 보상형 플랫폼',
  },
  es: {
    title: 'I LIKE LM : Web3 Creator DAO Community',
    description:
      "Unlock your creative potential and join the Web3 Creator DAO Community! With I LIKE LM, you'll find the reward, support and enthusiasm you need to thrive. Embrace the new era for creators and become part of a dynamic, innovative community. Join us now and let's succeed together!",
    keywords:
      'I LIKE LM, 아이라이크엘엠, DAO, LM NOVA, 엘엠노바, NOVA, 노바, Web3, 웹3, creator, 크리에이터, community, 커뮤니티, 팬덤, 창작, 콘텐츠, 수익화, LM, 레저메타, LeisureMeta, 더문랩스, The Moon Labs, 가우디 아시아 퍼시픽, gaudi Asia Pacific, 더문엔터테인먼트, The Moon Entertainment, 앱테크, N잡, AI 부업, AI 그림, AI 창작, AI 플랫폼, 웹3 앱, 디지털아트, 코인으로 돈벌기, 보상형 플랫폼',
  },
}

/**
 * nuxt config meta
 */
export const nuxtMetas = [
  {
    name: 'viewport',
    content:
      'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
  },
  {
    name: 'description',
    content: metaDefault.ko.description,
  },
  {
    name: 'keywords',
    content: metaDefault.ko.keywords,
  },
  {
    name: 'msapplication-TileImage',
    content: `${env[mode].APP_URL}/favicon-on-light/ms-icon-144x144.png`,
  },
  {
    name: 'naver-site-verification',
    content: 'e38bf22bc2a250125f65ac91464e8163e563f107',
  },
  { property: 'og:title', content: metaDefault.ko.title },
  { property: 'og:url', content: `${env[mode].APP_URL}` },
  {
    property: 'og:description',
    content: metaDefault.ko.description,
  },
  {
    property: 'og:image',
    content: `${env[mode].APP_URL}/lmnova.png`,
  },
  { property: 'og:type', content: 'website' },
]
