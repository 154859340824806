<script setup lang="ts">
import type { PostActionProps } from './NovaBoxPostAction.types'

withDefaults(defineProps<PostActionProps>(), {
  iconSize: 20,
  displayNumber: 0,
  loading: false,
  disabled: false,
})

const actionRef = ref<HTMLButtonElement>()

const handleOnClick = () => {
  setTimeout(() => {
    actionRef.value?.blur()
  }, 200)
}
</script>

<template>
  <button
    ref="actionRef"
    type="button"
    :class="['btn-toggle', actionType, { loading }]"
    :disabled="disabled"
    @click.stop="handleOnClick"
  >
    <div class="icon-wrap">
      <span class="shade" />
      <NovaIcon :icon="icon" :size="iconSize" class="icon" />
      <NovaLoadingIndicator
        v-if="loading"
        class="loading-indicator"
        :bg-bright="'light'"
      />
    </div>

    <span v-if="displayNumber > 0" class="label">
      {{ useNumberCompact(displayNumber, 1) }}
    </span>
  </button>
</template>

<style lang="scss" scoped>
@mixin on {
  .icon-wrap {
    > .shade {
      transform: translate(-50%, -50%) scale(1);
    }

    > .icon {
      color: $color-text-2;
    }
  }
}

.btn-toggle {
  display: flex;
  gap: 8px;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      @include on;
    }
  }

  &:active,
  &.on {
    @include on;
  }

  &:disabled {
    pointer-events: none;
  }

  &.reword {
    .icon-wrap {
      > .shade {
        background-color: $color-secondary-green-light-10;
      }

      > .icon {
        color: $color-secondary-green-light-80;
      }
    }
  }

  &.loading {
    .icon-wrap {
      > .icon {
        opacity: 0.4;
      }
    }
  }

  .icon-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;

    > .shade {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 36px;
      height: 36px;
      border-radius: 8px;
      transform: translate(-50%, -50%) scale(0);
      background-color: $color-bg-1;
      @include transition(transform 0.2s);
      z-index: 1;
    }

    > .icon {
      color: $color-text-4;
      @include transition(transform 0.2s);
      z-index: 2;
    }

    > .loading-indicator {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 60px;
      height: 60px;
      transform: translate(-50%, -50%);
      z-index: 2;
      pointer-events: none;
    }
  }

  > .label {
    @include text-style($text-body-12-regular);
    color: $color-text-4;
  }

  @include mobile {
    gap: 6px;
  }
}
</style>
