import apiPath from '@store/apiPath'

export default {
  /**
   * 크리에이터 콘텐츠 목록 조회
   * @method GET
   */
  FETCH_POSTS: `${apiPath.CREATOR}/v2/posts`,

  /**
   * 프로필 정보 조회
   * @method GET
   */
  FETCH_PROFILE: `${apiPath.CREATOR}/v1/profile`,

  /**
   * 크리에이터 링크를 통한 프로필 정보 조회
   * @method GET
   */
  FETCH_PROFILE_LINK: `${apiPath.CREATOR}/v1/profile-link`,

  /**
   * 크리에이터 보상 목록 리스트 조회
   * @method GET
   */
  FETCH_REWARD_POSTS: `${apiPath.CREATOR}/v1/rewards`,

  /**
   * 크리에이터 보상 개수 조회
   * @method GET
   */
  FETCH_REWARD_COUNT: `${apiPath.CREATOR}/v1/reward/count`,

  /**
   * 내 보상 상세내역 조회
   * @method GET
   */
  FETCH_REWARD_DETAIL: `${apiPath.CREATOR}/v1/reward`,

  /**
   * 총 보상량, 주간 예상 보상량 조회
   * @method GET
   */
  FETCH_WEEKLY_REWARD_COUNT: `${apiPath.CREATOR}/v1/reward/weekly`,

  /**
   * 크리에이터 주간 예상 보상 내역 조회
   * @method GET
   */
  FETCH_WEEKLY_ESTIMATED_REWARDS: `${apiPath.CREATOR}/v1/weekly-estimated-rewards`,

  /**
   * 크리에이터 차트 기간별 주간 보상량 조회
   * @method GET
   */
  FETCH_REWARDS_CHART: `${apiPath.CREATOR}/v1/reward/chart`,

  /**
   * 크리에이터 링크 중복체크
   * @method GET
   */
  FETCH_CHECK_DUPLICATE_CREATOR_LINK: `${apiPath.CREATOR}/v1/creatorlink`,

  /**
   * 크리에이터 링크 수정
   * @method POST
   */
  REQ_UPDATE_CREATOR_LINK: `${apiPath.CREATOR}/v1/creatorlink`,

  /**
   * 크리에이터 방명록 리스트 조회
   * @method GET
   */
  FETCH_CREATOR_GUEST_BOOKS: `${apiPath.CREATOR}/v1/gustbrds`,

  /**
   * 크리에이터 방명록 상세 조회
   * @method GET
   */
  FETCH_CREATOR_GUEST_BOOK: `${apiPath.CREATOR}/v1/gustbrd/{gustSn}`,

  /**
   * 크리에이터 방명록 등록
   * @method POST
   */
  REG_CREATOR_GUEST_BOOK: `${apiPath.CREATOR}/v1/gustbrd`,

  /**
   * 크리에이터 방명록 수정
   * @method PUT
   */
  UPDATE_CREATOR_GUEST_BOOK: `${apiPath.CREATOR}/v1/gustbrd`,

  /**
   * 크리에이터 방명록 삭제
   * @method PUT
   */
  DELETE_CREATOR_GUEST_BOOK: `${apiPath.CREATOR}/v1/gustbrd`,

  /**
   * 크리에이터 공지사항 목록 조회
   * @method GET
   */
  FETCH_CREATOR_NOTICE_LIST: `${apiPath.CREATOR}/v2/crtrNttBrds`,

  /**
   * 크리에이터 임시저장 공지사항 목록 조회
   * @method GET
   */
  FETCH_TEMP_CREATOR_NOTICE_LIST: `${apiPath.CREATOR}/v1/crtrNttBrdsTemp`,

  /**
   * 크리에이터 공지사항 상세 조회
   * @method GET
   */
  FETCH_CREATOR_NOTICE: `${apiPath.CREATOR}/v1/crtrNttBrd/{crtrNttSn}`,

  /**
   * 크리에이터 공지사항 등록
   * @method POST
   */
  REG_CREATOR_NOTICE: `${apiPath.CREATOR}/v1/crtrNttBrd`,

  /**
   * 크리에이터 공지사항 수정
   * @method PUT
   */
  UPDATE_CREATOR_NOTICE: `${apiPath.CREATOR}/v2/crtrNttBrd`,

  /**
   * 크리에이터 공지사항 삭제
   * @method DELETE
   */
  DELETE_CREATOR_NOTICE: `${apiPath.CREATOR}/v1/crtrNttBrd/{crtrNttSn}`,

  /**
   * 크리에이터 홈 홈탭정보조회
   * @method GET
   */
  FETCH_CREATOR_HOME: `${apiPath.CREATOR}/v1/home`,

  /**
   * 크리에이터 홈탭 / 인기포스트
   * @method GET
   */
  FETCH_CREATOR_HOME_HOT_POST: `${apiPath.CREATOR}/v2/home/hot`,

  /**
   * 크리에이터 소셜 목록 조회, 등록, 수정
   * @method GET
   */
  FETCH_CREATOR_SNS_LINKS: `${apiPath.CREATOR}/v1/snslinks`,

  /**
   * 크리에이터 통계탭 > 기본통계정보 조회
   * @method GET
   */
  FETCH_STAT_INFO: `${apiPath.CREATOR}/v1/stat`,

  /**
   * 크리에이터 통계탭 > 공유된 포스터 정보 조회
   */
  FETCH_STAT_SHARE_LIST: `${apiPath.CREATOR}/v1/stat/share/`,

  /**
   * 크리에이터 통계탭 > 공유된 포스터 정보 조회
   */
  REG_CREATOR_INTRODUCTION: `${apiPath.CREATOR}/v1/introduction/`,

  /**
   * 크리에이터 신청 요건 충족 정보 조회
   * @method GET
   */
  FETCH_APPLY_CREATOR_CONDITIONS: `${apiPath.CREATOR}/v2/check-application-conditions`,

  /**
   * 크리에이터 신청: 심사 요건중 하나 이상 충족시
   * @method POST
   */
  REQ_APPLY_CREATOR_SATISFY_YES: `${apiPath.CREATOR}/v2/creator-application`,

  /**
   * 크리에이터 심사 요청
   */
  REQ_APPLY_CREATOR: `${apiPath.CREATOR}/v2/creator-application`,

  /**
   * 크리에이터 카테고리 목록 조회
   * @method GET
   */
  FETCH_CREATOR_CATEGORIES: `${apiPath.CREATOR}/v1/categorys`,

  /**
   * 크리에이터 목록 조회: 메인
   */
  FETCH_CREATORS_BY_HOME: `${apiPath.CREATOR}/v1/type/home-creators`,

  /**
   * 크리에이터 목록 조회: 전체
   */
  FETCH_CREATORS_BY_ALL: `${apiPath.CREATOR}/v1/type/all-creators`,

  /**
   * 크리에이터 목록 조회: 노바+ (추천)
   */
  FETCH_CREATORS_BY_RECOMMENDED: `${apiPath.CREATOR}/v1/type/recommend-creators`,

  /**
   * 크리에이터 대표 프로필 사진등록/변경
   * @method POST
   */
  REQ_UPDATE_CREATOR_IMAGE: `${apiPath.CREATOR}/v1/profile`,

  /**
   * 크리에이터 카테고리 변경
   * @method  PUT
   */
  UPDATE_CREATOR_CATEGORY: `${apiPath.CREATOR}/v1/category`,

  /**
   * 크리에이터 프리미엄 상품 정보 조회
   * @method  GET
   */
  FETCH_PREMIUM: `${apiPath.CREATOR}/v1/premium`,

  /**
   * 크리에이터 프리미엄 개설(신청) 정보(혜택/유의사항) 조회
   * @method  GET
   */
  FETCH_PREMIUM_APPLICATION: `${apiPath.CREATOR}/v1/premium-application`,

  /**
   * 크리에이터 프리미엄 개설(신청)
   * @method POST
   */
  REQ_PREMIUM_APPLICATION: `${apiPath.CREATOR}/v1/premium-application`,
  /**
   * 프리미엄 혜택 목록 수정
   * @method PUT
   */
  UPDATE_PREMIUM_ITEMS: `${apiPath.CREATOR}/v1/premium/items`,

  /**
   * 크리에이터 프리미엄 혜택 목록 조회
   * @method GET
   */
  FETCH_PREMIUM_BENEFITS: `${apiPath.CREATOR}/v1/premium/benefits`,

  /**
   * 크리에이터 프리미엄 혜택 목록 전체 조회
   * @method GET
   */
  FETCH_PREMIUM_BENEFITS_ALL: `${apiPath.CREATOR}/v1/premium/benefits/all`,

  /**
   * 크리에이터 프리미엄 개설/신청 약관 상세 조회
   * @method  GET
   */
  FETCH_PREMIUM_TERM: `${apiPath.CREATOR}/v1/premium/term`,

  /**
   * 크리에이터 프리미엄 개설/신청 약관 목록 조회
   * @method  GET
   */
  FETCH_PREMIUM_TERMS: `${apiPath.CREATOR}/v1/premium/terms`,

  /**
   * 크리에이터 프리미엄 후원(구독) 신청
   * @method POST
   */
  REQ_PREMIUM_DONATE: `${apiPath.CREATOR}/v1/premium/donate`,

  /**
   * 크리에이터 프리미엄 후원(구독) 결제 완료 여부, 주문 정보 조회
   */
  FETCH_PREMIUM_PAYMENT_TRADE_INFO: `${apiPath.CREATOR}/v1/premium/payment/trade-info/:merchantOrderNo`,

  /**
   * 크리에이터 정산 은행 정보 등록
   */
  CREATE_CREATOR_BANK: `${apiPath.CREATOR}/v1/bank`,

  /**
   * 노바+ 후원자 목록 조회
   * @method GET
   */
  FETCH_PREMIUM_SUPPORTERS: `${apiPath.CREATOR}/v1/premium/donators`,

  /**
   * 나의 크리에이터 목록 조회
   * @method GET
   */
  FETCH_MY_CREATORS: `${apiPath.CREATOR}/v1/my-creators`,

  /**
   * 크리에이터 목록 조회
   * @method GET
   */
  FETCH_CREATORS: `${apiPath.CREATOR}/v1/creators`,

  /**
   * 추천 크리에이터 목록 조회
   * @method GET
   */
  FETCH_RECOMMEND_CREATORS: `${apiPath.CREATOR}/v1/recommend-creators`,
}
