import { AxiosError } from 'axios'
import useGetFiles from './useGetFiles'
import type {
  FileImageContainer,
  FileVideoContainer,
  NttTypeCode,
} from '@store'

type UsePostFileUploaderNttTypeCode = Exclude<NttTypeCode, 'NORM'>
type ShowLoadingIndicator = boolean | undefined
interface UsePostFileUploaderProps {
  nttTypeCode: UsePostFileUploaderNttTypeCode
  showLoadingIndicator: ShowLoadingIndicator
}

/**
 * 카드형 파일 얻기
 * @param showLoadingIndicator
 */
export const uploadFileImages = async (
  showLoadingIndicator: ShowLoadingIndicator = false
): Promise<FileImageContainer | null> => {
  const commStore = useCommStore()
  const layoutStore = useLayoutStore()
  const { t } = useNuxtApp().$i18n
  const { gtEvent } = useGoogleTag()

  const { formData } = await useGetFiles({
    accept: 'image/*',
    multiple: true,
    formDataName: 'imgFiles',
  })
  try {
    showLoadingIndicator &&
      layoutStore.updateLoadingIndicatorGlobal({ show: true })
    const data = await commStore.uploadFileImages(formData)
    gtEvent('fileAction', {
      eventCategory: '파일',
      eventAction: '카드형 파일 업로드',
      eventLabel: '',
      eventSlot: '',
      eventI18nAddr: '',
      eventComponent: 'File Upload',
      fileId: data.fileId,
    })
    return data
  } catch (err) {
    if (err instanceof AxiosError) {
      const { status, data } = err.response || {}
      const errorCode = data?.errorCode
      switch (status) {
        case 413:
          switch (errorCode) {
            case 2038:
              await useToast(t('postCreate.toastMessage.videoFileSize.over'))
              break
          }
          break

        case 2005:
          await useToast(t('mypage.profile.toastMessage.imgError2005'))
          break

        default:
          await useToast(t('postCreate.toastMessage.errors.default'))
      }
    } else {
      await useToast(t('postCreate.toastMessage.imgError'))
    }
    return null
  } finally {
    showLoadingIndicator &&
      layoutStore.updateLoadingIndicatorGlobal({ show: false })
  }
}

/**
 * 비디오형 파일 얻기
 * @param showLoadingIndicator
 */
export const uploadFileVideo = async (
  showLoadingIndicator: ShowLoadingIndicator = false
): Promise<FileVideoContainer | null> => {
  const commStore = useCommStore()
  const layoutStore = useLayoutStore()
  const { t } = useNuxtApp().$i18n
  const { gtEvent } = useGoogleTag()
  const { formData } = await useGetFiles({
    accept: 'video/*',
    multiple: false,
    formDataName: 'mvFile',
  })

  try {
    showLoadingIndicator &&
      layoutStore.updateLoadingIndicatorGlobal({ show: true })
    const data = await commStore.uploadFileVideo(formData)
    gtEvent('fileAction', {
      eventCategory: '파일',
      eventAction: '비디오 파일 업로드',
      eventLabel: '',
      eventSlot: '',
      eventI18nAddr: '',
      eventComponent: 'File Upload',
      fileId: data.fileId,
    })
    return data
  } catch (err) {
    if (err instanceof AxiosError && err.status === 413) {
      switch (err.response?.data.errorCode) {
        case 2038:
          await useToast(t('postCreate.toastMessage.videoFileSize.over'))
          break
      }
    } else {
      await useToast(t('postCreate.toastMessage.errors.default'))
    }
    return null
  } finally {
    showLoadingIndicator &&
      layoutStore.updateLoadingIndicatorGlobal({ show: false })
  }
}

/**
 * 포스트 관련 파일 업로더
 * @description CARD, CVOD 형 파일만 사용
 * @param nttTypeCode
 * @param showLoadingIndicator
 */
const usePostFileUploader = async ({
  nttTypeCode,
  showLoadingIndicator,
}: UsePostFileUploaderProps) => {
  switch (nttTypeCode) {
    case 'CARD':
      return await uploadFileImages(showLoadingIndicator)
    case 'CVOD':
      return await uploadFileVideo(showLoadingIndicator)
    default:
      throw new Error('nttTypeCode is not match')
  }
}

export default usePostFileUploader
