import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar, Line, Pie } from 'vue-chartjs'
import { defineNuxtPlugin } from '#app'

// Chart.js의 요소를 등록
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default defineNuxtPlugin((nuxtApp) => {
  // Chart 컴포넌트들을 전역에서 사용할 수 있도록 등록
  nuxtApp.vueApp.component('BarChart', Bar)
  nuxtApp.vueApp.component('LineChart', Line)
  nuxtApp.vueApp.component('PieChart', Pie)
})
