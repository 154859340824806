import * as CreatorType from './type'

/**
 * creator store init state
 */
export const initCreatorState: CreatorType.CreatorState = {
  profile: null,
  myProfile: null,
  postsByHot: {
    items: [],
    payload: {
      contentsType: 'content',
      creatorUserSn: 0,
      pageNum: 0,
      pageSize: 1,
      nextPageSize: 1,
      orderBy: 'newest',
      srchPostType: '',
    },
    refresh: false,
    last: false,
  },
  contentsList: {
    items: [],
    payload: {
      contentsType: 'content',
      creatorUserSn: 0,
      pageNum: 0,
      pageSize: 20,
      nextPageSize: 20,
      orderBy: 'newest',
      srchPostType: '',
    },
    refresh: false,
    last: false,
    loading: false,
  },
  contents: null,
  commentContents: {
    items: [],
    payload: {
      cmtyNttSn: 0,
      pageNum: 0,
      pageSize: 20,
      nextPageSize: 20,
      orderBy: 'newest',
    },
    refresh: false,
    last: false,
  },
  rewardContents: {
    items: [],
    payload: {
      pageNum: 0,
      pageSize: 20,
      nextPageSize: 20,
      orderBy: 'newest',
      creatorUserSn: -1,
    },
    refresh: false,
    last: false,
  },
  rewardCount: {
    rwardStdrBeginDtStr: '',
    rwardStdrEndDtStr: '',
    rwardSmLM: 0,
  },
  weeklyRewardCount: {
    totalReward: 0,
    unreadWeeklyEstimatedReward: 0,
    weeklyEstimatedReward: 0,
  },
  weeklyRewardContents: {
    items: [],
    followCount: 0,
    recommendCount: 0,
    payload: {
      pageNum: 0,
      pageSize: 20,
      nextPageSize: 20,
      svcActGroupClCode: 'RC',
    },
    refresh: false,
    last: false,
  },
  rewardChartData: {
    chartDataList: [
      {
        data: [],
        label: 'ALL',
      },
    ],
    labels: [],
  },
  rewardActivityChartData: {
    labels: [],
    chartDataList: [
      { data: [], label: 'ALL' },
      { data: [], label: 'ALL' },
    ],
  },
  creatorGuestBooks: {
    items: [],
    payload: {
      creatorUserSn: 0,
      pageNum: 0,
      pageSize: 20,
      nextPageSize: 20,
    },
    refresh: false,
    last: false,
    loading: false,
  },
  creatorGuestBook: null,
  creatorNoticeList: {
    items: [],
    payload: {
      creatorUserSn: 0,
      pageSize: 10,
      lastCrtrNttSn: 0,
    },
    refresh: false,
    last: false,
    loading: false,
  },
  tempCreatorNoticeList: [],
  creatorNotice: null,
  creatorHome: null,
  creatorSnsLinks: [],
  creatorStatItem: {
    statRdVal: 0, // 조회수
    statRdDiff: 0, // 조회수 백분율
    statFwVal: 0, // 팔로워수
    statFwDiff: 0, // 팔로워 백분율
    statRankVal: 0, // 랭킹
    statRankDiff: 0, // 랭킹 백분율
    statRewardVal: 0, // 보상
    statRewardDiff: 0, // 보상 백분율
    shareTotalCount: 0, // 전체 공유 수
    shareNormalCount: 0, // 일반게시형 포스팅 공유 수
    shareCardCount: 0, // 카드형 포스팅 공유 수
    shareVideoCount: 0, // 영상형 포시팅 공유 수
  },
  creatorShareStats: {
    items: [],
    payload: {
      lastCmtyNttSn: 0,
      nttTyCode: 'NORM',
      pageNo: 1,
      pageSize: 10,
      statTyCode: 'D',
      // statStartDt: string // 통계검색시작일
      // statEndDt: string // 통계검색종료일
    },
    refresh: false,
    last: false,
  },
  creatorShareVideoStats: {
    items: [],
    payload: {
      lastCmtyNttSn: 0,
      statTyCode: 'D',
      nttTyCode: 'NORM',
      pageNo: 1,
      pageSize: 10,
    },
    refresh: false,
    last: false,
  },
  commentsAndReplies: {
    items: [],
    payload: {
      cmtyNttSn: -1,
      pageNum: 0,
      pageSize: 20,
      nextPageSize: 20,
      orderBy: 'newest',
    },
    refresh: false,
    last: false,
    loading: false,
  },
  commentPosts: {
    items: [],
    payload: {
      cmtyNttSn: -1,
      pageNum: 0,
      pageSize: 20,
      nextPageSize: 20,
      orderBy: 'newest',
    },
    refresh: false,
    last: false,
    loading: false,
  },
  creatorCategories: [],
  creatorsByHomePersonal: [],
  creatorsByHomeProject: [],
  creatorsByAll: {
    items: [],
    payload: {
      crtrOrgnztTyCode: 'IND',
      pageNum: 0,
      pageSize: 40,
    },
    refresh: false,
    last: false,
    loading: false,
  },
  creatorsByRecommended: {
    items: [],
    payload: {
      pageNum: 0,
      pageSize: 4,
    },
    refresh: false,
    last: false,
    loading: false,
  },
  creatorPremiumSupporters: {
    items: [],
    payload: {
      orderBy: 'newest',
      pageNum: 1,
      pageSize: 6,
      searchKeyword: '',
    },
    pagination: {
      currentPage: 1,
      itemPerPage: 1,
      pagePerScope: 5,
      totals: 0,
      pageStartIdx: 0,
    },
  },
  myCreators: {
    items: {
      creatorList: [],
      randSeed: '',
      moreAt: 'Y',
    },
    payload: {
      pageNum: 1,
      pageSize: 20,
    },
    refresh: false,
    last: false,
    loading: false,
  },
  creators: {
    items: {
      creatorList: [],
      randSeed: '',
      moreAt: 'Y',
    },
    payload: {
      pageNum: 1,
      pageSize: 20,
    },
    refresh: false,
    last: false,
    loading: false,
  },
  recommendCreators: {
    items: {
      creatorList: [],
      randSeed: '',
      moreAt: 'Y',
    },
    payload: {
      pageNum: 1,
      pageSize: 20,
    },
    refresh: false,
    last: false,
    loading: false,
  },
}
