<script setup lang="ts">
import { AxiosError } from 'axios'
import type {
  FollowStatus,
  NovaFollowUnfollowButtonProps,
} from './NovaFollowUnfollowButton.types'

const props = defineProps<NovaFollowUnfollowButtonProps>()
const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const commStore = useCommStore()
const { userStore, showSignInDialog } = useMembershipProcess()
const { showFollowErrorMessage, showUnfollowErrorMessage } = useFollow()
const { generateReCaptchaToken } = useReCaptcha()
const followStatus = computed<FollowStatus | null>(() => {
  // 1. 포스트 작성자와 팔로우 하려는 사용자가 같음
  if (props.postUserSn === userStore.user?.userSn) {
    return null
  }

  // 2. 비로그인 상태일때 팔로우 가능
  if (!userStore.isSignIn) {
    return {
      status: 'requiredSignIn',
      text: t('follow'),
    }
  }

  // 4. 팔로잉 가능
  if (props.followFlag === '0') {
    return {
      status: 'follow',
      text: t('follow'),
    }
  }

  // 5. 언팔로우 가능
  if (props.followFlag === '1') {
    return {
      status: 'following',
      text: t('following'),
    }
  }

  return null
})
const followLoading = ref(false)

const handleOnToggleFollow = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `${props.userNcnm} ${followStatus.value?.text} 버튼 클릭`,
    eventLabel: followStatus.value ? followStatus.value.text : '',
    eventSlot: '',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  // 1. 로그인 요청
  if (followStatus.value?.status === 'requiredSignIn') {
    await showSignInDialog()
    return
  }

  // 2. 팔로잉 요청
  if (followStatus.value?.status === 'follow') {
    await handleOnFollow()
    return
  }

  // 3. 언팔로우 요청
  if (followStatus.value?.status === 'following') {
    await handleOnUnFollow()
  }
}

const handleOnFollow = async () => {
  try {
    followLoading.value = true

    await useSleeper()
    const recaptchaToken = await generateReCaptchaToken('FOLLOW')
    await commStore.reqFollow({ flwUserSn: props.postUserSn, recaptchaToken })
  } catch (err) {
    if (err instanceof AxiosError) {
      showFollowErrorMessage(err, props.userNcnm)
    }
  } finally {
    followLoading.value = false
  }
}

const handleOnUnFollow = async () => {
  try {
    followLoading.value = true

    await useSleeper()
    await commStore.reqUnfollow({
      flwUserSn: props.postUserSn,
    })
  } catch (err) {
    if (err instanceof AxiosError) {
      showUnfollowErrorMessage(err, props.userNcnm)
    }
  } finally {
    followLoading.value = false
  }
}
</script>

<template>
  <NovaButtonText
    v-if="followStatus"
    :label="followStatus.text"
    :class="[
      'follow',
      {
        'status-required-signin': followStatus.status === 'requiredSignIn',
      },
      { 'status-follow': followStatus.status === 'follow' },
      { 'status-following': followStatus.status === 'following' },
    ]"
    :loading="followLoading"
    :bg-bright="'light'"
    @click.stop="handleOnToggleFollow"
  />
</template>

<style lang="scss" scoped>
.follow {
  flex-shrink: 0;
  padding: 0 !important;
  @include text-style($text-body-11-medium, $force: true);

  &.status {
    &-required-signin {
      color: $color-black;
    }

    &-follow {
      color: $color-sky-blue;
    }

    &-following {
      color: $color-text-3;
    }
  }
}
</style>
