<script setup lang="ts">
import type {
  NovaOnboardingContentEmits,
  NovaOnboardingContentProps,
} from './NovaOnboardingContent.type.ts'

defineProps<NovaOnboardingContentProps>()
const emit = defineEmits<NovaOnboardingContentEmits>()
const { t } = useI18n()

const handleOnClose = (showAgain: boolean) => {
  emit('closeModal', showAgain)
}
</script>

<template>
  <div class="onboarding-wrap">
    <div class="step-box">
      <div
        v-dompurify-html="t(`onboarding.content.${pageIndex}.title`)"
        class="title-box"
      />
      <div class="img-box">
        <img
          :src="imgSource"
          :alt="t(`onboarding.content.${pageIndex}.title`)"
        />
      </div>
      <div
        v-dompurify-html="t(`onboarding.content.${pageIndex}.message`)"
        class="message-box"
      />
    </div>
    <div v-if="pageIndex === 'step05'" class="button-wrap">
      <NovaButtonText
        :label="t('onboarding.button.start')"
        :size="44"
        class="btn-start"
        :theme="'primary-blue-dark'"
        @click="handleOnClose(false)"
      />
    </div>
    <div v-else class="button-wrap">
      <NovaButtonText
        :label="t('onboarding.button.again')"
        :size="32"
        class="btn-action"
        @click="handleOnClose(false)"
      />
      <NovaButtonText
        :label="t('onboarding.button.skip')"
        :size="32"
        class="btn-action skip"
        @click="handleOnClose(true)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.onboarding-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 112px 20px 24px 20px;
  text-align: center;
  .step-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .title-box {
      @include text-style($text-custom-24-bold);
    }
    .img-box {
      padding-top: 16px;
      img {
        width: 100%;
        max-width: 330px;
        margin: 0 auto;
      }
    }
    .message-box {
      @include text-style($text-custom-15-medium);
    }
  }
  .button-wrap {
    display: flex;
    justify-content: space-between;
    .btn-action {
      &.skip {
        text-decoration: underline;
        text-underline-offset: 4px;
        :deep(.label) {
          span {
          }
        }
      }
    }
    .btn-start {
      width: 100%;
    }
  }
}
</style>
