export const FAMILY_SITES = [
  {
    key: 'theMoonLabs',
    url: 'https://themoonlabs.net',
  },
  {
    key: 'leisureMeta',
    url: 'https://leisuremeta.io',
  },
]
