import * as EtcType from './type'
import EtcService from './service'
import { initEtcState } from './init'
import type { BaseInfinityScrollList, NotificationType } from '@store/types'

export * from './init'
export * from './type'
export const useEtcStore = defineStore('etc', () => {
  const attendanceStore = useAttendanceStore()

  const hashtagRankingPrimary = ref(initEtcState.hashtagRankingPrimary)
  const hashtagRankingSecondary = ref(initEtcState.hashtagRankingSecondary)
  const hashtagRankingTertiary = ref(initEtcState.hashtagRankingTertiary)
  const hashtagRankSearchPanel = ref(initEtcState.hashtagRankSearchPanel)
  const terms = ref(initEtcState.terms)
  const newsList = ref(initEtcState.newsList)
  const newsDetail = ref(initEtcState.newsDetail)
  const noticeList = ref(initEtcState.noticeList)
  const noticeDetail = ref(initEtcState.noticeDetail)
  const eventList = ref(initEtcState.eventList)
  const eventDetail = ref(initEtcState.eventDetail)
  const faqList = ref(initEtcState.faqList)
  const faqDetail = ref(initEtcState.faqDetail)
  const faqFilter = ref(initEtcState.faqFilter)
  const w3cfNoticeList = ref(initEtcState.w3cfNoticeList)
  const w3cfNoticeDetail = ref(initEtcState.w3cfNoticeDetail)
  const fundNoticeList = ref(initEtcState.fundNoticeList)
  const fundNoticeDetail = ref(initEtcState.fundNoticeDetail)
  const selectFilter = ref(initEtcState.selectFilter)
  const classifications = ref(initEtcState.classifications)
  const reportCodes = ref(initEtcState.reportCodes)
  const newAlarmCount = ref(initEtcState.newAlarmCount)
  const notificationPushSounds = ref(initEtcState.notificationPushSounds)
  const notificationSetting = ref(initEtcState.notificationSetting)
  const notificationServiceDetail = ref(initEtcState.notificationServiceDetail)
  const notifications = ref(initEtcState.notifications)
  const dailyChallenge = ref(initEtcState.dailyChallenge)
  const recommenders = ref(initEtcState.recommenders)
  const userRecommendRank = ref(initEtcState.userRecommendRank)
  const mainBanners = ref(initEtcState.mainBanners)
  const mainPicks = ref(initEtcState.mainPicks)
  const premiumBanners = ref(initEtcState.premiumBanners)
  const banks = ref(initEtcState.banks)

  const filterTabData = computed(() =>
    faqFilter.value.map((item) => ({
      id: item.bbsNttClSn,
      name: item.nttClNm,
    }))
  )

  /**
   * 해시태그 랭킹 조회
   * @param payload
   * @param hashtagRankType
   */
  const fetchHashtagRanking = async (
    payload: EtcType.HashtagRankPayload,
    hashtagRankType: EtcType.HashtagRankType = 'primary'
  ) => {
    const { data } = await EtcService.fetchHashtagRanking(payload)

    switch (hashtagRankType) {
      case 'primary':
        hashtagRankingPrimary.value = data
        break
      case 'secondary':
        hashtagRankingSecondary.value = data
        break
      case 'tertiary':
        hashtagRankingTertiary.value = data
        break
      case 'searchPanel':
        hashtagRankSearchPanel.value = data
        break
      default:
        throw new Error('fetch hashtag ranking type is not match')
    }

    return data
  }

  /**
   * 약관 및 정책 목록 조회
   */
  const fetchTerms = async () => {
    const { data } = await EtcService.fetchTerms()
    terms.value = data
    return data
  }

  /**
   * 약관 및 정책 상세 조회
   */
  const fetchTerm = async (payload: EtcType.FetchTermDetailPayload) => {
    const { data } = await EtcService.fetchTerm(payload)
    return data
  }

  /**
   * 게시판 리스트 조회
   */
  const fetchBoardList = async (
    payload: EtcType.BoardPayload,
    refresh = false
  ) => {
    if (!payload.query) {
      delete payload.query
    }

    const { data, headers } = await EtcService.fetchBoardList({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })
    const overrider = (
      origin: BaseInfinityScrollList<EtcType.BoardItem[], EtcType.BoardPayload>
    ) => {
      const override: BaseInfinityScrollList<
        EtcType.BoardItem[],
        EtcType.BoardPayload
      > = {
        items: refresh ? data : origin.items.concat(data),
        payload: { ...payload, pageNum: refresh ? 1 : payload.pageNum + 1 },
        refresh,
        last: typeof headers['pagination-location'] === 'undefined',
      }

      return override
    }
    switch (payload.type) {
      case 'news':
        newsList.value = overrider(newsList.value)
        return newsList.value
      case 'notice':
        noticeList.value = overrider(noticeList.value)
        return noticeList.value
      case 'event':
        eventList.value = overrider(eventList.value)
        return eventList.value
      case 'faq':
        faqList.value = overrider(faqList.value)
        return faqList.value
      case 'w3cf-notice':
        w3cfNoticeList.value = overrider(w3cfNoticeList.value)
        return w3cfNoticeList.value
      case 'funding':
        fundNoticeList.value = overrider(fundNoticeList.value)
        return fundNoticeList.value
      default:
        throw new Error('fetch board list type is not match')
    }
  }

  /**
   * 게시판 상세 조회
   */
  const fetchBoardDetail = async (payload: EtcType.BoardDetailPayload) => {
    const { data } = await EtcService.fetchBoardDetail(payload)

    switch (payload.type) {
      case 'news':
        newsDetail.value = data
        break
      case 'notice':
        noticeDetail.value = data
        break
      case 'event':
        eventDetail.value = data
        break
      case 'faq':
        faqDetail.value = data
        break
      case 'w3cf-notice':
        w3cfNoticeDetail.value = data
        break
      case 'funding':
        fundNoticeDetail.value = data
        break
      default:
        throw new Error('fetch board index type is not match')
    }

    return data
  }

  /**
   * 게시판 필터 리스트
   */
  const fetchBoardFilter = async (payload: EtcType.BoardFilterPayload) => {
    try {
      const { data } = await EtcService.fetchBoardFilter(payload)
      faqFilter.value = data
    } catch (err) {
      return Promise.reject(err)
    }
  }

  /**
   * 문의 분류 리스트 조회
   */
  const fetchClassifications = async () => {
    const { data } = await EtcService.fetchClassifications()
    classifications.value = data
    return data
  }

  /**
   * 문의하기
   */
  const sendQnA = async (payload: EtcType.QnAPayload) => {
    try {
      await EtcService.sendQnA(payload)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  /**
   * 신고 코드 리스트 조회
   */
  const fetchReportCodes = async (payload: EtcType.ReportCodePayload) => {
    const { data } = await EtcService.fetchReportCodes(payload)
    reportCodes.value = data
    return data
  }

  /**
   * 신규 알림 카운트 조회
   */
  const fetchNewAlarmCount = async () => {
    const { data } = await EtcService.fetchNewAlarmCount()
    newAlarmCount.value = data.ntcnCount
    return data
  }

  /**
   * 알림음 목록 조회
   */
  const fetchNotificationPushSounds = async () => {
    const { data } = await EtcService.fetchNotificationPushSounds()
    notificationPushSounds.value = data
  }

  /**
   * 알림 설정 목록 조회
   */
  const fetchNotificationSettings = async () => {
    const { data } = await EtcService.fetchNotificationSettings()
    notificationSetting.value = data
  }

  /**
   * 서비스 알림 설정 하위 리스트 조회
   */
  const fetchNotificationServiceDetail = async (
    payload: EtcType.NotificationServiceDetailPayload
  ) => {
    const { data } = await EtcService.fetchNotificationServiceDetail(payload)
    notificationServiceDetail.value = data
  }

  /**
   * 서비스 알림 설정
   */
  const updateNotificationService = async (
    payload: EtcType.UpdateNotificationServicePayload
  ) => {
    const { data } = await EtcService.updateNotificationService(payload)

    // 알림 설정 > 서비스 업데이트
    if (notificationSetting.value?.service) {
      notificationSetting.value.service = data
    }

    // 알림 설정 > 서비스 상세 업데이트
    if (notificationServiceDetail.value?.serviceSetupList.length) {
      const serviceDetailIdx =
        notificationServiceDetail.value.serviceSetupList.findIndex(
          (item) => item.ntcnSetupId === payload.ntcnSetupId
        )
      if (serviceDetailIdx !== -1) {
        notificationServiceDetail.value.serviceSetupList[serviceDetailIdx] = {
          ...notificationServiceDetail.value.serviceSetupList[serviceDetailIdx],
          svcNtcnRecptnSetupAt: payload.svcNtcnRecptnSetupAt,
          svcNtcnRecptnSetupDt: data.svcNtcnRecptnSetupDt,
        }
      }
    }

    // 출석 체크 알림 > 상태 업데이트
    if (attendanceStore.dailyCheck.ntcnSetupId === payload.ntcnSetupId) {
      attendanceStore.dailyCheck.notification =
        payload.svcNtcnRecptnSetupAt === 'Y'
    }
  }

  /**
   * 푸시 알림 업데이트
   */
  const updateNotificationPush = async (
    payload: EtcType.UpdateNotificationPushPayload
  ) => {
    const { data } = await EtcService.updateNotificationPush(payload)
    notificationSetting.value!.push = data
  }

  /**
   * 알림 설정
   */
  const putNotificationSettings = async (payload: EtcType.AlarmPayload) => {
    try {
      await EtcService.putNotificationSettings(payload)
      await fetchNotificationSettings()
    } catch (err) {
      return Promise.reject(err)
    }
  }

  /**
   * 알림 리스트 조회
   * @param payload
   * @param refresh
   */
  const fetchNotifications = async (
    payload: EtcType.NotificationsPayload,
    refresh = false
  ) => {
    const _payload = {
      ...payload,
    }
    const items = notifications.value[_payload.ntcnSvcTyCode].items

    if (refresh) {
      delete _payload.lastUserNtcnSn
    } else {
      _payload.lastUserNtcnSn = items[items.length - 1].userNtcnSn
    }

    const { data, headers } = await EtcService.fetchNotifications(_payload)

    notifications.value[_payload.ntcnSvcTyCode] = {
      items: refresh ? data : items.concat(data),
      payload: _payload,
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * 알림 읽기
   * @param payload
   * @param notificationType
   */
  const putAlarmItem = async (
    payload: EtcType.NotificationsActionPayload,
    notificationType?: NotificationType
  ) => {
    await EtcService.putAlarmItem(payload)

    if (payload.type === 'all') {
      Object.keys(notifications.value).forEach((target) => {
        notifications.value[target].items.forEach(
          (item) => (item.ntcnCnfirmAt = 'Y')
        )
      })
      newAlarmCount.value = 0
      return
    }

    if (payload.type === 'confirm') {
      if (!notificationType) {
        throw new Error('putAlarmItem confirm require upperCode')
      }

      const readTargetIdx = notifications.value[
        notificationType
      ].items.findIndex(
        (item) => item.userNtcnGroupId === payload.userNtcnGroupId
      )
      notifications.value[notificationType].items[readTargetIdx].ntcnCnfirmAt =
        'Y'
      newAlarmCount.value =
        newAlarmCount.value > 0 ? newAlarmCount.value - 1 : newAlarmCount.value
    }
  }

  /**
   * 알림 삭제
   * @param payload
   * @param notificationType
   */
  const deleteAlarmItem = async (
    payload: EtcType.NotificationsActionPayload,
    notificationType: NotificationType
  ) => {
    await EtcService.deleteAlarmItem(payload)

    if (payload.type === 'all') {
      Object.keys(notifications.value).forEach((target) => {
        notifications.value[target].items = []
        newAlarmCount.value = 0
      })
      return
    }

    if (payload.type === 'confirm') {
      const delTargetIdx = notifications.value[
        notificationType
      ].items.findIndex(
        (item) => item.userNtcnGroupId === payload.userNtcnGroupId
      )

      if (
        notifications.value[notificationType].items[delTargetIdx]
          .ntcnCnfirmAt === 'N'
      )
        newAlarmCount.value = newAlarmCount.value - 1

      notifications.value[notificationType].items.splice(delTargetIdx, 1)
    }
  }

  /**
   * 일일 첼린지 정보 조회
   */
  const fetchDailyChallenge = async () => {
    const { data } = await EtcService.fetchDailyChallenge()
    dailyChallenge.value = data
  }

  /**
   * 이벤트 - 좋아요 받은 사용자 랭킹 목록 조회
   */
  const fetchRecommenders = async (
    payload: EtcType.RecommendersRankingPayload
  ) => {
    const { data } = await EtcService.fetchRecommenders({
      ...payload,
    })

    recommenders.value = {
      items: data,
      payload: { ...payload },
      refresh: true,
      last: true,
    }
  }

  /**
   * 이벤트 - 사용자의 좋아요 받은 랭크 조회
   */
  const fetchUserRecommendRank = async () => {
    const { data } = await EtcService.fetchUserRecommendRank()
    userRecommendRank.value = data
  }

  /**
   * 배너 목록 조회
   */
  const fetchBanners = async (payload: EtcType.BannersPayload) => {
    const { data } = await EtcService.fetchBanners(payload)
    return data
  }

  /**
   * 메인 - 메인 배너 조회
   */
  const fetchMainBanners = async () => {
    const { data } = await EtcService.fetchMainBanners()
    mainBanners.value = data
  }

  /**
   * 메인 - 메인 노바픽 조회
   */
  const fetchMainPicks = async () => {
    const { data } = await EtcService.fetchMainPicks()
    mainPicks.value = data
  }

  /**
   * 프리미엄 - 프리미엄 배너 조회
   */
  const fetchPremiumBanners = async () => {
    const { data } = await EtcService.fetchPremiumBanners()
    premiumBanners.value = data
  }

  /**
   * 마케팅 동의
   */
  const putMarketingSettings = async (marketingload: EtcType.AlarmPayload) => {
    try {
      await EtcService.putMarketingSettings(marketingload)
      await fetchNotificationSettings()
    } catch (err) {
      return Promise.reject(err)
    }
  }

  /**
   * LM 펀딩 현황 조회
   */
  const fetchFunding = async () => {
    const { data } = await EtcService.fetchFunding()
    return data
  }

  /**
   * 정산 은행 목록 조회
   * @param payload
   */
  const fetchBanks = async (payload?: EtcType.BanksPayload) => {
    const { data } = await EtcService.fetchBanks(payload)
    banks.value = data
  }

  return {
    hashtagRankingPrimary,
    hashtagRankingSecondary,
    hashtagRankingTertiary,
    hashtagRankSearchPanel,
    terms,
    newsList,
    newsDetail,
    noticeList,
    noticeDetail,
    eventList,
    eventDetail,
    faqList,
    faqDetail,
    faqFilter,
    w3cfNoticeList,
    w3cfNoticeDetail,
    fundNoticeList,
    fundNoticeDetail,
    selectFilter,
    classifications,
    reportCodes,
    newAlarmCount,
    notificationPushSounds,
    notificationSetting,
    notificationServiceDetail,
    notifications,
    dailyChallenge,
    recommenders,
    userRecommendRank,
    mainBanners,
    mainPicks,
    premiumBanners,
    banks,
    filterTabData,
    fetchHashtagRanking,
    fetchTerms,
    fetchTerm,
    fetchBoardList,
    fetchBoardDetail,
    fetchBoardFilter,
    fetchClassifications,
    sendQnA,
    fetchReportCodes,
    fetchNewAlarmCount,
    fetchNotificationPushSounds,
    fetchNotificationSettings,
    fetchNotificationServiceDetail,
    updateNotificationService,
    updateNotificationPush,
    putNotificationSettings,
    fetchNotifications,
    putAlarmItem,
    deleteAlarmItem,
    fetchDailyChallenge,
    fetchRecommenders,
    fetchUserRecommendRank,
    fetchBanners,
    fetchMainBanners,
    fetchMainPicks,
    fetchPremiumBanners,
    putMarketingSettings,
    fetchFunding,
    fetchBanks,
  }
})
