import type { EtcState } from './type'

export const initEtcState: EtcState = {
  hashtagRankingPrimary: [],
  hashtagRankingSecondary: [],
  hashtagRankingTertiary: [],
  hashtagRankSearchPanel: [],
  terms: [],
  newsList: {
    items: [],
    payload: {
      type: 'news',
      pageNum: 0,
      pageSize: 20,
    },
    refresh: false,
    last: false,
  },
  newsDetail: null,
  noticeList: {
    items: [],
    payload: {
      type: 'notice',
      pageNum: 0,
      pageSize: 20,
    },
    refresh: false,
    last: false,
  },
  noticeDetail: null,
  eventList: {
    items: [],
    payload: {
      type: 'event',
      pageNum: 0,
      pageSize: 20,
    },
    refresh: false,
    last: false,
  },
  eventDetail: null,
  faqList: {
    items: [],
    payload: {
      type: 'faq',
      pageNum: 0,
      pageSize: 20,
      query: '',
    },
    refresh: false,
    last: false,
  },
  faqDetail: null,
  faqFilter: [],
  w3cfNoticeList: {
    items: [],
    payload: {
      type: 'w3cf-notice',
      pageNum: 0,
      pageSize: 20,
    },
    refresh: false,
    last: false,
  },
  w3cfNoticeDetail: null,
  fundNoticeList: {
    items: [],
    payload: {
      type: 'funding',
      pageNum: 0,
      pageSize: 20,
    },
    refresh: false,
    last: false,
  },
  fundNoticeDetail: null,
  selectFilter: 0,
  classifications: [],
  reportCodes: [],
  newAlarmCount: 0,
  notificationPushSounds: [],
  notificationSetting: null,
  notificationServiceDetail: null,
  notifications: {
    NOT_PLS: {
      items: [],
      payload: {
        ntcnSvcTyCode: 'NOT_PLS',
        pageSize: 20,
      },
      refresh: false,
      last: false,
    },
    PLS: {
      items: [],
      payload: {
        ntcnSvcTyCode: 'PLS',
        pageSize: 20,
      },
      refresh: false,
      last: false,
    },
  },
  dailyChallenge: null,
  recommenders: {
    items: [],
    payload: {
      pageSize: 5,
    },
    refresh: false,
    last: false,
  },
  userRecommendRank: null,
  mainBanners: [],
  mainPicks: [],
  premiumBanners: [],
  banks: [],
}
