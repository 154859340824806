import {
  BannerCode,
  type BaseInfinityScrollList,
  type BooleanYn,
  type NotificationType,
  type ReportType,
} from '@store/types'
import { FcmActionHandler, FcmHandler } from '@firebaseModule'

/**
 * 해시태그
 */
export interface Hashtag {
  hashtagNm: string
  hashtagSn: number
}

/**
 * 해시태그 랭킹 조회 페이로드
 */
export interface HashtagRankPayload {
  pageSize: number // 조회 하려는 아이템 개수
  rankPeriod:
    | 'week' // 랭킹 추출 기준: 1주
    | 'semiannual' // 랭킹 추출 기준: 6개월
}

/**
 * 해시태그 랭킹 타입
 */
export type HashtagRankType =
  | 'primary' // 랭크 기간: week, 카운트: 5
  | 'secondary' // 랭크 기간: week, 카운트: 30
  | 'tertiary' // 랭크 기간: semiannual, 카운트: 50
  | 'searchPanel' // 랭크 기간: semiannual, 카운트 10(검색 패널에서만 사용)

/**
 * 게시판 아이템
 */
export interface BoardItem {
  nttAtchmnflAt: BooleanYn // 첨부파일 여부
  nttClNm: string | null // 게시물 클래스(카테고리) 네임
  nttCn: string // 게시판 내용
  nttGroupId: string // 언어팩 구분을 위한 게시판 일련번호
  nttNoticeAt: BooleanYn // 게시판 중요 공지 여부
  nttSj: string // 게시판 제목
  nttSn: number // 게시판 일련번호
  sysRegistDt: string // 게시판 작성일
  cxwaletAdres: string

  // filePath > nttAtchmnflAt 으로 변경됨
  // filePath: string | null // 게시판 첨부파일
}

/**
 * 게시판 상세
 */
export interface BoardItemDetail extends BoardItem {
  fileList: Array<{ filePath: string; orignlFileNm: string }>
  prev?: BoardItem
  next?: BoardItem
}

/**
 * 게시판 타입
 */
export type BoardType =
  | 'news' // 뉴스
  | 'notice' // 공지사항
  | 'event' // 이벤트
  | 'faq' // FAQ
  | 'w3cf-notice' // W3CF 공지사항
  | 'funding' // 펀드 공지사항

/**
 * 게시판 목록 조회 페이로드
 */
export interface BoardPayload {
  type: BoardType
  pageNum: number // 페이지 번호
  pageSize: number // 페이지당 조회 아이템 개수
  bbsNttClSn?: number // 필터,
  query?: string
}

/**
 * 게시판 상세 조회 페이로드
 */
export interface BoardDetailPayload {
  type: BoardType
  nttGroupId: string
}

/**
 * 게시판 필터 아이템
 */
export interface BoardFilterItem {
  bbsNttClSn: number
  nttClNm: string
  expsrOrdr: number
}

/**
 * 게시판 필터 조회 페이로드
 */
export interface BoardFilterPayload {
  type: BoardType
}

/**
 * 이용약관 및 정책 목록 아이템
 */
export interface TermsItem {
  stplatId: string // 약관 아이디
  stplatNm: string // 약관 이름
}

/**
 * 이용약관 및 정책 상세
 */
export interface TermDetail extends TermsItem {
  stplatDetailCn: string // 약관 산세 내용
  stplatVerId: string // 약관 버전 아이디
}

/**
 * 이용약관 및 정책 상세 조회 페이로드
 */
export interface FetchTermDetailPayload {
  stplatId: string
}

/**
 * 문의 분류 코드
 */
export interface Classification {
  bbsNttClSn: number // 게시판 게시물 분류 일련번호
  expsrOrdr: number // 노출 순서
  nttClNm: string // 게시판 분류 명
}

/**
 * 회원 탈퇴 : 문의글 페이로드
 */
export interface QnAPayload {
  bbsNttClSn: number
  email: string
  nttClNm?: string
  nttCn: string
  userCmmnSn: number
}
/**
 * 신고 코드 페이로드
 */
export interface ReportCodePayload {
  nttTyCode: ReportType // 게시물 유형 코드: N(게시물) A(댓글)
}

/**
 * 신고 코드
 */
export interface ReportCode {
  contentRequiredAt: BooleanYn //  내용 필수 여부 Y/N
  expsrOrdr: number // 노출순서
  stdCode: string // 표준 코드
  stdCodeNm: string // 표준 코드 명
}

/**
 * 마케팅 약관 유형 코드
 */
export enum NotificationMarketingAgreeCode {
  EMAIL = '62',
  SMS = '72',
  PUSH = '82',
}

/**
 * 마케팅 알림 설정
 */
export interface NotificationMarketingAgree {
  ntcnNm: string // 알림 명칭
  ntcnBrfDc: string // 알림 간략(브리핑) 설명
  stplatAgreAt: BooleanYn // 약관 동의 여부 Y/N
  stplatAgreDt: Date | null // 약관 동의 일시
  stplatTyCode: NotificationMarketingAgreeCode // 마케팅 약관 유형 코드
}

/**
 * 알림 설정: 마케팅
 */
export interface NotificationMarketingSetting {
  ntcnNm: string // 알림 명칭
  ntcnBrfDc: string // 알림 간략(브리핑) 설명
  marketingAgreeList: NotificationMarketingAgree[] // 알림 동의 리스트
}

/**
 * 알림음 코드
 */
export enum NotificationPushSoundCode {
  SIGNAL = 'SGNL', // 시그널
  VIBE = 'VIBE', // 진동
  MUTE = 'MUTE', // 무음
}

/**
 * 알림음
 */
export interface NotificationPushSound {
  ntcnChnnelAlarmTyCode: NotificationPushSoundCode // 알림 채널 경보 유형 코드 : SGNL(시그널음) VIBE(진동) MUTE(무음)
  ntcnChnnelAlarmTyCodeNm: string // 알림 채널 경보 유형 코드 명칭
}

/**
 * 푸시 알림 설정
 */
export interface NotificationPush extends NotificationPushSound {
  ntcnNm: string // 알림 명칭: 화면출력용
  ntcnBrfDc: string // 알림 간략(브리핑) 설명: 화면출력용
}

/**
 * 알림 설정: 푸시
 */
export interface NotificationPushSetting {
  ntcnNm: string // 알림 명칭: 화면출력용
  ntcnBrfDc: string // 알림 간략(브리핑) 설명: 화면출력용
  userPnsSn: number // 사용자 PNS 일련번호
  svcNtcnRecptnDt: Date // 서비스 알림 수신 일시: PNS수신 On/Off 변경 일시
  svcNtcnRecptnAt: BooleanYn // 알림 수신 여부 Y/N
  pushSound: NotificationPush // 푸시 알림 설정
}

/**
 * 알림 설정: 서비스
 */
export interface NotificationServiceSetting {
  ntcnSetupId: string // 알림 설정 ID
  ntcnNm: string // 알림 명칭: 화면출력용
  ntcnBrfDc: string // 알림 간략(브리핑) 설명: 화면출력용
  lowestExpsrAt: BooleanYn // 하위 설정 출력 여부 Y/N
  serviceSetupList: NotificationServiceSetting[] // 서비스 알림 하위 설정 목록
  svcNtcnRecptnSetupAt: BooleanYn // 서비스 알림 수신 설정 여부 Y/N
  svcNtcnRecptnSetupDt: Date // 서비스 알림 수신 설정 일시
  userNtcnSetupSn: number // 사용자 알림 설정 일련번호
}

/**
 * 알림 설정
 */
export interface NotificationSetting {
  marketing: NotificationMarketingSetting
  push: NotificationPushSetting
  service: NotificationServiceSetting
}

/**
 * 서비스 알림 설정 하위 리스트 조회 페이로드
 */
export interface NotificationServiceDetailPayload {
  ntcnSetupId: string // 알림 설정 ID
}

/**
 * 푸시 알림 설정 페이로드
 */
export interface UpdateNotificationPushPayload {
  svcNtcnRecptnAt: BooleanYn
  ntcnChnnelAlarmTyCode: NotificationPushSoundCode
}

/**
 * 알림 서비스 설정 페이로드
 */
export interface UpdateNotificationServicePayload {
  ntcnSetupId: string // 알림 설정 ID
  svcNtcnRecptnSetupAt: BooleanYn // 서비스 알림 수신 설정 여부 Y/N
}

/**
 * 알림함 신규알림 확인
 */
export interface AlarmCount {
  ntcnCount: number
}

/**
 * 알림 설정 코드
 */
export type AlarmCode =
  | 'ANSW' // 댓글
  | 'EVNT' // 이벤트
  | 'NTCE' // 공지사항
  | 'POST' // 포스트
  | 'DNTN' // 후원
  | 'CPRP' // 프리미엄 포스트 액션
  | 'CPPS' // 프리미엄 새 포스트 작성알림
  | 'CPUT' // 후원 만료 예정
  | 'CPEX' // 후원 만료
  | 'CPSB' // 후원 받음 발생

/**
 * 알림 설정 항목
 */
export interface Alarm {
  svcNtcnSeCode: AlarmCode // 알람 코드
  svcNtcnSeCodeNcm: string // 알람 이름
  svcNtcnRecptnSetupAt: BooleanYn // 알람 허용 'Y' | 'N'
  upperCode: NotificationType // 알림 구분(일반: 'N', 프리미엄: 'P')
}

/**
 * 알림 설정 페이로드
 */
export interface AlarmPayload {
  svcNtcnSeCode: AlarmCode // 알람 코드
  svcNtcnRecptnSetupAt: BooleanYn // 알람 허용 'Y' | 'N'
}

/**
 * 알림 목록 아이템
 */
export interface Notification {
  ntcnCn: string // 알림함 내용
  ntcnCnfirmAt: BooleanYn // 알림함 읽음 여부
  ntcnMssageExtrlLinkAt: BooleanYn // 알림 메세지 외부 링크 여부 Y/N
  ntcnMssageLinkAt: BooleanYn // 알림 메세지 링크 여부 Y/N
  ntcnMssageLinkUrl: string // 알림 메세지 링크 URL
  ntcnRegistDt: string // 알림함 작성일
  ntcnSj: string // 알림함 제목
  svcNtcnStyleCode:
    | 'ORG1' // 알림 스타일 코드(주황색) : 받은 엑션에 대한 알림
    | 'BLU1' // 알림 스타일 코드(파란색) : 사용자 본인이 한 액션에 대한 알림
  userNtcnGroupId: string // 알림 그룹 ID
  userNtcnSn: number // 알림함 시퀀스

  type: FcmHandler
  action: FcmActionHandler
}

/**
 * 알림 목록 페이로드
 */
export interface NotificationsPayload {
  lastUserNtcnSn?: number // 마지막 사용자 알림 일련번호
  ntcnSvcTyCode: NotificationType // 알림 타입
  pageSize: number // 한 페이지당 아이탬 수
}

export type NotificationAction = 'all' | 'confirm'

/**
 * 알림 목록 페이로드
 */
export interface NotificationsActionPayload {
  type: NotificationAction
  userNtcnGroupId: string
}

/**
 * 일일 챌린지 정보
 */
export interface DailyChallenge {
  postReward: number
  replyReward: number
  recommendReward: number
  averageLMPrice: number
}

/**
 * 이벤트 - 좋아요 받은 랭킹 목록 조회 페이로드
 */
export interface RecommendersRankingPayload {
  pageSize: number
}

/**
 * 이벤트 - 좋아요 받은 랭킹 목록 아이템
 */
export interface Recommender {
  rank: number // 순위
  recomendCount: number // 좋아요 받은 수
  userId: string // 사용자 아이디(이메일)
}

/**
 * 이벤트 - 사용자의 좋아요 받은 랭킹
 */
export interface UserRecommendRank {
  rank: number // 랭크
  recomendCount: number // 좋아요 받은 수
  userId: string // 사용자 이메일
}

/**
 * 배너
 */
export interface Banner {
  bannerExtrlLinkAt: BooleanYn // 외부 배너 링크 여부
  bannerImageUrl: string // 배너 이미지 url
  bannerLinkAt: BooleanYn // 배너 링크 여부
  bannerLinkUrl: string // 배너 링크 URL
  bannerMobileImageUrl: string // 모바일 배너 이미지 url
  bannerSn: number // 배너 일련번호
}

/**
 * 배너 목록 조회 페이로드
 */
export interface BannersPayload {
  bannerSlotCode: BannerCode
}

/**
 * 메인 - 메인 배너
 */
export interface MainBanner {
  bannerImageUrl: string // 배너 이미지 url
  bannerLinkAt: BooleanYn // 배너 링크 여부
  bannerExtrlLinkAt: BooleanYn // 외부 배너 링크 여부
  bannerLinkUrl: string // 배너 링크 URL
  bannerMobileImageUrl: string // 모바일 배너 이미지 url
  bannerSn: number // 배너 일련번호
}

/**
 * 메인 - 메인 노바픽
 */
export interface MainPick {
  bannerSn: number // 배너 일련번호
  bannerLinkAt: BooleanYn // 배너 링크 여부
  bannerExtrlLinkAt: BooleanYn // 외부 배너 링크 여부
  bannerLinkUrl: string // 배너 링크 URL
  bannerImageUrl: string // 배너 이미지 url
  bannerMobileImageUrl: string // 모바일 배너 이미지 url
  bannerSj: string // 배너 제목
  bannerSubtlSj: string // 배너 부제 제목
  bannerCn: string // 배너 내용
}

/**
 * 프리미엄 - 프리미엄 배너
 */
export interface PremiumBanner {
  bannerCn: string // 배너 내용
  bannerExtrlLinkAt: string // 배너 외부 링크 여부
  bannerImageUrl: string // 배너 이미지 url
  bannerLinkAt: string // 배너 링크 여부
  bannerLinkUrl: string // 배너 링크 URL
  bannerMobileImageUrl: string // 모바일 배너 이미지 url
  bannerSj: string // 배너 제목
  bannerSn: number // 배너 일련번호
  bannerSubtlSj: string // 배너 부제 제목
}

/**
 * LM 펀딩 현황
 */
export interface Funding {
  gaudiQquantityByEUR: number //  가우디 현재 펀딩 EUR
  gaudiQuantity: number //  가우디 현재 펀딩 LM
  quantity: number //  현재 펀딩 LM
  quantityByUSD: number //  현재 펀딩 USD
  rewardTotalAmount: number //  총 보상 금액 합계
  updateDt: string //  최근업데이트일시
}

/**
 * 정산 은행 목록 조회
 */
export interface BanksPayload {
  nationCode?: 'KR' | 'CN' | 'JP' | 'TW' | 'US' // 국가코드
}

/**
 * 은행
 */
export interface Bank {
  bicCode: string // 은행 코드
  bicBankNm: string // 은행명
}

/**
 * 부가기능 스토어 스테이트 타입
 */
export interface EtcState {
  hashtagRankingPrimary: Hashtag[] // 해시태그 랭킹(Week, 5개)
  hashtagRankingSecondary: Hashtag[] // 해시태그 랭킹(Week, 30개)
  hashtagRankingTertiary: Hashtag[] // 해시태그 랭킹(Month, 50개)
  hashtagRankSearchPanel: Hashtag[] // 해시태그 랭킹(검색 패널에서만 사용)
  newsList: BaseInfinityScrollList<BoardItem[], BoardPayload> // 뉴스 리스트
  newsDetail: BoardItemDetail | null // 뉴스 상세
  noticeList: BaseInfinityScrollList<BoardItem[], BoardPayload> // 공지사항 리스트
  noticeDetail: BoardItemDetail | null // 공지사항 상세
  eventList: BaseInfinityScrollList<BoardItem[], BoardPayload> // 이벤트 리스트
  eventDetail: BoardItemDetail | null // 이벤트 상세
  faqList: BaseInfinityScrollList<BoardItem[], BoardPayload> // FAQ 리스트
  faqDetail: BoardItemDetail | null // FAQ 상세
  faqFilter: BoardFilterItem[] // FAQ 필터,
  w3cfNoticeList: BaseInfinityScrollList<BoardItem[], BoardPayload> // W3CF 공지사항 리스트
  w3cfNoticeDetail: BoardItemDetail | null // W3CF 공지사항 상세
  fundNoticeList: BaseInfinityScrollList<BoardItem[], BoardPayload> // 펀드 공지사항 리스트
  fundNoticeDetail: BoardItemDetail | null // 펀드 공지사항 상세
  selectFilter: number // 선택된 필터
  terms: TermsItem[] // 정책 리스트
  classifications: Classification[] // 문의 분류 리스트
  reportCodes: ReportCode[] // 신고 코드 리스트
  newAlarmCount: AlarmCount['ntcnCount'] // 신규 알림 카운트
  notificationPushSounds: NotificationPushSound[] // 푸시 알림음 목록
  notificationSetting: NotificationSetting | null // 알림 설정 목록
  notificationServiceDetail: NotificationServiceSetting | null // 서비스 알림 설정 하위 리스트
  notifications: {
    [Property in NotificationType | string]: BaseInfinityScrollList<
      Notification[],
      NotificationsPayload
    >
  }
  dailyChallenge: DailyChallenge | null // 일일 첼린지 정보
  recommenders: BaseInfinityScrollList<
    Recommender[],
    RecommendersRankingPayload
  > // 좋아요 받은 랭킹 목록
  userRecommendRank: UserRecommendRank | null // 사용자의 좋아요 받은 랭크
  mainBanners: MainBanner[] // 메인 배너 목록
  mainPicks: MainPick[] // 메인 Pick 목록
  premiumBanners: PremiumBanner[] // 프리미엄 배너 목록
  banks: Bank[]
}
