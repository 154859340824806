<script setup lang="ts">
import type {
  NovaCreateHashtagItemEmits,
  NovaCreateHashtagItemProps,
} from './NovaCreateHashtagItem.types'

const emit = defineEmits<NovaCreateHashtagItemEmits>()
withDefaults(defineProps<NovaCreateHashtagItemProps>(), {
  index: 0,
  prefix: '',
})
</script>

<template>
  <div class="hashtag">
    <span>{{ `${prefix} ` }}{{ hashtag }}</span>
    <button
      type="button"
      class="btn-close"
      @click="emit('updateDelete', index)"
    >
      <NovaIcon :icon="{ type: 'outline', name: 'close-compact' }" :size="20" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
.hashtag {
  display: flex;
  align-items: center;
  width: auto;
  height: 30px;
  padding: 3px 10px;
  border-radius: 30px;
  background-color: $color-secondary-blue-light-10;
  flex-shrink: 0;

  @include text-style($text-body-14-regular);
  color: $color-primary-blue-dark;

  .btn-close {
    display: flex;
    align-items: center;
    margin-left: 4px;
  }
}
</style>
