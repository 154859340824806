import type { LayoutState } from './type'

export const initLayoutState: LayoutState = {
  loadingIndicatorGlobal: {
    show: false,
    message: '',
  },
  headerRect: null,
  headerInnerRect: null,
  bottomTabBarRect: null,
  modals: [],
  layers: [],
  mainBgWhite: false,
  mobileGnbHide: false,
  routeScrollPositions: {},
  responsive: 'desktop',
  touchDevice: false,
}
