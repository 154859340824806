import { default as applypBx5V6bGfkMeta } from "/codebuild/output/src1716140950/src/pages/apply-creator/apply.vue?macro=true";
import { default as indexcUFBcaurZSMeta } from "/codebuild/output/src1716140950/src/pages/apply-creator/index.vue?macro=true";
import { default as additionalD2mxrd62mZMeta } from "/codebuild/output/src1716140950/src/pages/apply-rent/facility-[id]/additional.vue?macro=true";
import { default as completeHqtRsvpeSLMeta } from "/codebuild/output/src1716140950/src/pages/apply-rent/facility-[id]/complete.vue?macro=true";
import { default as indexpyYHOK2CWEMeta } from "/codebuild/output/src1716140950/src/pages/apply-rent/facility-[id]/index.vue?macro=true";
import { default as indexqM9MFD3MKvMeta } from "/codebuild/output/src1716140950/src/pages/apply-rent/index.vue?macro=true";
import { default as formoYP5FLryPkMeta } from "/codebuild/output/src1716140950/src/pages/apply-super-moon/form.vue?macro=true";
import { default as indexOqifHqDD7fMeta } from "/codebuild/output/src1716140950/src/pages/apply-super-moon/index.vue?macro=true";
import { default as _91id_93izH0U49lqFMeta } from "/codebuild/output/src1716140950/src/pages/board/event/[id].vue?macro=true";
import { default as indexaaHQJnEKwEMeta } from "/codebuild/output/src1716140950/src/pages/board/event/index.vue?macro=true";
import { default as _91id_93WPPZIdkkZJMeta } from "/codebuild/output/src1716140950/src/pages/board/faq/[id].vue?macro=true";
import { default as indexKbYtcleLrMMeta } from "/codebuild/output/src1716140950/src/pages/board/faq/index.vue?macro=true";
import { default as _91id_93fUQlbKOjTyMeta } from "/codebuild/output/src1716140950/src/pages/board/news/[id].vue?macro=true";
import { default as indexzr2EdIhQdsMeta } from "/codebuild/output/src1716140950/src/pages/board/news/index.vue?macro=true";
import { default as _91id_93QrmxQc7Ze7Meta } from "/codebuild/output/src1716140950/src/pages/board/notice/[id].vue?macro=true";
import { default as indexyYfn0meHfAMeta } from "/codebuild/output/src1716140950/src/pages/board/notice/index.vue?macro=true";
import { default as payryL8AQKUCnMeta } from "/codebuild/output/src1716140950/src/pages/callback/pay.vue?macro=true";
import { default as signinj0T0D2uDi9Meta } from "/codebuild/output/src1716140950/src/pages/callback/signin.vue?macro=true";
import { default as signupRI50jHX7I9Meta } from "/codebuild/output/src1716140950/src/pages/callback/signup.vue?macro=true";
import { default as _91postId_93PV0OUT7g6YMeta } from "/codebuild/output/src1716140950/src/pages/creator/[id]/activities/[postId].vue?macro=true";
import { default as activitiesdGDTE39HhBMeta } from "/codebuild/output/src1716140950/src/pages/creator/[id]/activities.vue?macro=true";
import { default as _91collectionId_93ZulYC8BFL3Meta } from "/codebuild/output/src1716140950/src/pages/creator/[id]/collections/[collectionId].vue?macro=true";
import { default as collectionsUJIv93tneIMeta } from "/codebuild/output/src1716140950/src/pages/creator/[id]/collections.vue?macro=true";
import { default as donationACydbV6nhRMeta } from "/codebuild/output/src1716140950/src/pages/creator/[id]/donation.vue?macro=true";
import { default as guestbookVIigtHoRsOMeta } from "/codebuild/output/src1716140950/src/pages/creator/[id]/guestbook.vue?macro=true";
import { default as _91postId_93SEcD3XG6dTMeta } from "/codebuild/output/src1716140950/src/pages/creator/[id]/index/[postId].vue?macro=true";
import { default as indexl1BPUSQtllMeta } from "/codebuild/output/src1716140950/src/pages/creator/[id]/index.vue?macro=true";
import { default as _91noticeId_93Xas8VX03OVMeta } from "/codebuild/output/src1716140950/src/pages/creator/[id]/notice/[noticeId].vue?macro=true";
import { default as noticeeSLklHto47Meta } from "/codebuild/output/src1716140950/src/pages/creator/[id]/notice.vue?macro=true";
import { default as _91postId_93bfxK7p7lN3Meta } from "/codebuild/output/src1716140950/src/pages/creator/[id]/nova-plus/[postId].vue?macro=true";
import { default as index9Nawq0lTHvMeta } from "/codebuild/output/src1716140950/src/pages/creator/[id]/nova-plus/index.vue?macro=true";
import { default as _91postId_930vjoBpPEcfMeta } from "/codebuild/output/src1716140950/src/pages/creator/[id]/posts/[postId].vue?macro=true";
import { default as indexOVhIksMDR2Meta } from "/codebuild/output/src1716140950/src/pages/creator/[id]/posts/index.vue?macro=true";
import { default as rewardgsUBGANa34Meta } from "/codebuild/output/src1716140950/src/pages/creator/[id]/reward.vue?macro=true";
import { default as statisticsDY1GYRQxxoMeta } from "/codebuild/output/src1716140950/src/pages/creator/[id]/statistics.vue?macro=true";
import { default as sponsorslyuhZAWfO1Meta } from "/codebuild/output/src1716140950/src/pages/creator/sponsors.vue?macro=true";
import { default as _91creatorUserSn_93bGZXrGGZ3eMeta } from "/codebuild/output/src1716140950/src/pages/creator/subscribe/cancel/[creatorUserSn].vue?macro=true";
import { default as _91creatorUserSn_93VyUWkxxeEsMeta } from "/codebuild/output/src1716140950/src/pages/creator/subscribe/opened/[creatorUserSn].vue?macro=true";
import { default as _91creatorUserSn_938g50s1QEWQMeta } from "/codebuild/output/src1716140950/src/pages/creator/subscribe/refund/[creatorUserSn].vue?macro=true";
import { default as _91creatorUserSn_939wN42tfXrCMeta } from "/codebuild/output/src1716140950/src/pages/creator/subscribe/support/[creatorUserSn].vue?macro=true";
import { default as allLIk7CeAjcyMeta } from "/codebuild/output/src1716140950/src/pages/creators/all.vue?macro=true";
import { default as my6vYC2ImakCMeta } from "/codebuild/output/src1716140950/src/pages/creators/my.vue?macro=true";
import { default as indexwIpM5kvwF4Meta } from "/codebuild/output/src1716140950/src/pages/daily-check/index.vue?macro=true";
import { default as _91id_93CdJkDT1XA8Meta } from "/codebuild/output/src1716140950/src/pages/feed/[id].vue?macro=true";
import { default as indexPgE5stSgvlMeta } from "/codebuild/output/src1716140950/src/pages/feed/index.vue?macro=true";
import { default as index1b34EtYmIzMeta } from "/codebuild/output/src1716140950/src/pages/fund/gaudi-fund/index.vue?macro=true";
import { default as gaudifKZBreqmiHMeta } from "/codebuild/output/src1716140950/src/pages/fund/gaudi.vue?macro=true";
import { default as _91id_93Ke36RQX8cGMeta } from "/codebuild/output/src1716140950/src/pages/fund/history/[id].vue?macro=true";
import { default as historyW4b6QIzYAaMeta } from "/codebuild/output/src1716140950/src/pages/fund/history.vue?macro=true";
import { default as _91id_93w3Ek1OVF20Meta } from "/codebuild/output/src1716140950/src/pages/fund/index/[id].vue?macro=true";
import { default as indexalzMOgS4ZeMeta } from "/codebuild/output/src1716140950/src/pages/fund/index.vue?macro=true";
import { default as _91id_936Zod7S2oW1Meta } from "/codebuild/output/src1716140950/src/pages/fund/notice/[id].vue?macro=true";
import { default as indexi14IQlx1rrMeta } from "/codebuild/output/src1716140950/src/pages/fund/notice/index.vue?macro=true";
import { default as _91id_93NXe15Vu31PMeta } from "/codebuild/output/src1716140950/src/pages/hashtag/[id].vue?macro=true";
import { default as indexGerE4TIkyjMeta } from "/codebuild/output/src1716140950/src/pages/hashtag/index.vue?macro=true";
import { default as indexCMWdiK8UZCMeta } from "/codebuild/output/src1716140950/src/pages/index.vue?macro=true";
import { default as _91id_93sNpLfcpD1cMeta } from "/codebuild/output/src1716140950/src/pages/nova-plus/[id].vue?macro=true";
import { default as indexQ53ZdEHqTkMeta } from "/codebuild/output/src1716140950/src/pages/nova-plus/index.vue?macro=true";
import { default as indexiGgoK44LgWMeta } from "/codebuild/output/src1716140950/src/pages/oulim/index.vue?macro=true";
import { default as _91id_93DleghXReQ3Meta } from "/codebuild/output/src1716140950/src/pages/post/[id].vue?macro=true";
import { default as _64_91id_93nSkEWhaLZnMeta } from "/codebuild/output/src1716140950/src/pages/referral/@[id].vue?macro=true";
import { default as _91id_93lX4poZ1yLVMeta } from "/codebuild/output/src1716140950/src/pages/search/detail/comment/[id].vue?macro=true";
import { default as indexY3UUDVPSDWMeta } from "/codebuild/output/src1716140950/src/pages/search/detail/comment/index.vue?macro=true";
import { default as hashtagd2IBMOXdUpMeta } from "/codebuild/output/src1716140950/src/pages/search/detail/hashtag.vue?macro=true";
import { default as _91id_93FQYn8nAuGKMeta } from "/codebuild/output/src1716140950/src/pages/search/detail/index/[id].vue?macro=true";
import { default as index7XlOfacHj7Meta } from "/codebuild/output/src1716140950/src/pages/search/detail/index/index.vue?macro=true";
import { default as _91id_930cYjcFZmYbMeta } from "/codebuild/output/src1716140950/src/pages/search/detail/nova-plus/[id].vue?macro=true";
import { default as indexcQk7UZUdMeMeta } from "/codebuild/output/src1716140950/src/pages/search/detail/nova-plus/index.vue?macro=true";
import { default as userDvqZ4uvOCHMeta } from "/codebuild/output/src1716140950/src/pages/search/detail/user.vue?macro=true";
import { default as _91id_936krUOPI2mPMeta } from "/codebuild/output/src1716140950/src/pages/search/index/[id].vue?macro=true";
import { default as indexzxTbOM6lR5Meta } from "/codebuild/output/src1716140950/src/pages/search/index.vue?macro=true";
import { default as _91id_936EtJJguaiYMeta } from "/codebuild/output/src1716140950/src/pages/terms/[id].vue?macro=true";
import { default as _91postId_93CxnF3aDbUsMeta } from "/codebuild/output/src1716140950/src/pages/user/[id]/activities/[postId].vue?macro=true";
import { default as activitiesWefH0mFkpTMeta } from "/codebuild/output/src1716140950/src/pages/user/[id]/activities.vue?macro=true";
import { default as _91collectionId_93gWRGqUTZJWMeta } from "/codebuild/output/src1716140950/src/pages/user/[id]/collections/[collectionId].vue?macro=true";
import { default as collections5Zo3d6mCmWMeta } from "/codebuild/output/src1716140950/src/pages/user/[id]/collections.vue?macro=true";
import { default as donationhx9eyyb7LQMeta } from "/codebuild/output/src1716140950/src/pages/user/[id]/donation.vue?macro=true";
import { default as _91postId_939pQ2PsjuOVMeta } from "/codebuild/output/src1716140950/src/pages/user/[id]/index/[postId].vue?macro=true";
import { default as indexnoRE4z1CKcMeta } from "/codebuild/output/src1716140950/src/pages/user/[id]/index/index.vue?macro=true";
import { default as reward9SKz60a5GUMeta } from "/codebuild/output/src1716140950/src/pages/user/[id]/reward.vue?macro=true";
import { default as index3lsh8YmcxnMeta } from "/codebuild/output/src1716140950/src/pages/user/sponsors/index.vue?macro=true";
import { default as _91id_93UleM6Lvwl1Meta } from "/codebuild/output/src1716140950/src/pages/w3cf/detail/[id].vue?macro=true";
import { default as indexxV8tMXyTZoMeta } from "/codebuild/output/src1716140950/src/pages/w3cf/index.vue?macro=true";
export default [
  {
    name: "apply-creator-apply___ko",
    path: "/apply-creator/apply",
    meta: applypBx5V6bGfkMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-creator/apply.vue")
  },
  {
    name: "apply-creator-apply___en",
    path: "/en/apply-creator/apply",
    meta: applypBx5V6bGfkMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-creator/apply.vue")
  },
  {
    name: "apply-creator-apply___th",
    path: "/th/apply-creator/apply",
    meta: applypBx5V6bGfkMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-creator/apply.vue")
  },
  {
    name: "apply-creator___ko",
    path: "/apply-creator",
    meta: indexcUFBcaurZSMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-creator/index.vue")
  },
  {
    name: "apply-creator___en",
    path: "/en/apply-creator",
    meta: indexcUFBcaurZSMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-creator/index.vue")
  },
  {
    name: "apply-creator___th",
    path: "/th/apply-creator",
    meta: indexcUFBcaurZSMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-creator/index.vue")
  },
  {
    name: "apply-rent-facility-id-additional___ko",
    path: "/apply-rent/facility-:id()/additional",
    meta: additionalD2mxrd62mZMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-rent/facility-[id]/additional.vue")
  },
  {
    name: "apply-rent-facility-id-additional___en",
    path: "/en/apply-rent/facility-:id()/additional",
    meta: additionalD2mxrd62mZMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-rent/facility-[id]/additional.vue")
  },
  {
    name: "apply-rent-facility-id-additional___th",
    path: "/th/apply-rent/facility-:id()/additional",
    meta: additionalD2mxrd62mZMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-rent/facility-[id]/additional.vue")
  },
  {
    name: "apply-rent-facility-id-complete___ko",
    path: "/apply-rent/facility-:id()/complete",
    meta: completeHqtRsvpeSLMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-rent/facility-[id]/complete.vue")
  },
  {
    name: "apply-rent-facility-id-complete___en",
    path: "/en/apply-rent/facility-:id()/complete",
    meta: completeHqtRsvpeSLMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-rent/facility-[id]/complete.vue")
  },
  {
    name: "apply-rent-facility-id-complete___th",
    path: "/th/apply-rent/facility-:id()/complete",
    meta: completeHqtRsvpeSLMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-rent/facility-[id]/complete.vue")
  },
  {
    name: "apply-rent-facility-id___ko",
    path: "/apply-rent/facility-:id()",
    meta: indexpyYHOK2CWEMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-rent/facility-[id]/index.vue")
  },
  {
    name: "apply-rent-facility-id___en",
    path: "/en/apply-rent/facility-:id()",
    meta: indexpyYHOK2CWEMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-rent/facility-[id]/index.vue")
  },
  {
    name: "apply-rent-facility-id___th",
    path: "/th/apply-rent/facility-:id()",
    meta: indexpyYHOK2CWEMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-rent/facility-[id]/index.vue")
  },
  {
    name: "apply-rent___ko",
    path: "/apply-rent",
    meta: indexqM9MFD3MKvMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-rent/index.vue")
  },
  {
    name: "apply-rent___en",
    path: "/en/apply-rent",
    meta: indexqM9MFD3MKvMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-rent/index.vue")
  },
  {
    name: "apply-rent___th",
    path: "/th/apply-rent",
    meta: indexqM9MFD3MKvMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-rent/index.vue")
  },
  {
    name: "apply-super-moon-form___ko",
    path: "/apply-super-moon/form",
    meta: formoYP5FLryPkMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-super-moon/form.vue")
  },
  {
    name: "apply-super-moon-form___en",
    path: "/en/apply-super-moon/form",
    meta: formoYP5FLryPkMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-super-moon/form.vue")
  },
  {
    name: "apply-super-moon-form___th",
    path: "/th/apply-super-moon/form",
    meta: formoYP5FLryPkMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-super-moon/form.vue")
  },
  {
    name: "apply-super-moon___ko",
    path: "/apply-super-moon",
    meta: indexOqifHqDD7fMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-super-moon/index.vue")
  },
  {
    name: "apply-super-moon___en",
    path: "/en/apply-super-moon",
    meta: indexOqifHqDD7fMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-super-moon/index.vue")
  },
  {
    name: "apply-super-moon___th",
    path: "/th/apply-super-moon",
    meta: indexOqifHqDD7fMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/apply-super-moon/index.vue")
  },
  {
    name: "board-event-id___ko",
    path: "/board/event/:id()",
    meta: _91id_93izH0U49lqFMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/event/[id].vue")
  },
  {
    name: "board-event-id___en",
    path: "/en/board/event/:id()",
    meta: _91id_93izH0U49lqFMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/event/[id].vue")
  },
  {
    name: "board-event-id___th",
    path: "/th/board/event/:id()",
    meta: _91id_93izH0U49lqFMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/event/[id].vue")
  },
  {
    name: "board-event___ko",
    path: "/board/event",
    meta: indexaaHQJnEKwEMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/event/index.vue")
  },
  {
    name: "board-event___en",
    path: "/en/board/event",
    meta: indexaaHQJnEKwEMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/event/index.vue")
  },
  {
    name: "board-event___th",
    path: "/th/board/event",
    meta: indexaaHQJnEKwEMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/event/index.vue")
  },
  {
    name: "board-faq-id___ko",
    path: "/board/faq/:id()",
    meta: _91id_93WPPZIdkkZJMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/faq/[id].vue")
  },
  {
    name: "board-faq-id___en",
    path: "/en/board/faq/:id()",
    meta: _91id_93WPPZIdkkZJMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/faq/[id].vue")
  },
  {
    name: "board-faq-id___th",
    path: "/th/board/faq/:id()",
    meta: _91id_93WPPZIdkkZJMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/faq/[id].vue")
  },
  {
    name: "board-faq___ko",
    path: "/board/faq",
    meta: indexKbYtcleLrMMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/faq/index.vue")
  },
  {
    name: "board-faq___en",
    path: "/en/board/faq",
    meta: indexKbYtcleLrMMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/faq/index.vue")
  },
  {
    name: "board-faq___th",
    path: "/th/board/faq",
    meta: indexKbYtcleLrMMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/faq/index.vue")
  },
  {
    name: "board-news-id___ko",
    path: "/board/news/:id()",
    meta: _91id_93fUQlbKOjTyMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/news/[id].vue")
  },
  {
    name: "board-news-id___en",
    path: "/en/board/news/:id()",
    meta: _91id_93fUQlbKOjTyMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/news/[id].vue")
  },
  {
    name: "board-news-id___th",
    path: "/th/board/news/:id()",
    meta: _91id_93fUQlbKOjTyMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/news/[id].vue")
  },
  {
    name: "board-news___ko",
    path: "/board/news",
    meta: indexzr2EdIhQdsMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/news/index.vue")
  },
  {
    name: "board-news___en",
    path: "/en/board/news",
    meta: indexzr2EdIhQdsMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/news/index.vue")
  },
  {
    name: "board-news___th",
    path: "/th/board/news",
    meta: indexzr2EdIhQdsMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/news/index.vue")
  },
  {
    name: "board-notice-id___ko",
    path: "/board/notice/:id()",
    meta: _91id_93QrmxQc7Ze7Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/notice/[id].vue")
  },
  {
    name: "board-notice-id___en",
    path: "/en/board/notice/:id()",
    meta: _91id_93QrmxQc7Ze7Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/notice/[id].vue")
  },
  {
    name: "board-notice-id___th",
    path: "/th/board/notice/:id()",
    meta: _91id_93QrmxQc7Ze7Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/notice/[id].vue")
  },
  {
    name: "board-notice___ko",
    path: "/board/notice",
    meta: indexyYfn0meHfAMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/notice/index.vue")
  },
  {
    name: "board-notice___en",
    path: "/en/board/notice",
    meta: indexyYfn0meHfAMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/notice/index.vue")
  },
  {
    name: "board-notice___th",
    path: "/th/board/notice",
    meta: indexyYfn0meHfAMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/board/notice/index.vue")
  },
  {
    name: "callback-pay___ko",
    path: "/callback/pay",
    meta: payryL8AQKUCnMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/callback/pay.vue")
  },
  {
    name: "callback-pay___en",
    path: "/en/callback/pay",
    meta: payryL8AQKUCnMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/callback/pay.vue")
  },
  {
    name: "callback-pay___th",
    path: "/th/callback/pay",
    meta: payryL8AQKUCnMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/callback/pay.vue")
  },
  {
    name: "callback-signin___ko",
    path: "/callback/signin",
    meta: signinj0T0D2uDi9Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/callback/signin.vue")
  },
  {
    name: "callback-signin___en",
    path: "/en/callback/signin",
    meta: signinj0T0D2uDi9Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/callback/signin.vue")
  },
  {
    name: "callback-signin___th",
    path: "/th/callback/signin",
    meta: signinj0T0D2uDi9Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/callback/signin.vue")
  },
  {
    name: "callback-signup___ko",
    path: "/callback/signup",
    meta: signupRI50jHX7I9Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/callback/signup.vue")
  },
  {
    name: "callback-signup___en",
    path: "/en/callback/signup",
    meta: signupRI50jHX7I9Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/callback/signup.vue")
  },
  {
    name: "callback-signup___th",
    path: "/th/callback/signup",
    meta: signupRI50jHX7I9Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/callback/signup.vue")
  },
  {
    name: "creator-id-activities___ko",
    path: "/creator/:id()/activities",
    meta: activitiesdGDTE39HhBMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/activities.vue"),
    children: [
  {
    name: "creator-id-activities-postId___ko",
    path: ":postId()",
    meta: _91postId_93PV0OUT7g6YMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "creator-id-activities___en",
    path: "/en/creator/:id()/activities",
    meta: activitiesdGDTE39HhBMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/activities.vue"),
    children: [
  {
    name: "creator-id-activities-postId___en",
    path: ":postId()",
    meta: _91postId_93PV0OUT7g6YMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "creator-id-activities___th",
    path: "/th/creator/:id()/activities",
    meta: activitiesdGDTE39HhBMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/activities.vue"),
    children: [
  {
    name: "creator-id-activities-postId___th",
    path: ":postId()",
    meta: _91postId_93PV0OUT7g6YMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "creator-id-collections___ko",
    path: "/creator/:id()/collections",
    meta: collectionsUJIv93tneIMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/collections.vue"),
    children: [
  {
    name: "creator-id-collections-collectionId___ko",
    path: ":collectionId()",
    meta: _91collectionId_93ZulYC8BFL3Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "creator-id-collections___en",
    path: "/en/creator/:id()/collections",
    meta: collectionsUJIv93tneIMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/collections.vue"),
    children: [
  {
    name: "creator-id-collections-collectionId___en",
    path: ":collectionId()",
    meta: _91collectionId_93ZulYC8BFL3Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "creator-id-collections___th",
    path: "/th/creator/:id()/collections",
    meta: collectionsUJIv93tneIMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/collections.vue"),
    children: [
  {
    name: "creator-id-collections-collectionId___th",
    path: ":collectionId()",
    meta: _91collectionId_93ZulYC8BFL3Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "creator-id-donation___ko",
    path: "/creator/:id()/donation",
    meta: donationACydbV6nhRMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/donation.vue")
  },
  {
    name: "creator-id-donation___en",
    path: "/en/creator/:id()/donation",
    meta: donationACydbV6nhRMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/donation.vue")
  },
  {
    name: "creator-id-donation___th",
    path: "/th/creator/:id()/donation",
    meta: donationACydbV6nhRMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/donation.vue")
  },
  {
    name: "creator-id-guestbook___ko",
    path: "/creator/:id()/guestbook",
    meta: guestbookVIigtHoRsOMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/guestbook.vue")
  },
  {
    name: "creator-id-guestbook___en",
    path: "/en/creator/:id()/guestbook",
    meta: guestbookVIigtHoRsOMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/guestbook.vue")
  },
  {
    name: "creator-id-guestbook___th",
    path: "/th/creator/:id()/guestbook",
    meta: guestbookVIigtHoRsOMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/guestbook.vue")
  },
  {
    name: "creator-id___ko",
    path: "/creator/:id()",
    meta: indexl1BPUSQtllMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/index.vue"),
    children: [
  {
    name: "creator-id-index-postId___ko",
    path: ":postId()",
    meta: _91postId_93SEcD3XG6dTMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/index/[postId].vue")
  }
]
  },
  {
    name: "creator-id___en",
    path: "/en/creator/:id()",
    meta: indexl1BPUSQtllMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/index.vue"),
    children: [
  {
    name: "creator-id-index-postId___en",
    path: ":postId()",
    meta: _91postId_93SEcD3XG6dTMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/index/[postId].vue")
  }
]
  },
  {
    name: "creator-id___th",
    path: "/th/creator/:id()",
    meta: indexl1BPUSQtllMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/index.vue"),
    children: [
  {
    name: "creator-id-index-postId___th",
    path: ":postId()",
    meta: _91postId_93SEcD3XG6dTMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/index/[postId].vue")
  }
]
  },
  {
    name: "creator-id-notice___ko",
    path: "/creator/:id()/notice",
    meta: noticeeSLklHto47Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/notice.vue"),
    children: [
  {
    name: "creator-id-notice-noticeId___ko",
    path: ":noticeId()",
    meta: _91noticeId_93Xas8VX03OVMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/notice/[noticeId].vue")
  }
]
  },
  {
    name: "creator-id-notice___en",
    path: "/en/creator/:id()/notice",
    meta: noticeeSLklHto47Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/notice.vue"),
    children: [
  {
    name: "creator-id-notice-noticeId___en",
    path: ":noticeId()",
    meta: _91noticeId_93Xas8VX03OVMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/notice/[noticeId].vue")
  }
]
  },
  {
    name: "creator-id-notice___th",
    path: "/th/creator/:id()/notice",
    meta: noticeeSLklHto47Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/notice.vue"),
    children: [
  {
    name: "creator-id-notice-noticeId___th",
    path: ":noticeId()",
    meta: _91noticeId_93Xas8VX03OVMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/notice/[noticeId].vue")
  }
]
  },
  {
    name: "creator-id-nova-plus-postId___ko",
    path: "/creator/:id()/nova-plus/:postId()",
    meta: _91postId_93bfxK7p7lN3Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/nova-plus/[postId].vue")
  },
  {
    name: "creator-id-nova-plus-postId___en",
    path: "/en/creator/:id()/nova-plus/:postId()",
    meta: _91postId_93bfxK7p7lN3Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/nova-plus/[postId].vue")
  },
  {
    name: "creator-id-nova-plus-postId___th",
    path: "/th/creator/:id()/nova-plus/:postId()",
    meta: _91postId_93bfxK7p7lN3Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/nova-plus/[postId].vue")
  },
  {
    name: "creator-id-nova-plus___ko",
    path: "/creator/:id()/nova-plus",
    meta: index9Nawq0lTHvMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/nova-plus/index.vue")
  },
  {
    name: "creator-id-nova-plus___en",
    path: "/en/creator/:id()/nova-plus",
    meta: index9Nawq0lTHvMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/nova-plus/index.vue")
  },
  {
    name: "creator-id-nova-plus___th",
    path: "/th/creator/:id()/nova-plus",
    meta: index9Nawq0lTHvMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/nova-plus/index.vue")
  },
  {
    name: "creator-id-posts-postId___ko",
    path: "/creator/:id()/posts/:postId()",
    meta: _91postId_930vjoBpPEcfMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/posts/[postId].vue")
  },
  {
    name: "creator-id-posts-postId___en",
    path: "/en/creator/:id()/posts/:postId()",
    meta: _91postId_930vjoBpPEcfMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/posts/[postId].vue")
  },
  {
    name: "creator-id-posts-postId___th",
    path: "/th/creator/:id()/posts/:postId()",
    meta: _91postId_930vjoBpPEcfMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/posts/[postId].vue")
  },
  {
    name: "creator-id-posts___ko",
    path: "/creator/:id()/posts",
    meta: indexOVhIksMDR2Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/posts/index.vue")
  },
  {
    name: "creator-id-posts___en",
    path: "/en/creator/:id()/posts",
    meta: indexOVhIksMDR2Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/posts/index.vue")
  },
  {
    name: "creator-id-posts___th",
    path: "/th/creator/:id()/posts",
    meta: indexOVhIksMDR2Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/posts/index.vue")
  },
  {
    name: "creator-id-reward___ko",
    path: "/creator/:id()/reward",
    meta: rewardgsUBGANa34Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/reward.vue")
  },
  {
    name: "creator-id-reward___en",
    path: "/en/creator/:id()/reward",
    meta: rewardgsUBGANa34Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/reward.vue")
  },
  {
    name: "creator-id-reward___th",
    path: "/th/creator/:id()/reward",
    meta: rewardgsUBGANa34Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/reward.vue")
  },
  {
    name: "creator-id-statistics___ko",
    path: "/creator/:id()/statistics",
    meta: statisticsDY1GYRQxxoMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/statistics.vue")
  },
  {
    name: "creator-id-statistics___en",
    path: "/en/creator/:id()/statistics",
    meta: statisticsDY1GYRQxxoMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/statistics.vue")
  },
  {
    name: "creator-id-statistics___th",
    path: "/th/creator/:id()/statistics",
    meta: statisticsDY1GYRQxxoMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/[id]/statistics.vue")
  },
  {
    name: "creator-sponsors___ko",
    path: "/creator/sponsors",
    meta: sponsorslyuhZAWfO1Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/sponsors.vue")
  },
  {
    name: "creator-sponsors___en",
    path: "/en/creator/sponsors",
    meta: sponsorslyuhZAWfO1Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/sponsors.vue")
  },
  {
    name: "creator-sponsors___th",
    path: "/th/creator/sponsors",
    meta: sponsorslyuhZAWfO1Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/sponsors.vue")
  },
  {
    name: "creator-subscribe-cancel-creatorUserSn___ko",
    path: "/creator/subscribe/cancel/:creatorUserSn()",
    meta: _91creatorUserSn_93bGZXrGGZ3eMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/subscribe/cancel/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-cancel-creatorUserSn___en",
    path: "/en/creator/subscribe/cancel/:creatorUserSn()",
    meta: _91creatorUserSn_93bGZXrGGZ3eMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/subscribe/cancel/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-cancel-creatorUserSn___th",
    path: "/th/creator/subscribe/cancel/:creatorUserSn()",
    meta: _91creatorUserSn_93bGZXrGGZ3eMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/subscribe/cancel/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-opened-creatorUserSn___ko",
    path: "/creator/subscribe/opened/:creatorUserSn()",
    meta: _91creatorUserSn_93VyUWkxxeEsMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/subscribe/opened/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-opened-creatorUserSn___en",
    path: "/en/creator/subscribe/opened/:creatorUserSn()",
    meta: _91creatorUserSn_93VyUWkxxeEsMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/subscribe/opened/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-opened-creatorUserSn___th",
    path: "/th/creator/subscribe/opened/:creatorUserSn()",
    meta: _91creatorUserSn_93VyUWkxxeEsMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/subscribe/opened/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-refund-creatorUserSn___ko",
    path: "/creator/subscribe/refund/:creatorUserSn()",
    meta: _91creatorUserSn_938g50s1QEWQMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/subscribe/refund/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-refund-creatorUserSn___en",
    path: "/en/creator/subscribe/refund/:creatorUserSn()",
    meta: _91creatorUserSn_938g50s1QEWQMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/subscribe/refund/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-refund-creatorUserSn___th",
    path: "/th/creator/subscribe/refund/:creatorUserSn()",
    meta: _91creatorUserSn_938g50s1QEWQMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/subscribe/refund/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-support-creatorUserSn___ko",
    path: "/creator/subscribe/support/:creatorUserSn()",
    meta: _91creatorUserSn_939wN42tfXrCMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/subscribe/support/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-support-creatorUserSn___en",
    path: "/en/creator/subscribe/support/:creatorUserSn()",
    meta: _91creatorUserSn_939wN42tfXrCMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/subscribe/support/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-support-creatorUserSn___th",
    path: "/th/creator/subscribe/support/:creatorUserSn()",
    meta: _91creatorUserSn_939wN42tfXrCMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creator/subscribe/support/[creatorUserSn].vue")
  },
  {
    name: "creators-all___ko",
    path: "/creators/all",
    meta: allLIk7CeAjcyMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creators/all.vue")
  },
  {
    name: "creators-all___en",
    path: "/en/creators/all",
    meta: allLIk7CeAjcyMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creators/all.vue")
  },
  {
    name: "creators-all___th",
    path: "/th/creators/all",
    meta: allLIk7CeAjcyMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creators/all.vue")
  },
  {
    name: "creators-my___ko",
    path: "/creators/my",
    meta: my6vYC2ImakCMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creators/my.vue")
  },
  {
    name: "creators-my___en",
    path: "/en/creators/my",
    meta: my6vYC2ImakCMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creators/my.vue")
  },
  {
    name: "creators-my___th",
    path: "/th/creators/my",
    meta: my6vYC2ImakCMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/creators/my.vue")
  },
  {
    name: "daily-check___ko",
    path: "/daily-check",
    meta: indexwIpM5kvwF4Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/daily-check/index.vue")
  },
  {
    name: "daily-check___en",
    path: "/en/daily-check",
    meta: indexwIpM5kvwF4Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/daily-check/index.vue")
  },
  {
    name: "daily-check___th",
    path: "/th/daily-check",
    meta: indexwIpM5kvwF4Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/daily-check/index.vue")
  },
  {
    name: "feed-id___ko",
    path: "/feed/:id()",
    component: () => import("/codebuild/output/src1716140950/src/pages/feed/[id].vue")
  },
  {
    name: "feed-id___en",
    path: "/en/feed/:id()",
    component: () => import("/codebuild/output/src1716140950/src/pages/feed/[id].vue")
  },
  {
    name: "feed-id___th",
    path: "/th/feed/:id()",
    component: () => import("/codebuild/output/src1716140950/src/pages/feed/[id].vue")
  },
  {
    name: "feed___ko",
    path: "/feed",
    component: () => import("/codebuild/output/src1716140950/src/pages/feed/index.vue")
  },
  {
    name: "feed___en",
    path: "/en/feed",
    component: () => import("/codebuild/output/src1716140950/src/pages/feed/index.vue")
  },
  {
    name: "feed___th",
    path: "/th/feed",
    component: () => import("/codebuild/output/src1716140950/src/pages/feed/index.vue")
  },
  {
    name: "fund-gaudi-fund___ko",
    path: "/fund/gaudi-fund",
    meta: index1b34EtYmIzMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/gaudi-fund/index.vue")
  },
  {
    name: "fund-gaudi-fund___en",
    path: "/en/fund/gaudi-fund",
    meta: index1b34EtYmIzMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/gaudi-fund/index.vue")
  },
  {
    name: "fund-gaudi-fund___th",
    path: "/th/fund/gaudi-fund",
    meta: index1b34EtYmIzMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/gaudi-fund/index.vue")
  },
  {
    name: "fund-gaudi___ko",
    path: "/fund/gaudi",
    meta: gaudifKZBreqmiHMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/gaudi.vue")
  },
  {
    name: "fund-gaudi___en",
    path: "/en/fund/gaudi",
    meta: gaudifKZBreqmiHMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/gaudi.vue")
  },
  {
    name: "fund-gaudi___th",
    path: "/th/fund/gaudi",
    meta: gaudifKZBreqmiHMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/gaudi.vue")
  },
  {
    name: "fund-history___ko",
    path: "/fund/history",
    meta: historyW4b6QIzYAaMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/history.vue"),
    children: [
  {
    name: "fund-history-id___ko",
    path: ":id()",
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/history/[id].vue")
  }
]
  },
  {
    name: "fund-history___en",
    path: "/en/fund/history",
    meta: historyW4b6QIzYAaMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/history.vue"),
    children: [
  {
    name: "fund-history-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/history/[id].vue")
  }
]
  },
  {
    name: "fund-history___th",
    path: "/th/fund/history",
    meta: historyW4b6QIzYAaMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/history.vue"),
    children: [
  {
    name: "fund-history-id___th",
    path: ":id()",
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/history/[id].vue")
  }
]
  },
  {
    name: "fund___ko",
    path: "/fund",
    meta: indexalzMOgS4ZeMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/index.vue"),
    children: [
  {
    name: "fund-index-id___ko",
    path: ":id()",
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/index/[id].vue")
  }
]
  },
  {
    name: "fund___en",
    path: "/en/fund",
    meta: indexalzMOgS4ZeMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/index.vue"),
    children: [
  {
    name: "fund-index-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/index/[id].vue")
  }
]
  },
  {
    name: "fund___th",
    path: "/th/fund",
    meta: indexalzMOgS4ZeMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/index.vue"),
    children: [
  {
    name: "fund-index-id___th",
    path: ":id()",
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/index/[id].vue")
  }
]
  },
  {
    name: "fund-notice-id___ko",
    path: "/fund/notice/:id()",
    meta: _91id_936Zod7S2oW1Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/notice/[id].vue")
  },
  {
    name: "fund-notice-id___en",
    path: "/en/fund/notice/:id()",
    meta: _91id_936Zod7S2oW1Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/notice/[id].vue")
  },
  {
    name: "fund-notice-id___th",
    path: "/th/fund/notice/:id()",
    meta: _91id_936Zod7S2oW1Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/notice/[id].vue")
  },
  {
    name: "fund-notice___ko",
    path: "/fund/notice",
    meta: indexi14IQlx1rrMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/notice/index.vue")
  },
  {
    name: "fund-notice___en",
    path: "/en/fund/notice",
    meta: indexi14IQlx1rrMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/notice/index.vue")
  },
  {
    name: "fund-notice___th",
    path: "/th/fund/notice",
    meta: indexi14IQlx1rrMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/fund/notice/index.vue")
  },
  {
    name: "hashtag-id___ko",
    path: "/hashtag/:id()",
    meta: _91id_93NXe15Vu31PMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/hashtag/[id].vue")
  },
  {
    name: "hashtag-id___en",
    path: "/en/hashtag/:id()",
    meta: _91id_93NXe15Vu31PMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/hashtag/[id].vue")
  },
  {
    name: "hashtag-id___th",
    path: "/th/hashtag/:id()",
    meta: _91id_93NXe15Vu31PMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/hashtag/[id].vue")
  },
  {
    name: "hashtag___ko",
    path: "/hashtag",
    meta: indexGerE4TIkyjMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/hashtag/index.vue")
  },
  {
    name: "hashtag___en",
    path: "/en/hashtag",
    meta: indexGerE4TIkyjMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/hashtag/index.vue")
  },
  {
    name: "hashtag___th",
    path: "/th/hashtag",
    meta: indexGerE4TIkyjMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/hashtag/index.vue")
  },
  {
    name: "index___ko",
    path: "/",
    meta: indexCMWdiK8UZCMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexCMWdiK8UZCMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/index.vue")
  },
  {
    name: "index___th",
    path: "/th",
    meta: indexCMWdiK8UZCMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/index.vue")
  },
  {
    name: "nova-plus-id___ko",
    path: "/nova-plus/:id()",
    meta: _91id_93sNpLfcpD1cMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/nova-plus/[id].vue")
  },
  {
    name: "nova-plus-id___en",
    path: "/en/nova-plus/:id()",
    meta: _91id_93sNpLfcpD1cMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/nova-plus/[id].vue")
  },
  {
    name: "nova-plus-id___th",
    path: "/th/nova-plus/:id()",
    meta: _91id_93sNpLfcpD1cMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/nova-plus/[id].vue")
  },
  {
    name: "nova-plus___ko",
    path: "/nova-plus",
    meta: indexQ53ZdEHqTkMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/nova-plus/index.vue")
  },
  {
    name: "nova-plus___en",
    path: "/en/nova-plus",
    meta: indexQ53ZdEHqTkMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/nova-plus/index.vue")
  },
  {
    name: "nova-plus___th",
    path: "/th/nova-plus",
    meta: indexQ53ZdEHqTkMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/nova-plus/index.vue")
  },
  {
    name: "oulim___ko",
    path: "/oulim",
    meta: indexiGgoK44LgWMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/oulim/index.vue")
  },
  {
    name: "oulim___en",
    path: "/en/oulim",
    meta: indexiGgoK44LgWMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/oulim/index.vue")
  },
  {
    name: "oulim___th",
    path: "/th/oulim",
    meta: indexiGgoK44LgWMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/oulim/index.vue")
  },
  {
    name: "post-id___ko",
    path: "/post/:id()",
    meta: _91id_93DleghXReQ3Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/post/[id].vue")
  },
  {
    name: "post-id___en",
    path: "/en/post/:id()",
    meta: _91id_93DleghXReQ3Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/post/[id].vue")
  },
  {
    name: "post-id___th",
    path: "/th/post/:id()",
    meta: _91id_93DleghXReQ3Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/post/[id].vue")
  },
  {
    name: "referral-@id___ko",
    path: "/referral/@:id()",
    meta: _64_91id_93nSkEWhaLZnMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/referral/@[id].vue")
  },
  {
    name: "referral-@id___en",
    path: "/en/referral/@:id()",
    meta: _64_91id_93nSkEWhaLZnMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/referral/@[id].vue")
  },
  {
    name: "referral-@id___th",
    path: "/th/referral/@:id()",
    meta: _64_91id_93nSkEWhaLZnMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/referral/@[id].vue")
  },
  {
    name: "search-detail-comment-id___ko",
    path: "/search/detail/comment/:id()",
    meta: _91id_93lX4poZ1yLVMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/comment/[id].vue")
  },
  {
    name: "search-detail-comment-id___en",
    path: "/en/search/detail/comment/:id()",
    meta: _91id_93lX4poZ1yLVMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/comment/[id].vue")
  },
  {
    name: "search-detail-comment-id___th",
    path: "/th/search/detail/comment/:id()",
    meta: _91id_93lX4poZ1yLVMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/comment/[id].vue")
  },
  {
    name: "search-detail-comment___ko",
    path: "/search/detail/comment",
    meta: indexY3UUDVPSDWMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/comment/index.vue")
  },
  {
    name: "search-detail-comment___en",
    path: "/en/search/detail/comment",
    meta: indexY3UUDVPSDWMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/comment/index.vue")
  },
  {
    name: "search-detail-comment___th",
    path: "/th/search/detail/comment",
    meta: indexY3UUDVPSDWMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/comment/index.vue")
  },
  {
    name: "search-detail-hashtag___ko",
    path: "/search/detail/hashtag",
    meta: hashtagd2IBMOXdUpMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/hashtag.vue")
  },
  {
    name: "search-detail-hashtag___en",
    path: "/en/search/detail/hashtag",
    meta: hashtagd2IBMOXdUpMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/hashtag.vue")
  },
  {
    name: "search-detail-hashtag___th",
    path: "/th/search/detail/hashtag",
    meta: hashtagd2IBMOXdUpMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/hashtag.vue")
  },
  {
    name: "search-detail-index-id___ko",
    path: "/search/detail/:id()",
    meta: _91id_93FQYn8nAuGKMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/index/[id].vue")
  },
  {
    name: "search-detail-index-id___en",
    path: "/en/search/detail/:id()",
    meta: _91id_93FQYn8nAuGKMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/index/[id].vue")
  },
  {
    name: "search-detail-index-id___th",
    path: "/th/search/detail/:id()",
    meta: _91id_93FQYn8nAuGKMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/index/[id].vue")
  },
  {
    name: "search-detail-index___ko",
    path: "/search/detail",
    meta: index7XlOfacHj7Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/index/index.vue")
  },
  {
    name: "search-detail-index___en",
    path: "/en/search/detail",
    meta: index7XlOfacHj7Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/index/index.vue")
  },
  {
    name: "search-detail-index___th",
    path: "/th/search/detail",
    meta: index7XlOfacHj7Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/index/index.vue")
  },
  {
    name: "search-detail-nova-plus-id___ko",
    path: "/search/detail/nova-plus/:id()",
    meta: _91id_930cYjcFZmYbMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/nova-plus/[id].vue")
  },
  {
    name: "search-detail-nova-plus-id___en",
    path: "/en/search/detail/nova-plus/:id()",
    meta: _91id_930cYjcFZmYbMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/nova-plus/[id].vue")
  },
  {
    name: "search-detail-nova-plus-id___th",
    path: "/th/search/detail/nova-plus/:id()",
    meta: _91id_930cYjcFZmYbMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/nova-plus/[id].vue")
  },
  {
    name: "search-detail-nova-plus___ko",
    path: "/search/detail/nova-plus",
    meta: indexcQk7UZUdMeMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/nova-plus/index.vue")
  },
  {
    name: "search-detail-nova-plus___en",
    path: "/en/search/detail/nova-plus",
    meta: indexcQk7UZUdMeMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/nova-plus/index.vue")
  },
  {
    name: "search-detail-nova-plus___th",
    path: "/th/search/detail/nova-plus",
    meta: indexcQk7UZUdMeMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/nova-plus/index.vue")
  },
  {
    name: "search-detail-user___ko",
    path: "/search/detail/user",
    meta: userDvqZ4uvOCHMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/user.vue")
  },
  {
    name: "search-detail-user___en",
    path: "/en/search/detail/user",
    meta: userDvqZ4uvOCHMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/user.vue")
  },
  {
    name: "search-detail-user___th",
    path: "/th/search/detail/user",
    meta: userDvqZ4uvOCHMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/detail/user.vue")
  },
  {
    name: "search___ko",
    path: "/search",
    meta: indexzxTbOM6lR5Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/index.vue"),
    children: [
  {
    name: "search-index-id___ko",
    path: ":id()",
    meta: _91id_936krUOPI2mPMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/index/[id].vue")
  }
]
  },
  {
    name: "search___en",
    path: "/en/search",
    meta: indexzxTbOM6lR5Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/index.vue"),
    children: [
  {
    name: "search-index-id___en",
    path: ":id()",
    meta: _91id_936krUOPI2mPMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/index/[id].vue")
  }
]
  },
  {
    name: "search___th",
    path: "/th/search",
    meta: indexzxTbOM6lR5Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/index.vue"),
    children: [
  {
    name: "search-index-id___th",
    path: ":id()",
    meta: _91id_936krUOPI2mPMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/search/index/[id].vue")
  }
]
  },
  {
    name: "terms-id___ko",
    path: "/terms/:id()",
    meta: _91id_936EtJJguaiYMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/terms/[id].vue")
  },
  {
    name: "terms-id___en",
    path: "/en/terms/:id()",
    meta: _91id_936EtJJguaiYMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/terms/[id].vue")
  },
  {
    name: "terms-id___th",
    path: "/th/terms/:id()",
    meta: _91id_936EtJJguaiYMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/terms/[id].vue")
  },
  {
    name: "user-id-activities___ko",
    path: "/user/:id()/activities",
    meta: activitiesWefH0mFkpTMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/activities.vue"),
    children: [
  {
    name: "user-id-activities-postId___ko",
    path: ":postId()",
    meta: _91postId_93CxnF3aDbUsMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "user-id-activities___en",
    path: "/en/user/:id()/activities",
    meta: activitiesWefH0mFkpTMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/activities.vue"),
    children: [
  {
    name: "user-id-activities-postId___en",
    path: ":postId()",
    meta: _91postId_93CxnF3aDbUsMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "user-id-activities___th",
    path: "/th/user/:id()/activities",
    meta: activitiesWefH0mFkpTMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/activities.vue"),
    children: [
  {
    name: "user-id-activities-postId___th",
    path: ":postId()",
    meta: _91postId_93CxnF3aDbUsMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "user-id-collections___ko",
    path: "/user/:id()/collections",
    meta: collections5Zo3d6mCmWMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/collections.vue"),
    children: [
  {
    name: "user-id-collections-collectionId___ko",
    path: ":collectionId()",
    meta: _91collectionId_93gWRGqUTZJWMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "user-id-collections___en",
    path: "/en/user/:id()/collections",
    meta: collections5Zo3d6mCmWMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/collections.vue"),
    children: [
  {
    name: "user-id-collections-collectionId___en",
    path: ":collectionId()",
    meta: _91collectionId_93gWRGqUTZJWMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "user-id-collections___th",
    path: "/th/user/:id()/collections",
    meta: collections5Zo3d6mCmWMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/collections.vue"),
    children: [
  {
    name: "user-id-collections-collectionId___th",
    path: ":collectionId()",
    meta: _91collectionId_93gWRGqUTZJWMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "user-id-donation___ko",
    path: "/user/:id()/donation",
    meta: donationhx9eyyb7LQMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/donation.vue")
  },
  {
    name: "user-id-donation___en",
    path: "/en/user/:id()/donation",
    meta: donationhx9eyyb7LQMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/donation.vue")
  },
  {
    name: "user-id-donation___th",
    path: "/th/user/:id()/donation",
    meta: donationhx9eyyb7LQMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/donation.vue")
  },
  {
    name: "user-id-index-postId___ko",
    path: "/user/:id()/:postId()",
    meta: _91postId_939pQ2PsjuOVMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/index/[postId].vue")
  },
  {
    name: "user-id-index-postId___en",
    path: "/en/user/:id()/:postId()",
    meta: _91postId_939pQ2PsjuOVMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/index/[postId].vue")
  },
  {
    name: "user-id-index-postId___th",
    path: "/th/user/:id()/:postId()",
    meta: _91postId_939pQ2PsjuOVMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/index/[postId].vue")
  },
  {
    name: "user-id-index___ko",
    path: "/user/:id()",
    meta: indexnoRE4z1CKcMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/index/index.vue")
  },
  {
    name: "user-id-index___en",
    path: "/en/user/:id()",
    meta: indexnoRE4z1CKcMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/index/index.vue")
  },
  {
    name: "user-id-index___th",
    path: "/th/user/:id()",
    meta: indexnoRE4z1CKcMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/index/index.vue")
  },
  {
    name: "user-id-reward___ko",
    path: "/user/:id()/reward",
    meta: reward9SKz60a5GUMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/reward.vue")
  },
  {
    name: "user-id-reward___en",
    path: "/en/user/:id()/reward",
    meta: reward9SKz60a5GUMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/reward.vue")
  },
  {
    name: "user-id-reward___th",
    path: "/th/user/:id()/reward",
    meta: reward9SKz60a5GUMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/[id]/reward.vue")
  },
  {
    name: "user-sponsors___ko",
    path: "/user/sponsors",
    meta: index3lsh8YmcxnMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/sponsors/index.vue")
  },
  {
    name: "user-sponsors___en",
    path: "/en/user/sponsors",
    meta: index3lsh8YmcxnMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/sponsors/index.vue")
  },
  {
    name: "user-sponsors___th",
    path: "/th/user/sponsors",
    meta: index3lsh8YmcxnMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/user/sponsors/index.vue")
  },
  {
    name: "w3cf-detail-id___ko",
    path: "/w3cf/detail/:id()",
    meta: _91id_93UleM6Lvwl1Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/w3cf/detail/[id].vue")
  },
  {
    name: "w3cf-detail-id___en",
    path: "/en/w3cf/detail/:id()",
    meta: _91id_93UleM6Lvwl1Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/w3cf/detail/[id].vue")
  },
  {
    name: "w3cf-detail-id___th",
    path: "/th/w3cf/detail/:id()",
    meta: _91id_93UleM6Lvwl1Meta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/w3cf/detail/[id].vue")
  },
  {
    name: "w3cf___ko",
    path: "/w3cf",
    meta: indexxV8tMXyTZoMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/w3cf/index.vue")
  },
  {
    name: "w3cf___en",
    path: "/en/w3cf",
    meta: indexxV8tMXyTZoMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/w3cf/index.vue")
  },
  {
    name: "w3cf___th",
    path: "/th/w3cf",
    meta: indexxV8tMXyTZoMeta || {},
    component: () => import("/codebuild/output/src1716140950/src/pages/w3cf/index.vue")
  }
]