<script setup lang="ts">
import type { ScrollStatus, NovaFloatingProps } from './NovaFloating.types'

const defaultBottomPosition = 16
const props = withDefaults(defineProps<NovaFloatingProps>(), {
  bottomPosition: defaultBottomPosition,
})

const layoutStore = useLayoutStore()
const { responsive } = storeToRefs(layoutStore)
const lastScrollTop = ref(0)
const scrollStatus = ref<ScrollStatus>({
  scrollTop: 0,
  direction: null,
})
const floatingButtonWrapRef = ref<HTMLDivElement>()
const show = computed(() => scrollStatus.value.scrollTop > 0)
const scrollEnd = ref(false)
const bottom = computed(() =>
  useGetStyleSize(
    props.bottomPosition
      ? props.bottomPosition + defaultBottomPosition
      : defaultBottomPosition
  )
)

onMounted(async () => {
  await nextTick()
  window.addEventListener('scroll', calcScrollDirectionDetect)
})

onUnmounted(() => {
  window.removeEventListener('scroll', calcScrollDirectionDetect)
})

const calcScrollDirectionDetect = () => {
  const scrollTop = window.scrollY || 0

  if (scrollTop > lastScrollTop.value) {
    scrollStatus.value = {
      scrollTop,
      direction: 'down',
    }
  } else if (scrollTop < lastScrollTop.value) {
    scrollStatus.value = {
      scrollTop,
      direction: 'up',
    }
  }

  if (scrollTop === 0) {
    scrollStatus.value = {
      scrollTop,
      direction: null,
    }
  }

  scrollEnd.value =
    window.innerHeight + window.scrollY >= document.body.scrollHeight

  lastScrollTop.value = scrollTop <= 0 ? 0 : scrollTop
}
</script>

<template>
  <div
    ref="floatingButtonWrapRef"
    :class="['floating-button-wrap']"
    :style="{ bottom }"
  >
    <NovaFloatingScrollToTopButton
      :show="show"
      :class="[
        'scroll-to-top-button',
        { on: show, 'not-mobile': responsive !== 'mobile' },
      ]"
    />

    <ClientOnly>
      <NovaBoxPostCreateGate
        v-if="responsive !== 'mobile'"
        :show="show"
        :popover-position="'middle-bottom-end'"
      >
        <template #button>
          <NovaCreatePostButton class="floating-button-create-post" />
        </template>
      </NovaBoxPostCreateGate>
    </ClientOnly>
  </div>
</template>

<style lang="scss" scoped>
.floating-button-wrap {
  position: fixed;
  right: 16px;
  bottom: 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 12px;
  align-items: center;
  justify-content: center;
  z-index: $z-index-scroll-top-button;
  transition: opacity 0.2s ease-in-out;

  .scroll-to-top-button {
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;

    &.not-mobile {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    &.on {
      opacity: 1;
      pointer-events: auto;
    }
  }

  $size: 48px;
  .btn-open-post-create-gate {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $size;
    height: $size;
    border-radius: calc($size / 2);
    background-color: $color-secondary-blue-light-70;
    z-index: $z-index-scroll-top-button;
    transition: opacity 0.2s ease-in-out;
    @include transition(opacity 0.2s ease-in-out, bottom 0.2s ease-in-out);

    &:before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        -45deg,
        $color-secondary-blue-light-40 0%,
        $color-secondary-blue-light-70 100%
      );
      transform: translate3d(0px, 6px, 0) scale(0.95);
      filter: blur(4px);
      opacity: 0.7;
      transition: opacity 0.3s;
      border-radius: inherit;
    }

    &::after {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: inherit;
      border-radius: inherit;
    }

    > .icon {
      color: $color-white;
    }

    @include mobile {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
</style>
