/**
 * 화면에 토스트 메시지를 표시
 *
 * @param {string} message - 토스트에서 표시될 메시지
 */
export const useToast = async (message: string) => {
  const layoutStore = useLayoutStore()
  const { bottomTabBarRect } = storeToRefs(layoutStore)

  const selector = {
    toastMessageContainer: 'toast-message-container',
  }

  let toastMessageContainer: HTMLDivElement | null = document.querySelector(
    `.${selector.toastMessageContainer}`
  )

  // 1. create toast message container
  if (!toastMessageContainer) {
    toastMessageContainer = document.createElement('div')
    toastMessageContainer.classList.add(selector.toastMessageContainer)

    await useSleeper(100)
    if (bottomTabBarRect.value?.height) {
      const bottomTabBarRectHeight = bottomTabBarRect.value?.height || 0
      toastMessageContainer.style.paddingBottom = useGetStyleSize(
        bottomTabBarRectHeight ? bottomTabBarRectHeight + 16 : 32
      )
    }

    document.body.appendChild(toastMessageContainer)
  }

  // 2. create toast message box
  const toastMessageBox = document.createElement('div')
  toastMessageBox.classList.add('toast-message-box')
  toastMessageBox.innerText = message

  toastMessageContainer.appendChild(toastMessageBox)

  setTimeout(() => {
    toastMessageBox.classList.add('on')
  }, 100)

  setTimeout(() => {
    toastMessageBox.classList.remove('on')
    toastMessageBox.classList.add('leave')
  }, 3000)

  setTimeout(() => {
    toastMessageBox.remove()
  }, 3300)
}
