<script setup lang="ts">
import type { RouteParams } from 'vue-router'
import type { UseDonateCreator } from '@mixins'

defineOptions({
  inheritAttrs: false,
})

const { hide: modalHide } = useModal()
const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const mypageStore = useMyPageStore()
const layoutStore = useLayoutStore()
const blockchainStore = useBlockchainStore()
const commStore = useCommStore()
const etcStore = useEtcStore()
const postStore = usePostStore()
const show = ref(false)
const isLoading = ref(false)
const isFail = ref(false)
const isSuccess = ref(false)
const errorMessage = ref('')
const creator = ref<UseDonateCreator | null>()
const privateKey = ref('')
const cmtyNttSn = ref(0)
const balance = computed(() => ({
  origin: blockchainStore.balance?.totalAmount || 0,
  format: useFormatThousandsSeparators(
    blockchainStore.balance?.totalAmount || 0,
    2
  ),
}))
const donation = ref<string | number>('')
const donationActionBtnDisabled = computed(
  () =>
    balance.value.origin === 0 ||
    donation.value === '0' ||
    donation.value === '00' ||
    donation.value === '000' ||
    donation.value === 0 ||
    donation.value === ''
)

const donateMessage = ref('')

// 모달 오픈전 사전 작업 수행
const handleOnBeforeOpen = async (evt: RouteParams) => {
  const params = evt.value as unknown as {
    creator: UseDonateCreator
    privateKey: string
    cmtyNttSn?: number
  }

  creator.value = params.creator
  privateKey.value = params.privateKey
  donation.value = ''
  cmtyNttSn.value = params.cmtyNttSn || 0
  await fetchBalance()
}

// 사용자 벨런스 조회(LMC 잔액 조회)
const fetchBalance = async () => {
  try {
    isLoading.value = true
    isFail.value = false
    await blockchainStore.fetchBalance({ movable: '' })

    if (blockchainStore.balance?.totalAmount === 0) {
      errorMessage.value = t('donateAction.error.lackOfBalance')
      isFail.value = true
    }
  } catch (err) {
    switch ((err as any).response.status) {
      // case 1000:
      //   errorMessage.value = 'test error message'
      //   break
      default:
        errorMessage.value = t('donateAction.error.network')
        break
    }
    isFail.value = true
  } finally {
    isLoading.value = false
  }
}

const formatter = (value: string) =>
  value
    ? useFormatThousandsSeparators(String(value).replace(/[^0-9\\.]+/g, ''), 0)
    : ''

const unFormatter = (value: string) => value.replace(' ', '').replace(',', '')

// 후원하기
const handleOnClickDonate = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '후원하기 버튼 클릭',
    eventLabel: t('donateAction.title'),
    eventSlot: '모달',
    eventI18nAddr: useKoreanTranslation('donateAction.title'),
    eventComponent: 'Button',
    cmtyNttSn: cmtyNttSn.value,
    donation: donation.value,
  })
  try {
    // 1. 전역 로딩 인디케이터 추가
    layoutStore.updateLoadingIndicatorGlobal({ show: true })

    // 2. 후원 하기 API 호출
    const donateMessageFormated = donateMessage.value
      .trim()
      .replace(/\n/g, '<br />')
    if (cmtyNttSn.value) {
      await postStore.reqPostingDonation({
        cmtyNttSn: cmtyNttSn.value,
        dntnMssage: donateMessageFormated,
        donation: Number(donation.value),
        privateKey: privateKey.value,
      })
    } else {
      await commStore.reqCreatorDonate({
        creatorUserSn: creator.value!.userSn,
        dntnMssage: donateMessageFormated,
        donation: Number(donation.value),
        privateKey: privateKey.value,
      })
    }

    // 3. 벨런스 재조회
    await blockchainStore.fetchBalance({ movable: '' })

    // 3. 알림 카운트 재조회
    await etcStore.fetchNewAlarmCount()

    // 4. 후원금 재조회
    await mypageStore.fetchDonationQty()

    // 5. 사용자 정보 재조회
    await mypageStore.fetchProfile()

    // 6. 성공 화면 표시 및 정보 클리어
    isSuccess.value = true
    donateMessage.value = ''

    // 7. GA userAction 전송
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '후원하기 성공',
      eventLabel: t('donateAction.title'),
      eventSlot: '',
      eventI18nAddr: useKoreanTranslation('donateAction.title'),
      eventComponent: 'Button',
      cmtyNttSn: cmtyNttSn.value,
      donation: donation.value,
    })
  } catch (err: any) {
    switch (err.response.status) {
      // 2006: 필수값 누락
      // 2028: 잔액 부족
      // 2029: Transfer 오류
      // 2031: 허용되지 않는 금액
      // 4006: 후원하는 사용자가 KYC 미인증
      // 4008: 후원하는 사용자가 지값 주소 없음
      // 4010: 후원받는 대상자가 크리에이터 아님
      // 4011: 후원받는 크리에이터 KYC 미인증
      // 4012: 후원받는 크리에이터가 지갑 주소 없음
      case 2006:
      case 2028:
      case 2029:
      case 2031:
      case 4006:
      case 4008:
      case 4010:
      case 4011:
      case 4012:
        useToast(t(`donateAction.error.codes.${err.response.status}`))
        break
      default:
        useToast(t('donateAction.error.network'))
        break
    }
  } finally {
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
  }
}

// 팝업 닫기
const handleOnClose = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '후원하기 팝업 닫기',
    eventLabel: '',
    eventSlot: '모달',
    eventI18nAddr: '',
    eventComponent: 'Button',
    cmtyNttSn: cmtyNttSn.value,
    donation: donation.value,
  })
  await modalHide(modalsName.MODAL_DONATE)
  isSuccess.value = false
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_DONATE"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnBeforeOpen(evt.ref.params)"
    @click-outside="handleOnClose"
  >
    <ClientOnly>
      <NovaLoadingIndicator
        v-if="isLoading"
        :fill="true"
        :bg-bright="'dark'"
        class="async-status-message"
      />

      <NovaBoxBase v-if="!isLoading && show" class="modal-box">
        <div class="modal-header">
          <h4 class="title">{{ $t('donateAction.title') }}</h4>

          <NovaButtonIcon
            :icon="{ type: 'outline', name: 'close-extend' }"
            :theme="'transparent'"
            :size="20"
            class="btn-close"
            @click="handleOnClose"
          />
        </div>

        <NovaLayoutScrollContainer class="modal-body">
          <div v-if="creator && !isFail && !isLoading" class="donate">
            <div v-if="!isSuccess" class="creator-modal-wrap">
              <div class="creator-intro">
                <div class="creator-portrait">
                  <NovaImageContainer
                    :image-url="creator.userProflUrl"
                    :empty-type="'portrait'"
                    :ratio="'1:1'"
                  />
                </div>

                <div class="creator-guide">
                  <h4>
                    “{{ creator?.userNcnm }}” {{ t('donateAction.title') }}
                  </h4>
                  <p>{{ t('donateAction.guide') }}</p>
                </div>
              </div>

              <div class="donation-unit-input">
                <NovaInput
                  v-model="donation"
                  :label="t('donateAction.howAmount')"
                  :placeholder="t('donateAction.donationPlaceholder')"
                  :theme="'bgwhite'"
                  :max="balance.origin"
                  :formatter="formatter"
                  :un-formatter="unFormatter"
                />
              </div>

              <div class="donation-unit">
                <span>{{ t('donateAction.wallet') }}</span>
                <span> : </span>
                <span>{{ balance.format }}<span class="unit">LM</span></span>
              </div>

              <div class="donation-unit-textarea">
                <label class="field-label">
                  {{ t('donateAction.cheer') }}
                  <em>({{ t('optional') }})</em>
                </label>

                <NovaTextarea
                  v-model="donateMessage"
                  :placeholder="t('donateAction.cheerPlaceholder')"
                  :max-length="100"
                />
              </div>
            </div>

            <div v-if="isSuccess" class="creator-donation-apply">
              <h4>
                {{
                  t('donateAction.success', {
                    creator: creator?.userNcnm,
                    donation: formatter(donation.toString()),
                  })
                }}
              </h4>
            </div>
          </div>

          <NovaBoxEmptyMessage
            v-if="isFail"
            :message="errorMessage"
            :fill="true"
            class="async-status-message"
          />
        </NovaLayoutScrollContainer>

        <div class="actions">
          <NovaButtonText
            v-if="!isSuccess"
            :label="$t('donateAction.title')"
            :theme="'primary-blue'"
            :full-width="true"
            :size="44"
            :disabled="donationActionBtnDisabled"
            @click="handleOnClickDonate"
          />
          <NovaButtonText
            v-if="isSuccess"
            :label="$t('confirm')"
            :theme="'primary-blue'"
            :size="44"
            :full-width="true"
            @click="handleOnClose"
          />
        </div>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    height: auto;
    max-width: 420px;
    max-height: calc(100% - 24px - 24px);
    padding: 0;
    pointer-events: auto;
    overflow: hidden;

    @include mobile {
      background-color: $color-bg-2;
    }

    .modal-header {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 56px;
      padding: 0 20px;
      color: $color-text-2;
      background-color: $color-bg-3;
      border-bottom: 1px solid $border-1;

      .title {
        @include text-style($text-display-bold);
      }
    }

    .modal-body {
      padding: 24px 20px;

      .donate {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        width: 100%;
        min-height: 200px;
      }

      .async-status-message {
        min-height: 200px;
      }

      .creator-modal-wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .creator-intro {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          gap: 10px;

          .creator-portrait {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 110px;
            height: 110px;
            border-radius: 50%;
            background-color: $color-bg-3;
            box-shadow: 0 4px 20px 0 #213acc80;
            overflow: hidden;
          }

          .creator-guide {
            display: flex;
            flex-direction: column;
            gap: 6px;

            h4 {
              font-size: 20px;
              font-weight: 700;
              line-height: 28px;
              letter-spacing: -0.025em;
              text-align: center;
              color: $color-black;
            }

            p {
              font-size: 14px;
              font-weight: 400;
              line-height: 19.6px;
              letter-spacing: -0.025em;
              text-align: center;
              padding: 0 40px;
            }
          }
        }

        .donation-unit-input {
          width: 100%;
          margin-top: 50px;

          .input-group > .inner .input-box {
            height: 50px;
            background: $color-bg-1;
            border-radius: 5px;

            @include mobile {
              background-color: $color-white;
              border: none;
            }
          }
        }

        .donation-unit-textarea {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 100%;
          margin-top: 32px;
          margin-bottom: 24px;

          .textarea {
            background: $color-bg-1;
            border-color: $color-bg-custom-2;

            textarea {
              background: $color-bg-1;
            }

            @include mobile {
              border: none;
              background: $color-white;

              textarea {
                background: $color-white;
              }
            }
          }

          .field-label {
            text-align: left;
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.55px;
            color: $color-text-2;

            em {
              font-size: 15px;
              font-weight: 500;
              line-height: 21px;
              letter-spacing: -0.025em;
            }
          }
        }

        .donation-unit {
          width: 100%;
          text-align: right;
          margin-top: 3px;

          span {
            font-size: 14px;
            font-weight: 400;
            line-height: 20.27px;
          }
        }
      }

      .creator-donation-apply {
        display: flex;
        align-items: center;
        h4 {
          display: block;
          position: relative;
          font-weight: 700;
          font-size: 24px;
          line-height: 160%;
          text-align: center;
          letter-spacing: -0.03em;
          color: #3f4354;
          padding-top: 141px;
          &:before {
            display: block;
            content: '';
            width: 117px;
            height: 117px;
            background-image: url('assets/images/material-symbols_check-circle-rounded.png');
            background-size: 100% 100%;
            background-position: center center;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

    .actions {
      flex-shrink: 0;
      width: 100%;
      padding: 0 20px 20px;
    }

    @include mobile {
      flex-grow: 1;
      border-radius: 0 !important;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
    }
  }
}
</style>
