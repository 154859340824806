<script setup lang="ts">
import type { NovaBoxPopoverContainerProps } from './NovaBoxPopoverContainer.types'

const props = withDefaults(defineProps<NovaBoxPopoverContainerProps>(), {
  position: 'bottom-start',
  trigger: 'hover',
})

const { t } = useI18n()
const { gtEvent } = useGoogleTag()
const slots = useSlots()
const popConRef = ref<Element>()
const show = ref(false)

onBeforeMount(() => {
  if (props.trigger === 'hover') {
    popConRef.value?.addEventListener('mouseenter', showPopover)
    popConRef.value?.addEventListener('mouseleave', hidePopover)
  }

  popConRef.value?.addEventListener('click', togglePopover)
})

onBeforeUnmount(() => {
  if (props.trigger === 'hover') {
    popConRef.value?.removeEventListener('mouseenter', showPopover)
    popConRef.value?.removeEventListener('mouseleave', hidePopover)
  }

  popConRef.value?.removeEventListener('click', togglePopover)
})

const togglePopover = async () => {
  if (
    !show.value &&
    props.trigger === 'click' &&
    props.beforeShow &&
    (await props.beforeShow())
  ) {
    return
  }
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '포스트 작성 버튼 클릭',
    eventLabel: t('createPost'),
    eventSlot: show.value
      ? '포스트 유형 선택 패널 닫기'
      : '포스트 유형 선택 패널 열기',
    eventI18nAddr: useKoreanTranslation('createPost'),
    eventComponent: 'Button',
  })
  show.value = !show.value
}

const showPopover = () => {
  show.value = true
}

const hidePopover = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '포스트 작성 버튼 클릭',
    eventLabel: t('createPost'),
    eventSlot: '포스트 유형 선택 패널 닫기',
    eventI18nAddr: useKoreanTranslation('createPost'),
    eventComponent: 'Button',
  })
  show.value = false
}

defineExpose({
  showPopover,
  hidePopover,
})
</script>

<template>
  <div ref="popConRef" :class="['box-popover-container', position, { show }]">
    <div v-if="trigger === 'click'" class="dim" @click.stop="hidePopover" />

    <div
      v-if="slots['base']"
      class="popover-base"
      @click.stop="togglePopover()"
    >
      <slot name="base" />
    </div>

    <div v-if="slots['popover']" class="popover-wrap">
      <slot name="popover" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
$verticalOffset: 12px;
$horizonOffset: 12px;

.box-popover-container {
  flex-shrink: 0;
  position: relative;
  font-size: 0;

  .dim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    z-index: $z-index-popover;
  }

  .popover-base {
    font-size: 0;
  }

  .popover-wrap {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s;
    z-index: $z-index-popover;
  }

  &.show {
    .dim {
      pointer-events: auto;
    }

    .popover-wrap {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &.top {
    &-start .popover-wrap {
      bottom: calc(100% + $verticalOffset);
      //left: calc(100% + $horizonOffset);
      left: 0;
    }

    &-middle .popover-wrap {
      bottom: calc(100% + $verticalOffset);
      left: 50%;
      transform: translateX(-50%);
      right: auto;
    }

    &-end .popover-wrap {
      bottom: calc(100% + $verticalOffset);
      //right: calc(100% + $horizonOffset);
      right: 0;
    }
  }

  &.middle {
    &-top-start .popover-wrap {
      top: 0;
      left: calc(100% + $horizonOffset);
      //left: 0;
    }

    &-top-end .popover-wrap {
      top: 0;
      right: calc(100% + $horizonOffset);
      //right: 0;
    }

    &-bottom-start .popover-wrap {
      bottom: 0;
      left: calc(100% + $horizonOffset);
      //left: 0;
    }

    &-bottom-end .popover-wrap {
      bottom: 0;
      right: calc(100% + $horizonOffset);
      //right: 0;
    }
  }

  &.bottom {
    &-start .popover-wrap {
      top: calc(100% + $verticalOffset);
      //left: calc(100% + $horizonOffset);
      left: 0;
    }

    &-end .popover-wrap {
      top: calc(100% + $verticalOffset);
      //right: calc(100% + $horizonOffset);
      right: 0;
    }
  }
}
</style>
